// src/app/landlord/landlord.component.ts
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Database, ref, push, update, get, remove, set, query, orderByChild, equalTo } from '@angular/fire/database';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Auth, authState } from '@angular/fire/auth';
import { User } from 'firebase/auth';
import { getStorage, ref as storageRef, getDownloadURL, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid'; // For unique file names


// Interface Definitions
interface UserData {
  Name: string;
  Email: string;
  Location: string;
  Status: string;
  UID: string;
  reports?: string[];
  depositAmount?: number;
  contractStartDate?: string; // ISO date string
  contractExpireDate?: string; // ISO date string
  contractLink?: string;
  nextPaymentDate?: string; // ISO date string
  nextPaymentAmount?: number;
  role?: string; // User role
  profileUrl?: string
}

interface BillingData {
  internet_fee: number;
  trash_fee: number;
  energyCostPerKW: number;
  nightEnergyCostPerKW: number;
  gasCostPerM3: number;
  gasStandingChargePerDay: number;
  psoLevy: number;
  standingChargePerDay: number;
  carbonTax: number;
  vatRate: number;
}

interface BillingEntry {
  providerIcon: string;
  amount: number;
  period: string;
  status: string;
  billImage?: string;
  key?: string;
  type: string;
  isPerPerson: boolean;
}
interface ProviderIcon {
  label: string; // Display name
  value: string; // URL or identifier
  // Optional: Add an icon URL if you want to display images in the dropdown
  iconUrl?: string;
  type?: string;
}
interface Notification {
  id: string;
  type?: 'general' | 'emergency' | 'maintenance' | 'update'; // Made optional
  title: string;
  content: string;
  timestamp: Date; // Created date and time
  endTime: Date; // New property for notification expiration
  author: Author;
  sendToAll: boolean; // New property
  sendToIndividuals?: boolean; // New property (optional)
  recipients?: string[]; // Array of user UIDs (optional),
  top: boolean;
}
interface Author {
  name: string;
  icon: string;
}

@Component({
  selector: 'app-landlord',
  templateUrl: './landlord.component.html',
  styleUrls: ['./landlord.component.css']
})
export class LandlordComponent implements OnInit {
  // Form controls
  userDataForm: FormGroup;
  billingForm: FormGroup;
  newBillingForm: FormGroup;
  billingDataForm: FormGroup;
  // New FormGroup for Notifications
  notificationForm: FormGroup;
  // Additional properties for Send to Individuals
  users: UserData[] = [];
  isLoadingUsers: boolean = false;
  selectedUserIds: Set<string> = new Set();
  // User information
  currentUser: User | null = null;
  currentUserData: UserData | null = null; // To store fetched user data
  // State variables
  isSubmitting: boolean = false;
  generatedCode: string | null = null;
  addressesWithUsers: string[] = []; // List of addresses with users
  usersByAddress: { [key: string]: UserData[] } = {}; // Group users by address
  filteredUsers: UserData[] = []; // Store filtered users based on selectedAddress

  // Form fields
  selectedAddress: string = '';
  email: string = '';

  // Dynamically fetched addresses
  addresses: string[] = [];
  isLoading: boolean = false; // Optional: Manage loading state
  // For editing existing billing data
  uploadErrorEdit: string = '';
  isUploadingEdit: boolean = false;
  uploadedImageUrlEdit: string = '';

  // For adding new billing data
  uploadErrorNew: string = '';
  isUploadingNew: boolean = false;
  uploadedImageUrlNew: string = '';
  // Modal state variables
  isModalOpen: boolean = false;
  selectedUser: UserData | null = null;
  isBillingModalOpen: boolean = false;
  isAddBillingModalOpen: boolean = false;
  currentBillingDataList: BillingEntry[] = [];
  previousBillingData: BillingEntry[] = [];
  billingData: BillingData | null = null;
  isBillingDataModalOpen: boolean = false;
  roles: string[] = [
    'Head of Tenant',
    'Financial Management Admin',
    'Task Management Admin',
    'Normal User'
  ];
  selectedYear: string = new Date().getFullYear().toString();
  selectedMonth: string = (new Date().getMonth() + 1).toString(); // Months are 0-based
  availableYears: string[] = [];
  months = [
    { value: '1', name: 'January' },
    { value: '2', name: 'February' },
    { value: '3', name: 'March' },
    { value: '4', name: 'April' },
    { value: '5', name: 'May' },
    { value: '6', name: 'June' },
    { value: '7', name: 'July' },
    { value: '8', name: 'August' },
    { value: '9', name: 'September' },
    { value: '10', name: 'October' },
    { value: '11', name: 'November' },
    { value: '12', name: 'December' },
  ];
  // Data for utilities fees
  utilitiesFeesData: {
    internetfeepaid: { [uid: string]: boolean };
    trashfeepaid: { [uid: string]: boolean };
  } = {
    internetfeepaid: {},
    trashfeepaid: {},
  };
  providerIcons: ProviderIcon[] = [
    // Energy Providers
    { label: 'Bord Gáis Energy', value: 'https://www.bordgaisenergy.ie/', iconUrl: 'assets/bordgaid.png', type: 'Energy' },
    { label: 'Electric Ireland', value: 'https://www.electricireland.ie/', iconUrl: 'assets/electricIreland.png', type: 'Energy' },
    { label: 'SSE Airtricity', value: 'https://www.sseairtricity.com/ie/home', iconUrl: 'assets/sse.png', type: 'Energy' },
    { label: 'Energia', value: 'https://www.energia.ie/home', iconUrl: 'assets/energia.png', type: 'Energy' },
    { label: 'Flogas', value: 'https://www.flogas.ie/', iconUrl: 'assets/flogas.png', type: 'Energy' },
    { label: 'Pinergy', value: 'https://pinergy.ie/', iconUrl: 'assets/pinergy.png', type: 'Energy' },
    { label: 'Prepay Power', value: 'https://www.prepaypower.ie/', iconUrl: 'assets/prepaypower.png', type: 'Energy' },
    { label: 'Yuno Energy', value: 'https://yunoenergy.ie/', iconUrl: 'assets/yunoenergy.png', type: 'Energy' },
    { label: 'Waterpower', value: 'https://www.waterpower.ie/', iconUrl: 'assets/waterpower.png', type: 'Energy' },
    { label: 'Ecopower', value: 'https://ecopower.ie/', iconUrl: 'assets/ecopower.png', type: 'Energy' },
    { label: 'Community Power', value: 'https://communitypower.ie/', iconUrl: 'assets/communitypower.jpg', type: 'Energy' },
  
    // Trash Providers
    { label: 'Thorntons Recycling', value: 'https://thorntons-recycling.ie/', iconUrl: 'assets/thorntons-recycling.png', type: 'Trash' },
    { label: 'City Bin', value: 'https://www.citybin.com/', iconUrl: 'assets/citybin.png', type: 'Trash' },
    { label: 'Greyhound Recycling', value: 'https://greyhound.ie/', iconUrl: 'assets/greyhound.jpg', type: 'Trash' },
    { label: 'KWD Recycling', value: 'https://www.kwd.ie/', iconUrl: 'assets/kwd.png', type: 'Trash' },
    { label: 'Panda Waste', value: 'https://www.panda.ie/', iconUrl: 'assets/panda.png', type: 'Trash' },
    { label: 'Wiser Recycling', value: 'https://www.wiserbins.ie/', iconUrl: 'assets/wiser.jpg', type: 'Trash' },
    { label: 'Country Clean', value: 'https://www.countryclean.ie/', iconUrl: 'assets/countryclean.png', type: 'Trash' },
  
    // Broadband Providers
    { label: 'Digiweb', value: 'https://www.digiweb.ie/', iconUrl: 'assets/Digiweb.png', type: 'Broadband' },
    { label: 'Virgin Media', value: 'https://www.virginmedia.ie/', iconUrl: 'assets/virgin_media.png', type: 'Broadband' },
    { label: 'Pure Telecom', value: 'https://www.puretelecom.ie/', iconUrl: 'assets/puretelecom.png', type: 'Broadband' },
    { label: 'Vodafone Ireland', value: 'https://www.vodafone.ie/', iconUrl: 'assets/vodafone.png', type: 'Broadband' },
    { label: 'Eir', value: 'https://www.eir.ie/', iconUrl: 'assets/eir.png', type: 'Broadband' },
  ];

  filteredProviderIcons: ProviderIcon[] = [];
  uniqueTypes: string[] = [];
  // For editing existing billing data
  selectedFileEdit: File | null = null;

  // For adding new billing data
  selectedFileNew: File | null = null;

  constructor(
    private fb: FormBuilder,
    private db: Database,
    private router: Router,
    private notifier: NotifierService,
    private translate: TranslateService,
    private auth: Auth
  ) {
    // Initialize the user data form for the modal
    this.userDataForm = this.fb.group({
      depositAmount: [0, [Validators.min(0)]], // No validators except min
      contractStartDate: [''], // Optional
      contractExpireDate: [''], // Optional
      contractLink: ['', Validators.pattern(/^(https?:\/\/)?([\w\d\-]+\.)+\w{2,}(\/.+)?$/)], // Optional with URL pattern
      nextPaymentDate: [''], // Optional
      nextPaymentAmount: [0, [Validators.min(0)]], // Optional with min
      role: ['Normal User', Validators.required] // Default role
    });

    // Initialize the form for editing billing
    this.billingForm = this.fb.group({
      type: ['', Validators.required],
      providerIcon: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(0)]],
      period: ['', Validators.required],
      status: ['Active', Validators.required],
      billImage: [''],
      key: [''] ,// Hidden field
      isPerPerson: [null, Validators.required] // Add this for radio buttons
    });

    // Initialize the form for adding new billing
    this.newBillingForm = this.fb.group({
      type: ['', Validators.required], // Add this
      providerIcon: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(0)]],
      period: ['', Validators.required],
      status: ['Active', Validators.required],
      billImage: [''],
      isPerPerson: [null, Validators.required]
    });

    // Initialize the billing data form
    this.billingDataForm = this.fb.group({
      carbonTax: [0, [Validators.required, Validators.min(0)]],
      energyCostPerKW: [0, [Validators.required, Validators.min(0)]],
      nightEnergyCostPerKW: [0, [Validators.required, Validators.min(0)]],
      gasCostPerM3: [0, [Validators.required, Validators.min(0)]],
      gasStandingChargePerDay: [0, [Validators.required, Validators.min(0)]],
      internet_fee: [0, [Validators.required, Validators.min(0)]],
      psoLevy: [0, [Validators.required, Validators.min(0)]],
      standingChargePerDay: [0, [Validators.required, Validators.min(0)]],
      trash_fee: [0, [Validators.required, Validators.min(0)]],
      vatRate: [0, [Validators.required, Validators.min(0)]]
    });
    // Initialize the notification form with endTime and sendToIndividuals
    this.notificationForm = this.fb.group({
      sendToAll: [false], // Checkbox to send to all users
      sendToIndividuals: [false], // Checkbox to send to selected individuals
      selectedUsers: [{ value: [], disabled: true }], // Array to hold selected user IDs, initially disabled
      type: ['', Validators.required],
      title: ['', Validators.required],
      content: ['', Validators.required],
      endTime: ['', Validators.required], // DateTime input for end time
      top: [false], 
    });
     // Disable selectedUsers initially
     this.notificationForm.get('selectedUsers')?.disable();
  }

  ngOnInit(): void {
    // Subscribe to authentication state
    authState(this.auth).subscribe((user) => {
      if (user) {
        this.currentUser = user;
        // Fetch landlord-specific data
        this.handleLandlordData(user.uid);
         // Fetch current user's data for author details
        this.fetchCurrentUserData(user.uid);
      } else {
        // User is not authenticated, navigate to login
        this.currentUser = null;
        this.translate.get('ADMIN.NOT_AUTHENTICATED').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
        this.router.navigate(['/login']);
      }
    });
     // Handle Send to Individuals checkbox changes
    this.notificationForm.get('sendToIndividuals')?.valueChanges.subscribe(value => {
      if (value) {
        // Enable selectedUsers without validators to make it optional
        this.notificationForm.get('selectedUsers')?.enable();
        this.notificationForm.get('selectedUsers')?.setValidators([]);
      } else {
        // Disable selectedUsers and clear validators and values
        this.notificationForm.get('selectedUsers')?.setValue([]);
        this.notificationForm.get('selectedUsers')?.disable();
        this.notificationForm.get('selectedUsers')?.clearValidators();
      }
      this.notificationForm.get('selectedUsers')?.updateValueAndValidity();
    });

    // Handle Send to All Users checkbox changes
    this.notificationForm.get('sendToAll')?.valueChanges.subscribe(value => {
      if (value) {
        // Disable Send to Individuals and selectedUsers
        this.notificationForm.get('sendToIndividuals')?.setValue(false);
        this.notificationForm.get('sendToIndividuals')?.disable();
        this.notificationForm.get('selectedUsers')?.setValue([]);
        this.notificationForm.get('selectedUsers')?.disable();
        this.notificationForm.get('selectedUsers')?.clearValidators();
        this.notificationForm.get('selectedUsers')?.updateValueAndValidity();
      } else {
        // Enable Send to Individuals
        this.notificationForm.get('sendToIndividuals')?.enable();
      }
    });
    // Extract unique types for the type dropdown, ensuring no undefined values
    this.uniqueTypes = [...new Set(this.providerIcons.map(icon => icon.type).filter((type): type is string => type !== undefined))];
    const currentYear = new Date().getFullYear();
    this.availableYears = [];
    for (let year = currentYear - 5; year <= currentYear + 5; year++) {
      this.availableYears.push(year.toString());
    }
  }
  onTypeChange(event: Event): void {
    const selectedType = (event.target as HTMLSelectElement).value;
    this.filteredProviderIcons = this.providerIcons.filter(icon => icon.type === selectedType);
  }
  /**
   * Custom validator to ensure at least a minimum number of checkboxes are selected
   * @param min - Minimum number of selections required
   */
  minSelectedCheckboxes(min = 1) {
    return (formControl: any) => {
      const selected = formControl.value;
      if (selected && selected.length >= min) {
        return null;
      }
      return { required: true };
    };
  }

  /**
   * Submits the notification form
   */
  async submitNotification(): Promise<void> {
    if (this.notificationForm.invalid) {
      this.notificationForm.markAllAsTouched();
      return;
    }

    this.isSubmitting = true;

    const formValue = this.notificationForm.value;
    const sendToAll: boolean = formValue.sendToAll;
    const top: boolean = formValue.top;
    const sendToIndividuals: boolean = formValue.sendToIndividuals;
    const type: 'general' | 'emergency' | 'maintenance' | 'update' = formValue.type as 'general' | 'emergency' | 'maintenance' | 'update';
    const title: string = formValue.title;
    const content: string = formValue.content;
    const endTime: string = formValue.endTime; // ISO date string

    // Prepare the notification data
    const newNotification: Notification = {
      id: '', // Will be set by Firebase
      type: type,
      title: title,
      content: content,
      timestamp: new Date(), // Created date and time
      endTime: new Date(endTime), // End date and time
      author: {
        name: this.currentUserData?.Name || 'Landlord',
        icon: this.currentUserData?.profileUrl || 'assets/icons/user.png' // Default icon if profileUrl doesn't exist
      },
      sendToAll: sendToAll,
      top: top,
      sendToIndividuals: sendToIndividuals,
      recipients: sendToIndividuals ? formValue.selectedUsers : undefined // Include recipients if sending to individuals
    };

    try {
      if (!this.selectedAddress) {
        this.translate.get('ADMIN.NOTIFICATIONS.SELECT_ADDRESS_FIRST').subscribe((msg: string) => {
          this.notifier.notify('warning', msg);
        });
        this.isSubmitting = false;
        return;
      }

      // Reference to the Notifications node for the selected address
      const notificationsRef = ref(this.db, `Notifications/${this.selectedAddress}`);
      const newNotificationRef = push(notificationsRef);

      // Prepare the data to be saved, converting dates to ISO strings
      const notificationDataToSave = {
        type: newNotification.type,
        title: newNotification.title,
        content: newNotification.content,
        timestamp: newNotification.timestamp.toISOString(),
        endTime: newNotification.endTime.toISOString(),
        author: newNotification.author,
        sendToAll: newNotification.sendToAll ?? false,
        top: newNotification.top ?? false,
        sendToIndividuals: newNotification.sendToIndividuals ?? false,
        recipients: newNotification.recipients ?? ['N/A']
      };

      // Save the notification to Firebase
      await set(newNotificationRef, notificationDataToSave);

      // Notify success
      this.translate.get(sendToAll ? 'ADMIN.NOTIFICATIONS.NOTIFICATION_SENT_ALL' : 'ADMIN.NOTIFICATIONS.NOTIFICATION_SENT_INDIVIDUALS').subscribe((msg: string) => {
        this.notifier.notify('success', msg);
      });

      // Reset the form after successful submission
      this.notificationForm.reset({
        sendToAll: false,
        top: false,
        sendToIndividuals: false,
        selectedUsers: [],
        type: '',
        title: '',
        content: '',
        endTime: ''
      });
      this.selectedUserIds.clear();
    } catch (error) {
      console.error('Error adding notification:', error);
      this.translate.get(sendToAll ? 'ADMIN.NOTIFICATIONS.ERROR_SENDING_ALL' : 'ADMIN.NOTIFICATIONS.ERROR_SENDING_INDIVIDUALS').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
    } finally {
      this.isSubmitting = false;
    }
  }
  
  // Handle file selection for editing billing data
  onFileSelectedEdit(event: any) {
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
    const file: File = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        this.translate.get('ADMIN.FILE_TOO_LARGE').subscribe(msg => {
          this.notifier.notify('error', msg);
        });
        return;
      }
      if (!file.type.startsWith('image/')) {
        this.translate.get('ADMIN.INVALID_FILE_TYPE').subscribe(msg => {
          this.notifier.notify('error', msg);
        });
        return;
      }
      this.selectedFileEdit = file;
    }
  }

  // Handle file selection for adding new billing data
  onFileSelectedNew(event: any) {
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
    const file: File = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        this.translate.get('ADMIN.FILE_TOO_LARGE').subscribe(msg => {
          this.notifier.notify('error', msg);
        });
        this.selectedFileNew = null; // Reset the selected file
        return;
      }
      if (!file.type.startsWith('image/')) {
        this.translate.get('ADMIN.INVALID_FILE_TYPE').subscribe(msg => {
          this.notifier.notify('error', msg);
        });
        this.selectedFileNew = null; // Reset the selected file
        return;
      }
      this.selectedFileNew = file;
    }
  }
  
  
  // Handle user selection changes
  onUserSelectionChange(event: any, uid: string) {
    if (event.target.checked) {
      this.selectedUserIds.add(uid);
    } else {
      this.selectedUserIds.delete(uid);
    }
    this.notificationForm.get('selectedUsers')?.setValue(Array.from(this.selectedUserIds));
  }

  /**
   * Fetches the current user's data from Firebase to get name and profileUrl.
   * @param uid - The UID of the current user.
   */
  private async fetchCurrentUserData(uid: string): Promise<void> {
    const userRef = ref(this.db, `Accounts/${uid}`);
    try {
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const userData = snapshot.val() as UserData;
        this.currentUserData = userData;
      } else {
        this.currentUserData = null;
      }
    } catch (error) {
      console.error('Error fetching current user data:', error);
      this.translate.get('ADMIN.ERROR_FETCHING_USER_DATA').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
    }
  }
  /**
   * Handles landlord data fetching based on their UID.
   * @param uid - The UID of the landlord.
   */
  private async handleLandlordData(uid: string): Promise<void> {
    try {
      await this.fetchAvailableAddresses();
      await this.fetchUsersByAddress();
  
      // After addresses are fetched, load selectedAddress from localStorage
      const storedAddress = localStorage.getItem('selectedAddress');
      if (storedAddress && this.addresses.includes(storedAddress)) {
        this.selectedAddress = storedAddress;
        this.filterUsersByAddress();
      }
    } catch (error) {
      console.error('Error in handleLandlordData:', error);
      // Notify the user about the error
      this.translate.get('ADMIN.ERROR_HANDLING_DATA_FETCH').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
    }
  }

  /**
   * Fetches the list of addresses assigned to the landlord.
   */
  async fetchAvailableAddresses(): Promise<void> {
    if (!this.currentUser) return;

    const landlordAddressesRef = ref(this.db, `Permission/landlord/${this.currentUser.uid}/addresses`);
    try {
      const snapshot = await get(landlordAddressesRef);
      if (snapshot.exists()) {
        // Assuming landlord's addresses are structured as { "address1": true, "address2": true, ... }
        this.addresses = Object.keys(snapshot.val());
      } else {
        this.addresses = [];
      }
    } catch (error) {
      console.error('Error fetching landlord addresses:', error);
      // Optionally, handle the error (e.g., notify the user)
      this.notifier.notify('error', 'Failed to fetch your assigned addresses.');
    }
  }

    /**
   * Fetches users associated with landlord's addresses.
   */
  async fetchUsersByAddress(): Promise<void> {
    this.isLoading = true; // Start loading
    this.usersByAddress = {}; // Reset usersByAddress
    this.addressesWithUsers = []; // Reset addressesWithUsers

    try {
      const userPromises = this.addresses.map(async (address) => {
        // Create a query for users with Location equal to current address
        const accountsQuery = query(
          ref(this.db, 'Accounts'),
          orderByChild('Location'),
          equalTo(address)
        );

        const snapshot = await get(accountsQuery);

        if (snapshot.exists()) {
          const users = snapshot.val() as { [uid: string]: UserData };
          const usersArray: UserData[] = [];

          for (const userId in users) {
            const user = users[userId] as UserData;

            if (user.role === 'Landlord' || user.role === 'Admin') {
              continue; // Skip this user
            }

            // Fetch reports if available
            const userReports = await this.fetchUserReports(userId);

            usersArray.push({
              ...user,
              reports: userReports
            });
          }

          if (usersArray.length > 0) {
            this.usersByAddress[address] = usersArray;
            this.addressesWithUsers.push(address);
          }
        }
      });

      await Promise.all(userPromises); // Wait for all queries to complete

      if (this.addressesWithUsers.length === 0) {
        this.translate.get('ADMIN.NO_USERS_FOUND').subscribe(msg => {
          this.notifier.notify('info', msg);
        });
      }
    } catch (error) {
      console.error('Error fetching users by address:', error);
      this.translate.get('ADMIN.ERROR_FETCHING_USERS').subscribe(msg => {
        this.notifier.notify('error', msg);
      });
    } finally {
      this.isLoading = false; // End loading
    }
  }


  /**
   * Fetches reports associated with a user.
   * @param userId - The UID of the user.
   * @returns An array of report strings.
   */
  async fetchUserReports(userId: string): Promise<string[]> {
    const reportsRef = ref(this.db, `reports/${userId}`);
    try {
      const snapshot = await get(reportsRef);
      if (snapshot.exists()) {
        const reports = snapshot.val();
        if (Array.isArray(reports)) {
          return reports;
        } else if (typeof reports === 'object' && reports !== null) {
          return Object.values(reports).filter((report): report is string => typeof report === 'string');
        }
      }
    } catch (error) {
      console.error(`Error fetching reports for user ${userId}:`, error);
      this.translate.get('ADMIN.ERROR_FETCHING_REPORTS').subscribe(msg => {
        this.notifier.notify('error', msg);
      });
    }
    return [];
  }
  onYearOrMonthChange(): void {
    this.fetchUtilitiesFeesData();
  }
  fetchUtilitiesFeesData(): void {
    if (!this.selectedAddress || !this.selectedYear || !this.selectedMonth) {
      return;
    }
  
    const utilitiesFeesRef = ref(
      this.db,
      `billing/${this.selectedAddress}/utilitiesfees/${this.selectedYear}/${this.months[+this.selectedMonth - 1].name.toLowerCase()}`
    );
  
    get(utilitiesFeesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          this.utilitiesFeesData = {
            internetfeepaid: {},
            trashfeepaid: {},
          };
  
          // Initialize the data structures
          if (data.internetfeepaid) {
            data.internetfeepaid.forEach((uid: string) => {
              this.utilitiesFeesData.internetfeepaid[uid] = true;
            });
          }
  
          if (data.trashfeepaid) {
            data.trashfeepaid.forEach((uid: string) => {
              this.utilitiesFeesData.trashfeepaid[uid] = true;
            });
          }
        } else {
          // No data exists for the selected month and year
          this.utilitiesFeesData = {
            internetfeepaid: {},
            trashfeepaid: {},
          };
        }
      })
      .catch((error) => {
        console.error('Error fetching utilities fees data:', error);
        this.translate.get('ADMIN.ERROR_FETCHING_UTILITIES_FEES_DATA').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
      });
  }
  saveUtilitiesFeesData(): void {
    if (!this.selectedAddress || !this.selectedYear || !this.selectedMonth) {
      return;
    }
  
    const utilitiesFeesRef = ref(
      this.db,
      `billing/${this.selectedAddress}/utilitiesfees/${this.selectedYear}/${this.months[+this.selectedMonth - 1].name.toLowerCase()}`
    );
  
    // Prepare data to save
    const internetfeepaidUIDs = Object.keys(this.utilitiesFeesData.internetfeepaid).filter(
      (uid) => this.utilitiesFeesData.internetfeepaid[uid]
    );
  
    const trashfeepaidUIDs = Object.keys(this.utilitiesFeesData.trashfeepaid).filter(
      (uid) => this.utilitiesFeesData.trashfeepaid[uid]
    );
  
    const dataToSave = {
      internetfeepaid: internetfeepaidUIDs,
      trashfeepaid: trashfeepaidUIDs,
    };
  
    set(utilitiesFeesRef, dataToSave)
      .then(() => {
        this.translate.get('ADMIN.UTILITIES_FEES_MANAGEMENT.DATA_SAVED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });
      })
      .catch((error) => {
        console.error('Error saving utilities fees data:', error);
        this.translate.get('ADMIN.UTILITIES_FEES_MANAGEMENT.ERROR_SAVING_DATA').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
      });
  }
  
  /**
   * Filters users based on the selected address.
   */
  filterUsersByAddress(): void {
    if (this.selectedAddress) {
      if (this.usersByAddress[this.selectedAddress]) {
        this.filteredUsers = this.usersByAddress[this.selectedAddress];
      } else {
        this.filteredUsers = [];
      }
      this.onAddressSelected(this.selectedAddress);
      this.fetchBillingDataDetails(this.selectedAddress);
      this.fetchUtilitiesFeesData();
  
      // Store selectedAddress in localStorage
      localStorage.setItem('selectedAddress', this.selectedAddress);
    } else {
      this.filteredUsers = []; // No users for the selected address
      this.billingData = null;
      this.currentBillingDataList = [];
      this.previousBillingData = [];
      this.isBillingModalOpen = false;
      this.isAddBillingModalOpen = false;
      this.isBillingDataModalOpen = false;
  
      // Remove selectedAddress from localStorage
      localStorage.removeItem('selectedAddress');
    }
  }

  /**
   * Method to generate a random 6-character code with uppercase letters and numbers
   */
  generateInvitationCode(): void {
    if (!this.selectedAddress) {
      this.translate.get('ADMIN.ADDRESS_REQUIRED').subscribe((res: string) => {
        this.notifier.notify('warning', res);
      });
      return;
    }

    if (this.email && !this.isValidEmail(this.email)) {
      this.translate.get('ADMIN.INVALID_EMAIL').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    this.isSubmitting = true; // Start loading

    // Generate the invitation code
    this.generatedCode = this.generateRandomCode(6);

    // Store the generated code in Firebase
    const invitationData = {
      code: this.generatedCode,
      address: this.selectedAddress,
      email: this.email || 'Not Provided', // Handle optional email
      generatedBy: this.currentUser ? this.currentUser.uid : 'Unknown',
      timestamp: new Date().toISOString()
    };

    const invitationsRef = ref(this.db, 'invitations');
    push(invitationsRef, invitationData)
      .then(() => {
        this.translate.get('ADMIN.CODE_GENERATED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });
        this.filterUsersByAddress(); // Update the filtered users
      })
      .catch(error => {
        console.error('Error generating invitation code:', error);
        this.translate.get('ADMIN.CODE_GENERATION_FAILED', { error }).subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
      })
      .finally(() => {
        this.isSubmitting = false; // End loading
      });
  }

  /**
   * Helper method to generate a random code
   * @param length - The length of the code to generate.
   * @returns A shuffled random code string.
   */
  private generateRandomCode(length: number): string {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const allCharacters = letters + numbers;

    let code = '';

    // Ensure at least one letter
    code += letters.charAt(Math.floor(Math.random() * letters.length));

    // Ensure at least one number
    code += numbers.charAt(Math.floor(Math.random() * numbers.length));

    // Generate remaining characters
    for (let i = 2; i < length; i++) {
      code += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
    }

    // Shuffle the code to mix letters and numbers
    code = this.shuffleString(code);

    return code;
  }

  /**
   * Helper method to shuffle a string
   * @param str - The string to shuffle.
   * @returns A shuffled string.
   */
  private shuffleString(str: string): string {
    const arr = str.split('');
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr.join('');
  }

  /**
   * Method to copy the generated code to clipboard
   */
  copyCode(): void {
    if (this.generatedCode) {
      navigator.clipboard.writeText(this.generatedCode).then(
        () => {
          this.translate.get('ADMIN.CODE_COPIED').subscribe((res: string) => {
            this.notifier.notify('success', res);
          });
        },
        err => {
          console.error('Could not copy text: ', err);
          this.translate.get('ADMIN.CODE_COPY_FAILED').subscribe((res: string) => {
            this.notifier.notify('error', res);
          });
        }
      );
    }
  }

  /**
   * Validate email format
   * @param email - The email string to validate.
   * @returns Boolean indicating if the email is valid.
   */
  isValidEmail(email: string): boolean {
    if (!email) return true; // Email is optional
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  /**
   * Handles address selection and fetches corresponding billing data
   * @param address - The selected address.
   */
  onAddressSelected(address: string): void {
    this.selectedAddress = address;
    this.fetchBillingData(address);
  }

  /**
   * Method to fetch the billing data for the selected address
   * @param address - The address for which to fetch billing data.
   */
  fetchBillingData(address: string): void {
    const billingRef = ref(this.db, `billing/${address}`);

    get(billingRef).then(snapshot => {
      if (snapshot.exists()) {
        const billingData = snapshot.val();

        // Handle current billing data (allowing multiple entries)
        if (billingData.currentbilling) {
          const billingDataModel = billingData.currentbilling;

          // Store all current billing entries, including their keys
          this.currentBillingDataList = Object.keys(billingDataModel).map(key => ({
            ...billingDataModel[key], // Copy all billing data properties
            key: key // Append the key for each billing entry
          }));

        } else {
          this.currentBillingDataList = [];
        }

        // Handle previous billing data (if available)
        if (billingData.previousbilling) {
          this.previousBillingData = Object.values(billingData.previousbilling).filter((bill): bill is BillingEntry => typeof bill === 'object' && bill !== null);
        } else {
          this.previousBillingData = [];
        }
      } else {
        this.currentBillingDataList = [];
        this.previousBillingData = [];
      }
    }).catch(error => {
      console.error('Error fetching billing data:', error);
      this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_FETCHING_BILLING_DATA').subscribe(msg => {
        this.notifier.notify('error', msg);
      });
    });
  }

  /**
   * Opens the modal to view and edit user data
   * @param user - The user data to view/edit.
   */
  openViewDataModal(user: UserData): void {
    this.selectedUser = user;
    this.fetchUserData(user);
    this.isModalOpen = true;
  }

  /**
   * Closes the user data modal
   */
  closeModal(): void {
    this.isModalOpen = false;
    this.selectedUser = null;
    this.userDataForm.reset({
      depositAmount: 0,
      contractStartDate: '',
      contractExpireDate: '',
      contractLink: '',
      nextPaymentDate: '',
      nextPaymentAmount: 0,
      role: 'Normal User'
    });
  }

  /**
   * Fetches additional data for a user from Firebase
   * @param user - The user data.
   */
  async fetchUserData(user: UserData): Promise<void> {
    if (!user.UID) {
      this.translate.get('ADMIN.USER_UID_NOT_FOUND').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    const userRef = ref(this.db, `Accounts/${user.UID}`);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      const userData = snapshot.val() as UserData;
      // Populate the form with existing data if available
      this.userDataForm.patchValue({
        depositAmount: userData.depositAmount || 0,
        contractStartDate: userData.contractStartDate || '',
        contractExpireDate: userData.contractExpireDate || '',
        contractLink: userData.contractLink || '',
        nextPaymentDate: userData.nextPaymentDate || '',
        nextPaymentAmount: userData.nextPaymentAmount || 0,
        role: userData.role || 'Normal User' // Populate role
      });
    } else {
      // No additional data exists, reset the form
      this.userDataForm.reset({
        depositAmount: 0,
        contractStartDate: '',
        contractExpireDate: '',
        contractLink: '',
        nextPaymentDate: '',
        nextPaymentAmount: 0,
        role: 'Normal User'
      });
    }
  }

  /**
   * Saves or updates user data in Firebase
   */
  saveUserData(): void {
    if (!this.selectedUser || !this.selectedUser.UID) {
      this.translate.get('ADMIN.SELECT_USER_FIRST').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    // Prepare an object to hold only non-empty fields
    const updatedData: Partial<UserData> = {};

    // Add fields to the updatedData object only if they have values
    if (this.userDataForm.get('depositAmount')?.value !== null) {
      updatedData.depositAmount = this.userDataForm.get('depositAmount')?.value;
    }

    if (this.userDataForm.get('contractStartDate')?.value) {
      updatedData.contractStartDate = this.userDataForm.get('contractStartDate')?.value;
    }

    if (this.userDataForm.get('contractExpireDate')?.value) {
      updatedData.contractExpireDate = this.userDataForm.get('contractExpireDate')?.value;
    }

    if (this.userDataForm.get('contractLink')?.value) {
      updatedData.contractLink = this.userDataForm.get('contractLink')?.value;
    }

    if (this.userDataForm.get('nextPaymentDate')?.value) {
      updatedData.nextPaymentDate = this.userDataForm.get('nextPaymentDate')?.value;
    }

    if (this.userDataForm.get('nextPaymentAmount')?.value !== null) {
      updatedData.nextPaymentAmount = this.userDataForm.get('nextPaymentAmount')?.value;
    }

    if (this.userDataForm.get('role')?.value) {
      updatedData.role = this.userDataForm.get('role')?.value;
    }

    // Ensure at least one field is provided
    if (Object.keys(updatedData).length === 0) {
      this.translate.get('ADMIN.NO_DATA_PROVIDED').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    const userRef = ref(this.db, `Accounts/${this.selectedUser.UID}`);

    // Save or update the non-empty fields in Firebase
    update(userRef, updatedData)
      .then(() => {
        this.translate.get('ADMIN.USER_DATA_SAVED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });
        // Update the local user data using non-null assertion
        Object.assign(this.selectedUser!, updatedData);
        this.closeModal();
      })
      .catch(error => {
        console.error('Error saving user data:', error);
        this.translate.get('ADMIN.ERROR_SAVING_USER_DATA').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
      });
  }

  /**
   * Toggles user status between Active and Inactive
   * @param user - The user whose status is to be toggled.
   */
  toggleUserStatus(user: UserData): void {
    // Determine the new status
    const newStatus = user.Status === 'Active' ? 'Inactive' : 'Active';

    // Prepare the multi-location updates
    const updates: { [key: string]: any } = {};
    updates[`Accounts/${user.UID}/Status`] = newStatus;
    updates[`Addresses/${user.Location}/${user.UID}/status`] = newStatus;

    const rootRef = ref(this.db);

    // Perform the multi-location update
    update(rootRef, updates)
      .then(() => {
        // Update the local UI state
        user.Status = newStatus;

        // Notify the user of the successful update
        this.translate.get('ADMIN.STATUS_UPDATED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });
      })
      .catch(error => {
        // Handle any errors during the update

        // Notify the user of the error
        this.translate.get('ADMIN.ERROR_UPDATING_STATUS').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });

        // Log the error for debugging purposes
        console.error('Error updating user status:', error);
      });
  }

  /**
   * Deletes a user from Firebase
   * @param user - The user to delete.
   */
  deleteUser(user: UserData): void {
    this.translate.get('ADMIN.CONFIRM_DELETE_USER').subscribe((confirmMessage: string) => {
      if (confirm(confirmMessage)) {
        const userRef = ref(this.db, `Accounts/${user.UID}`);

        // Use remove() function to delete the user
        remove(userRef)
          .then(() => {
            this.translate.get('ADMIN.USER_DELETED').subscribe((res: string) => {
              this.notifier.notify('success', res);
            });

            // Remove user from the local data structure
            const usersForAddress = this.usersByAddress[user.Location];
            this.usersByAddress[user.Location] = usersForAddress.filter(u => u.UID !== user.UID);

            // If no more users are present under the selected address, reset the selected address
            if (this.usersByAddress[user.Location].length === 0) {
              delete this.usersByAddress[user.Location];
              this.selectedAddress = '';
              this.filterUsersByAddress(); // Update the filtered users
            }
          })
          .catch(error => {
            this.translate.get('ADMIN.ERROR_DELETING_USER').subscribe((res: string) => {
              this.notifier.notify('error', res);
            });
            console.error('Error deleting user:', error);
          });
      }
    });
  }

  /**
   * Opens the billing data modal
   */
  openBillingDataModal(): void {
    this.isBillingDataModalOpen = true;
  }

  /**
   * Closes the billing data modal
   */
  closeBillingDataModal(): void {
    this.isBillingDataModalOpen = false;
  }

  /**
   * Opens the modal to edit billing
   * @param billingData - The billing data to edit.
   */
  openEditBillingModal(billingData: BillingEntry): void {
    this.billingForm.patchValue({
      type: billingData.type,
      providerIcon: billingData.providerIcon,
      amount: billingData.amount,
      period: billingData.period,
      status: billingData.status,
      billImage: billingData.billImage,
      key: billingData.key,
      isPerPerson : billingData.isPerPerson
    });
    this.isBillingModalOpen = true;
  }

  /**
   * Closes the edit billing modal
   */
  closeBillingModal(): void {
    this.isBillingModalOpen = false;
  }

  /**
   * Opens the modal to add new billing
   */
  openAddBillingModal(): void {
    this.isAddBillingModalOpen = true;
  }

  /**
   * Closes the add billing modal
   */
  closeAddBillingModal(): void {
    this.isAddBillingModalOpen = false;
  }

  /**
   * Saves the new billing data
   */
  saveNewBillingData(): void {
    if (!this.selectedAddress) {
      this.translate.get('ADMIN.SELECT_ADDRESS_FIRST').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }
  
    if (this.newBillingForm.invalid) {
      this.translate.get('ADMIN.FORM_INVALID').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }
  
    const newBillingData = this.newBillingForm.value;
  
    // Disable the form to prevent multiple submissions
    this.isSubmitting = true;
  
    // Function to add billing data to the database
    const addBillingData = (imageUrl?: string) => {
      if (imageUrl) {
        newBillingData.billImage = imageUrl;
      }
  
      const billingRef = ref(this.db, `billing/${this.selectedAddress}/currentbilling`);
      push(billingRef, newBillingData)
        .then(() => {
          this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_DATA_ADDED').subscribe(msg => {
            this.notifier.notify('success', msg);
          });
          this.isAddBillingModalOpen = false; // Close the modal
          this.fetchBillingData(this.selectedAddress);
          // Reset the form
          this.newBillingForm.reset();
        })
        .catch(error => {
          console.error('Error adding new billing:', error);
          this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_ADDING_BILLING_DATA').subscribe(msg => {
            this.notifier.notify('error', msg);
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    };
  
    // Check if a file has been selected
    if (this.selectedFileNew) {
      // Upload the file
      const storage = getStorage();
      const uniqueFileName = `Billing/${uuidv4()}_${this.selectedFileNew.name}`;
      const storageReference = storageRef(storage, uniqueFileName);
      const uploadTask = uploadBytesResumable(storageReference, this.selectedFileNew);
  
      uploadTask.on('state_changed',
        (snapshot) => {
          // Progress monitoring (optional)
        },
        (error) => {
          this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_UPLOADING_IMAGE').subscribe(msg => {
            this.notifier.notify('error', msg);
          });
          this.isSubmitting = false;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            addBillingData(downloadURL);
            // Reset the selected file
            this.selectedFileNew = null;
          }).catch((error) => {
            this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_GETTING_DOWNLOAD_URL').subscribe(msg => {
              this.notifier.notify('error', msg);
            });
            this.isSubmitting = false;
          });
        }
      );
    } else {
      // No file selected, proceed without uploading
      addBillingData();
    }
  }
  

  /**
   * Saves the updated billing data
   */
  saveBillingData(): void {
    if (this.billingForm.invalid) {
      this.translate.get('ADMIN.FORM_INVALID').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }
  
    const updatedData = this.billingForm.value;
    const billingKey = this.billingForm.get('key')?.value;
  
    if (!billingKey) {
      this.translate.get('ADMIN.BILLING_MANAGEMENT.NO_BILLING_KEY').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }
  
    this.isSubmitting = true;
  
    const updateBillingData = (imageUrl?: string) => {
      if (imageUrl) {
        updatedData.billImage = imageUrl;
      }
  
      // Rest of your existing code to update or move the billing data
      if (updatedData.status === 'Completed') {
        this.moveToPreviousBilling(billingKey, updatedData);
      } else {
        const currentBillingRef = ref(this.db, `billing/${this.selectedAddress}/currentbilling/${billingKey}`);
        update(currentBillingRef, updatedData)
          .then(() => {
            this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_UPDATED').subscribe(msg => {
              this.notifier.notify('success', msg);
            });
            this.fetchBillingData(this.selectedAddress);
          })
          .catch(error => {
            console.error('Error updating billing:', error);
            this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_UPDATING_BILLING_DATA').subscribe(msg => {
              this.notifier.notify('error', msg);
            });
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
      this.closeBillingModal();
    };
  
    // **Add the code to delete the old image here**
    // Check if a new file has been selected
    if (this.selectedFileEdit) {
      // **Delete the old image before uploading the new one**
      const oldImageUrl = this.billingForm.get('billImage')?.value;
  
      if (oldImageUrl) {
        const storage = getStorage();
        const oldImageRef = storageRef(storage, oldImageUrl);
  
        deleteObject(oldImageRef).catch((error) => {
          console.error('Error deleting old image:', error);
        });
      }
  
      // Proceed to upload the new file
      const storage = getStorage();
      const uniqueFileName = `Billing/${uuidv4()}_${this.selectedFileEdit.name}`;
      const storageReference = storageRef(storage, uniqueFileName);
      const uploadTask = uploadBytesResumable(storageReference, this.selectedFileEdit);
  
      uploadTask.on('state_changed',
        (snapshot) => {
          // Progress monitoring (optional)
        },
        (error) => {
          this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_UPLOADING_IMAGE').subscribe(msg => {
            this.notifier.notify('error', msg);
          });
          this.isSubmitting = false;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            updateBillingData(downloadURL);
            // Reset the selected file
            this.selectedFileEdit = null;
          }).catch((error) => {
            this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_GETTING_DOWNLOAD_URL').subscribe(msg => {
              this.notifier.notify('error', msg);
            });
            this.isSubmitting = false;
          });
        }
      );
    } else {
      // No new file selected, proceed without uploading
      updateBillingData();
    }
  }
  

  /**
   * Moves the current billing to previous billing if status is 'Completed'
   * @param billingKey - The key of the billing entry.
   * @param updatedData - The updated billing data.
   */
  moveToPreviousBilling(billingKey: string, updatedData: any): void {
    const currentBillingRef = ref(this.db, `billing/${this.selectedAddress}/currentbilling/${billingKey}`);
    const previousBillingRef = ref(this.db, `billing/${this.selectedAddress}/previousbilling/${billingKey}`);

    // First move the data to previous billing
    set(previousBillingRef, updatedData)
      .then(() => {
        // Once the data is moved, remove it from current billing
        return remove(currentBillingRef);
      })
      .then(() => {
        this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_MOVED_TO_PREVIOUS').subscribe(msg => {
          this.notifier.notify('success', msg);
        });
        // Refresh the billing data after the move
        this.fetchBillingData(this.selectedAddress);
      })
      .catch(error => {
        console.error('Error moving billing to previous:', error);
        this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_MOVE_FAILED').subscribe(msg => {
          this.notifier.notify('error', msg);
        });
      });
  }

  /**
   * Deletes a billing entry
   * @param billingData - The billing data to delete.
   */
  deleteCurrentBilling(billingData: BillingEntry): void {
    const billingKey = billingData.key; // Assuming billing entries are stored with unique keys

    const billingRef = ref(this.db, `billing/${this.selectedAddress}/currentbilling/${billingKey}`);

    remove(billingRef)
      .then(() => {
        this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_DELETED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });
        this.fetchBillingData(this.selectedAddress); // Refresh the billing data
      })
      .catch(error => {
        this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_DELETING_BILLING').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
        console.error('Error deleting billing data:', error);
      });
  }

  /**
   * Fetches billing data details for the selected address
   * @param address - The address for which to fetch billing data.
   */
  async fetchBillingDataDetails(address: string): Promise<void> {
    const billingDetailsRef = ref(this.db, `billing/${address}`);
    const snapshot = await get(billingDetailsRef);

    if (snapshot.exists()) {
      this.billingData = snapshot.val() as BillingData;
      this.billingDataForm.patchValue(this.billingData);
    } else {
      this.billingData = null;
      this.billingDataForm.reset({
        carbonTax: 0,
        energyCostPerKW: 0,
        nightEnergyCostPerKW: 0,
        gasCostPerM3: 0,
        gasStandingChargePerDay: 0,
        internet_fee: 0,
        psoLevy: 0,
        standingChargePerDay: 0,
        trash_fee: 0,
        vatRate: 0
      });
    }
  }

  /**
   * Saves or updates billing data details in Firebase
   */
  saveBillingDataDetails(): void {
    if (!this.selectedAddress) {
      this.translate.get('ADMIN.SELECT_ADDRESS_FIRST').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    if (this.billingDataForm.invalid) {
      this.translate.get('ADMIN.FORM_INVALID').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    if (this.billingData) {
      // Modify existing billing data
      const billingDetailsRef = ref(this.db, `billing/${this.selectedAddress}`);
      update(billingDetailsRef, this.billingDataForm.value)
        .then(() => {
          this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_DATA_UPDATED').subscribe((res: string) => {
            this.notifier.notify('success', res);
          });
          this.closeBillingDataModal();
          this.fetchBillingDataDetails(this.selectedAddress);
        })
        .catch(error => {
          console.error('Error updating billing data:', error);
          this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_UPDATING_BILLING_DATA').subscribe((res: string) => {
            this.notifier.notify('error', res);
          });
        });
    } else { 
      // Add new billing data
      const billingDetailsRef = ref(this.db, `billing/${this.selectedAddress}`);
      set(billingDetailsRef, this.billingDataForm.value)
        .then(() => {
          this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_DATA_ADDED').subscribe((res: string) => {
            this.notifier.notify('success', res);
          });
          this.closeBillingDataModal();
        })
        .catch(error => {
          console.error('Error adding billing data:', error);
          this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_ADDING_BILLING_DATA').subscribe((res: string) => {
            this.notifier.notify('error', res);
          });
        });
    }
  }
}
