// src/app/auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Auth, authState } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private auth: Auth) {}

  /**
   * Call this method when the invitation is validated.
   * It sets the invitationValidated flag in localStorage.
   */
  validateInvitation() {
    try {
      localStorage.setItem('invitationValidated', 'true');
    } catch (error) {
      console.error('Failed to store invitation validated flag in localStorage', error);
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const isSignUpRoute = route.routeConfig?.path === 'auth/sign-up';

    return authState(this.auth).pipe(
      take(1), // Take the first emitted value and complete
      switchMap((user) => {
        const invitationValidated = this.getInvitationValidated();
        if (isSignUpRoute) {
          if (!invitationValidated) {
            // Redirect to invitation page if invitation not validated
            return of(this.router.createUrlTree(['/auth/invitation']));
          }
          // Allow access to sign-up if invitation is validated
          return of(true);
        }

        if (user && user.emailVerified) {
          // User is authenticated, allow access
          return of(true);
        } else {
          // User is not authenticated, redirect to sign-in with returnUrl
          return of(this.router.createUrlTree(['/auth/sign-in'], { queryParams: { returnUrl: state.url } }));
        }
      })
    );
  }

  /**
   * Retrieve the invitationValidated flag from localStorage.
   * 
   * @returns boolean - True if invitation is validated, else false.
   */
  private getInvitationValidated(): boolean {
    try {
      const value = localStorage.getItem('invitationValidated');
      return value === 'true';
    } catch (error) {
      console.error('Failed to retrieve invitation validated flag from localStorage', error);
      return false;
    }
  }
}
