import { Component, OnInit } from '@angular/core';
import { Database, ref, get, push, update, child, remove, onValue } from '@angular/fire/database';
import { Auth, authState } from '@angular/fire/auth';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { formatDate } from '@angular/common';
import { CalendarOptions } from '@fullcalendar/core';
import { TranslateService } from '@ngx-translate/core';
import enLocale from '@fullcalendar/core/locales/en-gb';
import zhLocale from '@fullcalendar/core/locales/zh-cn'; // Chinese (Simplified)
import { NotifierService } from 'angular-notifier';

interface Task {
  id: string;
  type: string;
  date: string;
  userId: string;
  userName: string;
  profileUrl: string;
  reminder?: boolean; // Added reminder property
}

interface ActiveUser {
  userId: string;
  userName: string;
  profileUrl: string;
}

@Component({
  selector: 'app-clean-bin-schedule',
  templateUrl: './clean-bin-schedule.component.html',
  styleUrls: ['./clean-bin-schedule.component.css']
})
export class CleanBinScheduleComponent implements OnInit {
  fullTasks: Task[] = []; // All tasks for the address (for calendar)
  ownTasks: Task[] = []; // Upcoming tasks for the logged-in user (for task list)
  allTasks: Task[] = []; // All upcoming tasks for all users
  currentDate: string = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  userId: string = '';
  userName: string = '';
  address: string = ''; // Address fetched from Firebase
  profileUrl: string = 'assets/user.png'; // Default profile URL
  showModal: boolean = false; // Control modal visibility
  selectedDate: string = ''; // Holds the selected date

  taskTypes: { type: string; label: string }[] = [];
  newTask: Task = { id: '', type: '', date: '', userId: '', userName: '', profileUrl: '' };
  customTaskType: string = ''; // Store custom task type
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    selectable: true,
    editable: true,
    events: [], // This will be populated with tasks
    validRange: {
      start: this.currentDate, // Prevent selecting past dates
    },
    dateClick: this.openModal.bind(this),
    locale: 'en-gb' // Default to English
  };

  userRole: string = ''; // 用户角色
  activeUsers: ActiveUser[] = []; // 活跃用户列表，包含 profileUrl
  isAdmin: boolean = false; // 是否为管理员
  selectedUserId: string = ''; // 选定的用户ID（仅管理员）

  // 新增属性用于编辑任务
  isEditing: boolean = false; // 标识是否处于编辑模式
  editingTask: Task | null = null; // 当前编辑的任务
  originalTask: Task | null = null; // 原始任务数据，以便在取消时恢复

  // 新增属性用于消息显示
  successMessage: string = '';
  errorMessage: string = '';

  constructor(private db: Database, private auth: Auth, private translate: TranslateService, private notifier: NotifierService) {}

  ngOnInit(): void {
    // Subscribe to language changes
    this.translate.onLangChange.subscribe(langEvent => {
      this.setCalendarLocale(langEvent.lang);
    });

    // Set initial language
    this.setCalendarLocale(this.translate.currentLang || 'en');

    // Fetch authenticated user and load data
    authState(this.auth).subscribe(user => {
      if (user) {
        this.userId = user.uid;
        this.fetchUserData(); // Fetch user name, address, profile URL, and role
        this.loadTaskTypes();
      }
    });
  }

  // Handle task type change
  onTaskTypeChange(event: any) {
    if (event.target.value !== 'other') {
      this.customTaskType = ''; // Clear custom task type if not "Other"
    }
  }

  // Set calendar locale based on current language
  setCalendarLocale(lang: string) {
    if (lang === 'zh') {
      this.calendarOptions.locale = zhLocale;
    } else {
      this.calendarOptions.locale = enLocale;
    }
  }

  // Fetch user data (name, address, profileUrl, role) from Firebase
  fetchUserData() {
    const userRef = ref(this.db, `Accounts/${this.userId}`);
    get(userRef).then(snapshot => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        this.userName = userData.Name || 'User';
        this.address = userData.Location || 'Unknown Address';
        this.profileUrl = userData.profileUrl || 'assets/user.png'; // Fallback to default profile URL
        this.userRole = userData.role || 'User'; // Get user role, default to 'User'

        this.isAdmin = this.userRole === 'Task Management Admin';

        if (this.isAdmin) {
          this.loadActiveUsers(); // If admin, load active users list
        }

        this.loadTasks(); // Load tasks
      }
    }).catch(error => {
      console.error("Error fetching user data: ", error);
    });
  }

  // Load task types from Firebase
  loadTaskTypes() {
    const taskTypeRef = ref(this.db, `TaskTypes`);
    get(taskTypeRef).then(snapshot => {
      if (snapshot.exists()) {
        this.taskTypes = Object.values(snapshot.val());
      }
    }).catch(error => {
      console.error("Error fetching task types: ", error);
    });
  }

  // Load active users for admin
  loadActiveUsers() {
    if (!this.address) return;

    const usersRef = ref(this.db, `Addresses/${this.address}`);
    get(usersRef).then(snapshot => {
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        this.activeUsers = Object.keys(usersData)
          .filter(userId => usersData[userId].status === 'Active')
          .map(userId => ({
            userId: userId,
            userName: usersData[userId].name || 'Unnamed User',
            profileUrl: usersData[userId].profileUrl || 'assets/user.png' // Fetch profileUrl
          }));
      } else {
        this.activeUsers = [];
      }
    }).catch(error => {
      console.error("Error fetching active users: ", error);
      this.activeUsers = [];
    });
  }

  // Load tasks for the current address from Firebase
  loadTasks() {
    if (!this.address) return;
  
    const tasksRef = ref(this.db, `Tasks/${this.address}`);
    
    onValue(
      tasksRef, 
      snapshot => {
        if (snapshot.exists()) {
          const tasksData = snapshot.val();
          this.fullTasks = Object.keys(tasksData).flatMap(userId => 
            Object.keys(tasksData[userId]).map(taskId => ({
              id: taskId,
              type: tasksData[userId][taskId].type,
              date: tasksData[userId][taskId].date,
              userId: userId,
              userName: tasksData[userId][taskId].userName,
              profileUrl: tasksData[userId][taskId].profileUrl || 'assets/user.png',
              reminder: tasksData[userId][taskId].reminder ?? true // Ensure reminder is boolean
            }))
          );
          this.filterOwnTasks();
          this.filterAllTasks();
          this.updateCalendarEvents();
        } else {
          this.fullTasks = [];
          this.ownTasks = [];
          this.allTasks = [];
          this.calendarOptions.events = [];
        }
      },
      error => { // Correct error handling
        console.error("Error loading tasks: ", error);
        this.errorMessage = 'Failed to load tasks.';
        setTimeout(() => this.errorMessage = '', 3000); // Hide after 3 seconds
      }
    );
  }
  

  // Filter tasks specific to the logged-in user and upcoming
  filterOwnTasks() {
    const today = new Date(this.currentDate);
    this.ownTasks = this.fullTasks.filter(task => task.userId === this.userId && new Date(task.date) >= today);
  }

  // Filter all upcoming tasks for all users
  filterAllTasks() {
    const today = new Date(this.currentDate);
    this.allTasks = this.fullTasks.filter(task => new Date(task.date) >= today);
    // Optionally, sort the tasks by date
    this.allTasks.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }

  // Update calendar with all task events for the address
  updateCalendarEvents() {
    this.calendarOptions.events = this.fullTasks.map(task => ({
      id: task.id,
      // Use TranslateService to translate task type
      title: `${task.userName}: ${this.translate.instant(task.type)}`,
      start: task.date
    }));
  }

  // Open custom modal and store the selected date
  openModal(arg: any): void {
    this.selectedDate = arg.dateStr; // Auto-populate the date in the backend
    this.showModal = true;
  }

  // Close custom modal
  closeModal(): void {
    this.showModal = false;
    this.newTask = { id: '', type: '', date: '', userId: '', userName: '', profileUrl: '' };
    this.customTaskType = '';
    this.selectedUserId = ''; // Reset selected user ID
    this.isEditing = false; // Reset editing state
    this.editingTask = null; // Clear editing task
    this.originalTask = null; // Clear original task
  }

  // Save task to Firebase (add or edit)
  saveTask(): void {
    // If "Other" is selected, set custom task type
    let taskType = this.newTask.type;
    if (taskType === 'other' && this.customTaskType) {
      taskType = this.customTaskType; // Assign custom task type
    }

    // Determine which user to assign the task to
    const assignUserId = this.isAdmin ? this.selectedUserId : this.userId;
    const assignUser = this.isAdmin ? this.activeUsers.find(user => user.userId === this.selectedUserId) : null;
    const assignUserName = this.isAdmin && assignUser ? assignUser.userName : this.userName;
    const assignProfileUrl = this.isAdmin && assignUser ? assignUser.profileUrl : this.profileUrl;

    if (taskType && this.selectedDate && assignUserId) {
      if (this.isEditing && this.editingTask) {
        // Edit existing task
        const taskRef = ref(this.db, `Tasks/${this.address}/${assignUserId}/${this.editingTask.id}`);
        const updatedTask: Task = {
          ...this.editingTask,
          type: taskType,
          date: this.selectedDate,
          userId: assignUserId,
          userName: assignUserName,
          profileUrl: assignProfileUrl
        };
        update(taskRef, updatedTask).then(() => {
          this.successMessage = 'Task updated successfully.';
          this.closeModal();
          setTimeout(() => this.successMessage = '', 3000); // Hide after 3 seconds
        }).catch(error => {
          console.error("Error updating task: ", error);
          this.errorMessage = 'Failed to update task.';
          setTimeout(() => this.errorMessage = '', 3000); // Hide after 3 seconds
        });
      } else {
        // Add new task
        const newTaskId = push(child(ref(this.db), `Tasks/${this.address}/${assignUserId}`)).key;
        this.newTask.id = newTaskId || '';
        this.newTask.type = taskType;
        this.newTask.date = this.selectedDate;
        this.newTask.userId = assignUserId;
        this.newTask.userName = assignUserName;
        this.newTask.profileUrl = assignProfileUrl; // Set assigned user's profileUrl
        this.newTask.reminder = true;

        const taskRef = ref(this.db, `Tasks/${this.address}/${assignUserId}/${this.newTask.id}`);
        update(taskRef, this.newTask).then(() => {
          this.successMessage = 'Task added successfully.';
          this.closeModal();
          setTimeout(() => this.successMessage = '', 3000); // Hide after 3 seconds
        }).catch(error => {
          console.error("Error adding task: ", error);
          this.errorMessage = 'Failed to add task.';
          setTimeout(() => this.errorMessage = '', 3000); // Hide after 3 seconds
        });
      }
    } else {
      // Handle missing required fields
      this.errorMessage = 'Please fill in all required fields.';
      setTimeout(() => this.errorMessage = '', 3000); // Hide after 3 seconds
    }
  }

  onImageError(event: Event) {
    // Cast the event target to HTMLImageElement and set the fallback image
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = 'assets/taskplaceholder.png';
  }

  // Delete task from Firebase
  deleteTask(task: Task) {

    let taskRef = ref(this.db, `Tasks/${this.address}/${this.userId}/${task.id}`);
    if(this.isAdmin)
    {
        const assignUserId = this.isAdmin ? task.userId : this.userId;
       taskRef = ref(this.db, `Tasks/${this.address}/${assignUserId}/${task.id}`);
    }
    
    remove(taskRef).then(() => {
      // No need to manually remove from fullTasks because onValue listener will handle it
      this.successMessage = 'Task deleted successfully.';
      setTimeout(() => this.successMessage = '', 3000); // Hide after 3 seconds
    }).catch(error => {
      console.error("Error removing task: ", error);
      this.errorMessage = 'Failed to delete task.';
      setTimeout(() => this.errorMessage = '', 3000); // Hide after 3 seconds
    });
  }

  // Edit task method
  editTask(task: Task): void {
    this.isEditing = true;
    this.editingTask = { ...task }; // Create a copy of the task to avoid direct modification
    this.originalTask = { ...task }; // Keep original task data to restore if canceled
    this.selectedDate = task.date;
    
    // If admin, allow changing the assigned user
    if (this.isAdmin) {
      this.selectedUserId = task.userId;
    }
    
    this.showModal = true;
  }
  
  // Optional: Cancel edit task method
  cancelEdit(): void {
    if (this.originalTask) {
      this.editingTask = { ...this.originalTask };
    }
    this.closeModal();
  }

  /**
   * Toggles the reminder state of a task.
   * @param task The task to toggle the reminder for.
   * @param event The change event from the checkbox.
   */
  toggleReminder(task: Task, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    // Update the local task object
    task.reminder = isChecked;
  
    // Reference to the specific task in Firebase
    const taskRef = ref(this.db, `Tasks/${this.address}/${task.userId}/${task.id}`);
  
    // Update the reminder property in Firebase
    update(taskRef, { reminder: isChecked })
      .then(() => {
        // Fetch the appropriate translated message
        const messageKey = isChecked ? 'ADMIN.REMINDER_SET' : 'ADMIN.REMINDER_REMOVED';
        const successMsg = isChecked ? 'success' : 'info';
        const message = this.translate.instant(messageKey);
  
        // Notify the user with the translated message
        this.notifier.notify(successMsg, message);
        
        // Optionally, display success message using notifier
        // this.notifier.notify('success', message);
  
        // If you have successMessage and errorMessage properties for UI display
        // this.successMessage = message;
        // setTimeout(() => this.successMessage = '', 3000); // Hide after 3 seconds
      })
      .catch(error => {
        console.error("Error updating reminder: ", error);
        
        // Fetch the translated error message
        const errorMsg = this.translate.instant('ADMIN.ERROR_REMINDER_UPDATE');
  
        // Notify the user about the error
        this.notifier.notify('error', errorMsg);
        
        // If you have errorMessage property for UI display
        // this.errorMessage = errorMsg;
        // setTimeout(() => this.errorMessage = '', 3000); // Hide after 3 seconds
      });
  }
}
