// src/environments/environment.ts
export const environment = {
    production: true,
    firebaseConfig: {
      apiKey: "AIzaSyBxq78CIgakzbuPdn5ddISgFjEyDsQO2Es",
      authDomain: "homerent-30d11.firebaseapp.com",
      databaseURL: "https://homerent-30d11-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "homerent-30d11",
      storageBucket: "homerent-30d11.appspot.com",
      messagingSenderId: "534533484122",
      appId: "1:534533484122:web:d76232ef806a9d97897263",
      measurementId: "G-6HN8VKKEK6"
    },
    recaptcha:{
      siteKey: "6Lc_rl8qAAAAAMtd4avu6Xy-oOBgtBlWhFEcZLZA"
    }
  };
  