<!-- src/app/admin/admin.component.html -->
<div class="admin-container">
  <!-- Admin Content -->
  <div class="admin-content">

      <!-- Choose Address Section -->
      <div class="admin-card animate__animated animate__fadeIn mb-4">
          <h2>
      <i class="fas fa-map-marker-alt me-2"></i> {{ 'ADMIN.CHOOSE_ADDRESS_LABEL' | translate }}
    </h2>
          <div class="form-group mb-3">
              <label for="address">{{ 'ADMIN.CHOOSE_ADDRESS_LABEL' | translate }}</label>
              <select id="address" [(ngModel)]="selectedAddress" name="address" (change)="filterUsersByAddress()" class="form-control" required>
                  <option value="" disabled selected>{{ 'ADMIN.SELECT_ADDRESS_OPTION' | translate }}</option>
                  <option *ngFor="let address of addresses" [value]="address">{{ address }}</option>
              </select>
              <div *ngIf="!selectedAddress && email !== ''" class="error-message text-danger mt-1">
                  {{ 'ADMIN.ADDRESS_REQUIRED' | translate }}
              </div>
          </div>
      </div>

      <!-- Invitation Code Generation Card -->
      <div class="admin-card animate__animated animate__fadeIn mb-4" *ngIf="selectedAddress">
          <h2>
      <i class="fas fa-key me-2"></i> {{ 'ADMIN.GENERATE_INVITATION_CODE' | translate }}
    </h2>
          <form>
              <!-- Email Input (Optional) -->
              <div class="form-group mb-3">
                  <label for="email">{{ 'ADMIN.ENTER_EMAIL_LABEL' | translate }} ({{ 'ADMIN.OPTIONAL' | translate }})</label>
                  <input type="email" id="email" [(ngModel)]="email" name="email" class="form-control" [placeholder]="'ADMIN.PLACEHOLDER_ENTER_EMAIL' | translate" />
                  <div *ngIf="email && !isValidEmail(email)" class="error-message text-danger mt-1">
                      {{ 'ADMIN.INVALID_EMAIL' | translate }}
                  </div>
              </div>
              <!-- Generate Button -->
              <button type="button" (click)="generateInvitationCode()" class="btn btn-primary" [disabled]="isSubmitting">
                  <i class="fas fa-sync-alt"></i> {{ isSubmitting ? ('ADMIN.GENERATING' | translate) : ('ADMIN.GENERATE_CODE_BUTTON' | translate) }}
              </button>
          </form>
          <!-- Display the Generated Code -->
          <div *ngIf="generatedCode" class="generated-code mt-4">
              <p>{{ 'ADMIN.GENERATED_CODE' | translate }}</p>
              <div class="code-box d-flex align-items-center">
                  <input type="text" [value]="generatedCode" readonly class="form-control me-2" />
                  <button (click)="copyCode()" class="btn btn-secondary">
                      <i class="fas fa-copy me-1"></i> {{ 'ADMIN.COPY_CODE' | translate }}
                  </button>
              </div>
          </div>
      </div>

      <!-- Add Notification Card -->
      <div class="admin-card animate__animated animate__fadeIn mb-4" *ngIf="selectedAddress">
          <h2>
            <i class="fas fa-bell me-2"></i> {{ 'ADMIN.NOTIFICATIONS.ADD_NOTIFICATION' | translate }}
          </h2>
          <form [formGroup]="notificationForm" (ngSubmit)="submitNotification()">
              <!-- Send to All Users Checkbox -->
              <div class="form-check mb-3">
                  <input type="checkbox" id="sendToAll" formControlName="sendToAll" class="form-check-input" />
                  <label for="sendToAll" class="form-check-label">
                      {{ 'ADMIN.NOTIFICATIONS.SEND_TO_ALL_LABEL' | translate }}
                  </label>
              </div>

              <!-- Send to Individuals Checkbox -->
              <div class="form-check mb-3">
                  <input type="checkbox" id="sendToIndividuals" formControlName="sendToIndividuals" class="form-check-input" />
                  <label for="sendToIndividuals" class="form-check-label">
                      {{ 'ADMIN.NOTIFICATIONS.SEND_TO_INDIVIDUALS_LABEL' | translate }}
                  </label>
              </div>

              <!-- New: Pin to Top Checkbox -->
              <div class="form-check mb-3">
                  <input type="checkbox" id="top" formControlName="top" class="form-check-input" />
                  <label for="top" class="form-check-label">
                      {{ 'ADMIN.NOTIFICATIONS.PIN_TO_TOP_LABEL' | translate }}
                  </label>
              </div>

              <!-- User Selection (Visible if Send to Individuals is checked) -->
              <div *ngIf="notificationForm.get('sendToIndividuals')?.value" class="mb-3">
                  <label class="form-label">{{ 'ADMIN.NOTIFICATIONS.SELECT_USERS_LABEL' | translate }}</label>
                  <div class="user-selection">
                      <div *ngIf="isLoadingUsers" class="text-center">
                          <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">{{ 'ADMIN.NOTIFICATIONS.LOADING' | translate }}</span>
                          </div>
                      </div>
                      <div *ngIf="!isLoadingUsers && filteredUsers.length === 0" class="text-muted">
                          {{ 'ADMIN.NOTIFICATIONS.NO_USERS_AVAILABLE' | translate }}
                      </div>
                      <div *ngIf="!isLoadingUsers && filteredUsers.length > 0" class="user-list">
                          <div *ngFor="let user of filteredUsers" class="form-check">
                              <input type="checkbox" [id]="'user-' + user.UID" [value]="user.UID" (change)="onUserSelectionChange($event, user.UID)" [checked]="selectedUserIds.has(user.UID)" class="form-check-input" />
                              <label [for]="'user-' + user.UID" class="form-check-label">
                                  <img [src]="user.profileUrl ?? 'assets/user.png'" alt="{{ user.Name }}" class="user-avatar me-2" /> {{ user.Name }}
                              </label>
                          </div>
                      </div>
                  </div>
                  <!-- Validation Message (Optional since recipients are optional) -->
                  <!--
        <div *ngIf="notificationForm.get('selectedUsers')?.invalid && notificationForm.get('selectedUsers')?.touched" class="error-message text-danger mt-1">
          {{ 'ADMIN.NOTIFICATIONS.NO_USERS_SELECTED' | translate }}
        </div>
        -->
              </div>

              <!-- Notification Type -->
              <div class="form-group mb-3">
                  <label for="notificationType">{{ 'ADMIN.NOTIFICATIONS.TYPE_LABEL' | translate }}</label>
                  <select id="notificationType" formControlName="type" class="form-control" [disabled]="notificationForm.get('sendToAll')?.value || notificationForm.get('sendToIndividuals')?.value" required>
                      <option value="" disabled selected>{{ 'ADMIN.NOTIFICATIONS.SELECT_TYPE_OPTION' | translate }}</option>
                      <option value="general">{{ 'ADMIN.NOTIFICATIONS.TYPE_GENERAL' | translate }}</option>
                      <option value="emergency">{{ 'ADMIN.NOTIFICATIONS.TYPE_EMERGENCY' | translate }}</option>
                      <option value="maintenance">{{ 'ADMIN.NOTIFICATIONS.TYPE_MAINTENANCE' | translate }}</option>
                      <option value="update">{{ 'ADMIN.NOTIFICATIONS.TYPE_UPDATE' | translate }}</option>
                  </select>
                  <div *ngIf="notificationForm.get('type')?.invalid && notificationForm.get('type')?.touched" class="error-message text-danger mt-1">
                      {{ 'ADMIN.NOTIFICATIONS.NO_TYPE_SELECTED' | translate }}
                  </div>
              </div>

              <!-- Notification Title -->
              <div class="form-group mb-3">
                  <label for="notificationTitle">{{ 'ADMIN.NOTIFICATIONS.TITLE_LABEL' | translate }}</label>
                  <input type="text" id="notificationTitle" formControlName="title" class="form-control" placeholder="{{ 'ADMIN.NOTIFICATIONS.TITLE_PLACEHOLDER' | translate }}" required />
                  <div *ngIf="notificationForm.get('title')?.invalid && notificationForm.get('title')?.touched" class="error-message text-danger mt-1">
                      {{ 'ADMIN.NOTIFICATIONS.NO_TITLE_ENTERED' | translate }}
                  </div>
              </div>

              <!-- Notification Content -->
              <div class="form-group mb-3">
                  <label for="notificationContent">{{ 'ADMIN.NOTIFICATIONS.CONTENT_LABEL' | translate }}</label>
                  <textarea id="notificationContent" formControlName="content" class="form-control" rows="4" placeholder="{{ 'ADMIN.NOTIFICATIONS.CONTENT_PLACEHOLDER' | translate }}" required></textarea>
                  <div *ngIf="notificationForm.get('content')?.invalid && notificationForm.get('content')?.touched" class="error-message text-danger mt-1">
                      {{ 'ADMIN.NOTIFICATIONS.NO_CONTENT_ENTERED' | translate }}
                  </div>
              </div>

              <!-- Notification End Time -->
              <div class="form-group mb-3">
                  <label for="endTime">{{ 'ADMIN.NOTIFICATIONS.END_TIME_LABEL' | translate }}</label>
                  <input type="datetime-local" id="endTime" formControlName="endTime" class="form-control" required />
                  <div *ngIf="notificationForm.get('endTime')?.invalid && notificationForm.get('endTime')?.touched" class="error-message text-danger mt-1">
                      {{ 'ADMIN.NOTIFICATIONS.INVALID_END_TIME' | translate }}
                  </div>
              </div>

              <!-- Submit Button -->
              <button type="submit" class="btn btn-primary w-100 w-md-auto" [disabled]="notificationForm.invalid || isSubmitting">
                  <i class="fas fa-paper-plane me-2"></i> {{ isSubmitting ? ('ADMIN.NOTIFICATIONS.SUBMITTING_BUTTON' | translate) : ('ADMIN.NOTIFICATIONS.SUBMIT_BUTTON' | translate) }}
              </button>
          </form>
      </div>
      <!-- User Management Card -->
      <div class="admin-user-card animate__animated animate__fadeIn mb-4" *ngIf="selectedAddress">
          <!-- Header with Title and Add Button -->
          <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
              <h2 class="mb-2 mb-md-0">
        <i class="fas fa-users me-2"></i> {{ 'ADMIN.USER_MANAGEMENT' | translate }}
      </h2>
              <!-- You can add an "Add User" button here if needed -->
          </div>
          <!-- User Management Table -->
          <div class="table-responsive">
              <table class="table table-striped table-bordered user-table" *ngIf="selectedAddress">
                  <thead class="table-primary">
                      <tr>
                          <th>{{ 'ADMIN.USER_NAME' | translate }}</th>
                          <th>{{ 'ADMIN.USER_EMAIL' | translate }}</th>
                          <th>{{ 'ADMIN.STATUS' | translate }}</th>
                          <th>{{ 'ADMIN.ACTIONS' | translate }}</th>
                      </tr>
                  </thead>
                  <tbody *ngFor="let user of usersByAddress[selectedAddress]">
                      <tr>
                          <td [attr.data-label]="'ADMIN.USER_NAME' | translate">{{ user.Name }}</td>
                          <td [attr.data-label]="'ADMIN.USER_EMAIL' | translate">{{ user.Email }}</td>
                          <td [attr.data-label]="'ADMIN.STATUS' | translate">
                              <span [ngClass]="{'active-status': user.Status === 'Active', 'inactive-status': user.Status === 'Inactive'}">
                {{ user.Status }}
              </span>
                          </td>
                          <td [attr.data-label]="'ADMIN.ACTIONS' | translate">
                              <button (click)="toggleUserStatus(user)" class="btn btn-status btn-sm me-2">
                                  {{ user.Status === 'Active' ? ('ADMIN.SET_INACTIVE' | translate) : ('ADMIN.SET_ACTIVE' | translate) }}
                              </button>
                              <button (click)="openViewDataModal(user)" class="btn btn-info btn-sm me-2">
                                  <i class="fas fa-eye"></i>
                              </button>
                              <button (click)="deleteUser(user)" class="btn btn-danger btn-sm">
                                  <i class="fas fa-trash-alt"></i>
                              </button>
                          </td>
                      </tr>
                      <!-- Display User Reports if Any -->
                      <tr *ngIf="user.reports && user.reports.length > 0">
                          <td colspan="4">
                              <h4>{{ 'ADMIN.USER_REPORTS' | translate }}</h4>
                              <ul>
                                  <li *ngFor="let report of user.reports">{{ report }}</li>
                              </ul>
                          </td>
                      </tr>
                      <!-- Message When No Users Found -->
                      <tr *ngIf="usersByAddress[selectedAddress].length === 0">
                          <td colspan="4" class="text-center">{{ 'ADMIN.NO_USERS_FOUND' | translate }}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
      <!-- Utilities Fees Management Section -->
      <div class="admin-card animate__animated animate__fadeIn mb-4" *ngIf="selectedAddress">
            <h2>
            <i class="fas fa-clipboard-list me-2"></i>
            {{ 'ADMIN.UTILITIES_FEES_MANAGEMENT.TITLE' | translate }}
            </h2>
        
            <!-- Year and Month Selection -->
            <div class="row mb-3">
            <div class="col-md-6 mb-3 mb-md-0">
                <label for="yearSelect" class="form-label">
                {{ 'ADMIN.UTILITIES_FEES_MANAGEMENT.SELECT_YEAR' | translate }}
                </label>
                <select id="yearSelect" class="form-select" [(ngModel)]="selectedYear" (change)="onYearOrMonthChange()">
                <option *ngFor="let year of availableYears" [value]="year">{{ year }}</option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="monthSelect" class="form-label">
                {{ 'ADMIN.UTILITIES_FEES_MANAGEMENT.SELECT_MONTH' | translate }}
                </label>
                <select id="monthSelect" class="form-select" [(ngModel)]="selectedMonth" (change)="onYearOrMonthChange()">
                <option *ngFor="let month of months" [value]="month.value">{{ month.name }}</option>
                </select>
            </div>
            </div>
        
            <!-- Users List -->
            <div *ngIf="utilitiesFeesData">
            <table class="table table-striped table-bordered">
                <thead class="table-secondary">
                <tr>
                    <th>{{ 'ADMIN.USER_NAME' | translate }}</th>
                    <th>{{ 'ADMIN.UTILITIES_FEES_MANAGEMENT.INTERNET_FEE_PAID' | translate }}</th>
                    <th>{{ 'ADMIN.UTILITIES_FEES_MANAGEMENT.TRASH_FEE_PAID' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of filteredUsers">
                    <td>{{ user.Name }}</td>
                    <td>
                    <input type="checkbox" [(ngModel)]="utilitiesFeesData.internetfeepaid[user.UID]" />
                    </td>
                    <td>
                    <input type="checkbox" [(ngModel)]="utilitiesFeesData.trashfeepaid[user.UID]" />
                    </td>
                </tr>
                </tbody>
            </table>
            <!-- Save Button -->
            <button class="btn btn-success" (click)="saveUtilitiesFeesData()">
                <i class="fas fa-save me-1"></i>
                {{ 'ADMIN.UTILITIES_FEES_MANAGEMENT.SAVE_CHANGES' | translate }}
            </button>
            </div>
        
            <!-- Message When No Users Found -->
            <div *ngIf="filteredUsers.length === 0">
            <p class="text-muted text-center">{{ 'ADMIN.NO_USERS_FOUND' | translate }}</p>
            </div>
      </div>

      <!-- Billing Management Section -->
      <div class="admin-card shadow-sm rounded-lg p-4 mb-4 animate__animated animate__fadeIn" *ngIf="selectedAddress">
          <!-- Header with Title and Add Button -->
          <div class="row justify-content-between align-items-center mb-4">
              <div class="col-md-8">
                  <h2 class="mb-2 mb-md-0">
                    <i class="fas fa-file-invoice-dollar me-2 text-primary"></i>
                    {{ 'ADMIN.BILLING_MANAGEMENT.MANAGE_BILLING' | translate }}
                   </h2>
              </div>
              <div class="col-md-4 text-md-end">
                  <button class="btn btn-success btn-sm add-billing-button w-100 w-md-auto" (click)="openAddBillingModal()">
                      <i class="fas fa-plus-circle me-2"></i>{{ 'ADMIN.ADD_NEW_BILLING_PERIOD' | translate }}
                  </button>
              </div>
          </div>
          <!-- Current Billing Data Table for Desktop -->
          <div class="table-responsive d-none d-md-block animate__animated animate__fadeIn">
              <table class="table table-bordered table-hover align-middle billing-table">
                  <thead class="table-primary">
                      <tr>
                          <th scope="col">{{ 'BILL_CALCULATORS.PROVIDER_ICON' | translate }}</th>
                          <th scope="col">{{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}</th>
                          <th scope="col">{{ 'BILL_CALCULATORS.STATUS' | translate }}</th>
                          <th scope="col">{{ 'BILL_CALCULATORS.BILL_IMAGE' | translate }}</th>
                          <th scope="col">{{ 'BILL_CALCULATORS.PERIOD' | translate }}</th>
                          <th scope="col">{{ 'BILL_CALCULATORS.IS_PER_PERSON' | translate }}</th>
                          <th scope="col">{{ 'ADMIN.BILLING_MANAGEMENT.ACTIONS' | translate }}</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let currentBillingData of currentBillingDataList">
                          <td>
                              <img [src]="currentBillingData?.providerIcon" alt="Provider Icon" class="provider-icon">
                          </td>
                          <td>€{{ currentBillingData?.amount }}</td>
                          <td>
                              <span [ngClass]="{'active-status': currentBillingData?.status === 'Active', 'inactive-status': currentBillingData?.status === 'Inactive'}">
                                {{ currentBillingData?.status }}
                                </span>
                          </td>
                          <td>
                              <a *ngIf="currentBillingData?.billImage != '' && currentBillingData?.billImage != null" [href]="currentBillingData?.billImage" target="_blank" class="btn btn-sm btn-outline-primary">
                                  <i class="fas fa-eye me-1"></i>{{ 'BILL_CALCULATORS.VIEW_BILL_IMAGE' | translate }}
                              </a>
                          </td>
                          <td>{{ currentBillingData?.period }}</td>
                          <td>{{ currentBillingData?.isPerPerson === true ? ('Yes' | translate) : ('No' | translate) }}</td>
                          <td>
                              <button class="btn btn-primary btn-sm me-2" (click)="openEditBillingModal(currentBillingData)">
                                  <i class="fas fa-edit me-1"></i>{{ 'ADMIN.EDIT_CURRENT_BILLING' | translate }}
                              </button>
                              <button class="btn btn-danger btn-sm" (click)="deleteCurrentBilling(currentBillingData)">
                                  <i class="fas fa-trash me-1"></i>{{ 'ADMIN.DELETE_BILLING' | translate }}
                              </button>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <!-- Current Billing Data Cards for Mobile -->
          <div *ngFor="let currentBillingData of currentBillingDataList" class="billing-card card mb-3 d-block d-md-none animate__animated animate__fadeInUp">
              <div class="card-header bg-primary text-white">
                  <div class="d-flex align-items-center">
                      <img [src]="currentBillingData?.providerIcon" alt="Provider Icon" class="provider-icon me-2">
                      <h5 class="mb-0">{{ 'ADMIN.CURRENT_BILLING' | translate }}</h5>
                  </div>
              </div>
              <div class="card-body">
                  <div class="mb-2">
                      <strong>{{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}:</strong> €{{ currentBillingData?.amount }}
                  </div>
                  <div class="mb-2">
                      <strong>{{ 'BILL_CALCULATORS.STATUS' | translate }}:</strong>
                      <span [ngClass]="{'active-status': currentBillingData?.status === 'Active', 'inactive-status': currentBillingData?.status === 'Inactive'}">
            {{ currentBillingData?.status }}
          </span>
                  </div>
                  <div class="mb-2">
                      <strong>{{ 'BILL_CALCULATORS.BILL_IMAGE' | translate }}:</strong>
                      <a [href]="currentBillingData?.billImage" target="_blank" class="btn btn-sm btn-outline-primary">
                          <i class="fas fa-eye me-1"></i>{{ 'BILL_CALCULATORS.VIEW_BILL_IMAGE' | translate }}
                      </a>
                  </div>
                  <div class="mb-3">
                      <strong>{{ 'BILL_CALCULATORS.PERIOD' | translate }}:</strong> {{ currentBillingData?.period }}
                  </div>
                  <!-- Edit and Delete Buttons -->
                  <div class="d-flex justify-content-end">
                      <button class="btn btn-primary btn-sm me-2" (click)="openEditBillingModal(currentBillingData)">
                          <i class="fas fa-edit me-1"></i>{{ 'ADMIN.EDIT_CURRENT_BILLING' | translate }}
                      </button>
                      <button class="btn btn-danger btn-sm" (click)="deleteCurrentBilling(currentBillingData)">
                          <i class="fas fa-trash me-1"></i>{{ 'ADMIN.DELETE_BILLING' | translate }}
                      </button>
                  </div>
              </div>
          </div>
          <!-- Message When No Billing Data is Found -->
          <div *ngIf="currentBillingDataList.length === 0" class="no-billing-data text-muted text-center py-5 animate__animated animate__fadeIn">
              <i class="fas fa-exclamation-triangle fa-3x mb-3"></i>
              <p>{{ 'ADMIN.BILLING_MANAGEMENT.NO_BILLING_DATA_FOUND' | translate }}</p>
          </div>
      </div>

      <!-- Historical Billing Data Section -->
      <div class="admin-card animate__animated animate__fadeIn mb-4" *ngIf="selectedAddress && previousBillingData.length > 0">
           
            <h3> <img src='assets/past-bill.png' alt="Provider Icon" class="provider-icon me-2"> {{ 'ADMIN.PREVIOUS_BILLS' | translate }}</h3>
          <div class="table-responsive">
              <table class="table table-striped table-bordered">
                  <thead class="table-secondary">
                      <tr>
                          <th scope="col">{{ 'BILL_CALCULATORS.PROVIDER_ICON' | translate }}</th>
                          <th>{{ 'BILL_CALCULATORS.PERIOD' | translate }}</th>
                          <th>{{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}</th>
                          <th>{{ 'BILL_CALCULATORS.STATUS' | translate }}</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let bill of previousBillingData">
                            <td>
                                <img [src]="bill?.providerIcon" alt="Provider Icon" class="provider-icon">
                            </td>
                          <td>{{ bill.period }}</td>
                          <td>€{{ bill.amount }}</td>
                          <td>
                              <span [ngClass]="{'active-status': bill.status === 'Active', 'inactive-status': bill.status === 'Inactive'}">
                {{ bill.status }}
              </span>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>

      <!-- Billing Management Card -->
      <div class="admin-card shadow-sm rounded-lg p-4 mb-4 animate__animated animate__fadeIn" *ngIf="selectedAddress">
          <!-- Header with Title and Manage Button -->
          <div class="row justify-content-between align-items-center mb-3">
              <div class="col-md-8">
                  <h2 class="mb-2 mb-md-0">
          <i class="fas fa-cogs me-2 text-primary"></i>
          {{ 'ADMIN.BILLING_MANAGEMENT.MANAGE_BILLING_DATA' | translate }}
        </h2>
              </div>
              <div class="col-md-4 text-md-end">
                  <button class="btn btn-primary w-100 w-md-auto manage-billing-button" (click)="openBillingDataModal()" [disabled]="!selectedAddress">
                      <i class="fas fa-edit me-1"></i> {{ 'ADMIN.BILLING_MANAGEMENT.MANAGE_BILLING_DATA' | translate }}
                  </button>
              </div>
          </div>
          <!-- Display Current Billing individual Details if Available -->
          <div *ngIf="billingData" class="">
              <h4 class="mb-3 text-success">
        <i class="fas fa-info-circle me-2"></i>
        {{ 'ADMIN.BILLING_MANAGEMENT.CURRENT_BILLING_DATA' | translate }}
      </h4>
              <div class="table-responsive">
                  <table class="table table-bordered table-hover">
                      <tbody>
                          <tr>
                              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.CARBON_TAX' | translate }}</th>
                              <td>€{{ billingData.carbonTax }}</td>
                          </tr>
                          <tr>
                              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.ENERGY_COST_PER_KW' | translate }}</th>
                              <td>€{{ billingData.energyCostPerKW }}</td>
                          </tr>
                          <tr>
                              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.ENERGY_NIGHT_COST_PER_KW' | translate }}</th>
                              <td>€{{ billingData.nightEnergyCostPerKW }}</td>
                          </tr>
                          <tr>
                              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.GAS_COST_PER_M3' | translate }}</th>
                              <td>€{{ billingData.gasCostPerM3 }}</td>
                          </tr>
                          <tr>
                              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.GAS_STANDING_CHARGE_PER_DAY' | translate }}</th>
                              <td>€{{ billingData.gasStandingChargePerDay }}</td>
                          </tr>
                          <tr>
                              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.INTERNET_FEE' | translate }}</th>
                              <td>€{{ billingData.internet_fee }}</td>
                          </tr>
                          <tr>
                              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.PSO_LEVY' | translate }}</th>
                              <td>€{{ billingData.psoLevy }}</td>
                          </tr>
                          <tr>
                              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.STANDING_CHARGE_PER_DAY' | translate }}</th>
                              <td>€{{ billingData.standingChargePerDay }}</td>
                          </tr>
                          <tr>
                              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.TRASH_FEE' | translate }}</th>
                              <td>€{{ billingData.trash_fee }}</td>
                          </tr>
                          <tr>
                              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.VAT_RATE' | translate }}</th>
                              <td>{{ billingData.vatRate }}%</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
          <!-- Message When No Billing Data is Found -->
          <div *ngIf="!billingData" class="no-billing-data text-muted text-center py-5 animate__animated animate__fadeIn">
              <i class="fas fa-exclamation-triangle fa-3x mb-3"></i>
              <p>{{ 'ADMIN.BILLING_MANAGEMENT.NO_BILLING_DATA_FOUND' | translate }}</p>
          </div>
      </div>

      <!-- Data Modal for Viewing User Data -->
      <div class="modal-overlay" *ngIf="isModalOpen">
          <div class="modal-content animate__animated animate__zoomIn">
              <div class="modal-header">
                  <h3>
                    <i class="fas fa-eye"></i>
                    {{ 'ADMIN.VIEW_DATA' | translate }}
                  </h3>
                  <button class="close-button" (click)="closeModal()">&times;</button>
              </div>
              <div class="modal-body p-4">
                  <form [formGroup]="userDataForm" (ngSubmit)="saveUserData()">
                      <!-- Deposit Amount -->
                      <div class="form-group mb-3">
                          <label for="depositAmount">{{ 'ADMIN.DEPOSIT_AMOUNT' | translate }}</label>
                          <input type="number" id="depositAmount" formControlName="depositAmount" class="form-control" [class.is-invalid]="userDataForm.get('depositAmount')?.invalid && userDataForm.get('depositAmount')?.touched" placeholder="{{ 'ADMIN.PLACEHOLDER_DEPOSIT_AMOUNT' | translate }}"
                          min="0" step="0.01" />
                          <div *ngIf="userDataForm.get('depositAmount')?.invalid && userDataForm.get('depositAmount')?.touched" class="invalid-feedback">
                              {{ 'ADMIN.INVALID_DEPOSIT_AMOUNT' | translate }}
                          </div>
                      </div>
                      <!-- Contract Start Date -->
                      <div class="form-group mb-3">
                          <label for="contractStartDate">{{ 'ADMIN.CONTRACT_START_DATE' | translate }}</label>
                          <input type="date" id="contractStartDate" formControlName="contractStartDate" class="form-control" [class.is-invalid]="userDataForm.get('contractStartDate')?.invalid && userDataForm.get('contractStartDate')?.touched" />
                          <div *ngIf="userDataForm.get('contractStartDate')?.invalid && userDataForm.get('contractStartDate')?.touched" class="invalid-feedback">
                              {{ 'ADMIN.INVALID_CONTRACT_START_DATE' | translate }}
                          </div>
                      </div>
                      <!-- Contract Expire Date -->
                      <div class="form-group mb-3">
                          <label for="contractExpireDate">{{ 'ADMIN.CONTRACT_EXPIRE_DATE' | translate }}</label>
                          <input type="date" id="contractExpireDate" formControlName="contractExpireDate" class="form-control" [class.is-invalid]="userDataForm.get('contractExpireDate')?.invalid && userDataForm.get('contractExpireDate')?.touched" />
                          <div *ngIf="userDataForm.get('contractExpireDate')?.invalid && userDataForm.get('contractExpireDate')?.touched" class="invalid-feedback">
                              {{ 'ADMIN.INVALID_CONTRACT_EXPIRE_DATE' | translate }}
                          </div>
                      </div>
                      <!-- Contract Link -->
                      <div class="form-group mb-3">
                          <label for="contractLink">{{ 'ADMIN.CONTRACT_LINK' | translate }}</label>
                          <input type="url" id="contractLink" formControlName="contractLink" class="form-control" [class.is-invalid]="userDataForm.get('contractLink')?.invalid && userDataForm.get('contractLink')?.touched" placeholder="{{ 'ADMIN.PLACEHOLDER_CONTRACT_LINK' | translate }}"
                          />
                          <div *ngIf="userDataForm.get('contractLink')?.invalid && userDataForm.get('contractLink')?.touched" class="invalid-feedback">
                              {{ 'ADMIN.INVALID_CONTRACT_LINK' | translate }}
                          </div>
                      </div>
                      <!-- Next Payment Date -->
                      <div class="form-group mb-3">
                          <label for="nextPaymentDate">{{ 'ADMIN.NEXT_PAYMENT_DATE' | translate }}</label>
                          <input type="date" id="nextPaymentDate" formControlName="nextPaymentDate" class="form-control" [class.is-invalid]="userDataForm.get('nextPaymentDate')?.invalid && userDataForm.get('nextPaymentDate')?.touched" />
                          <div *ngIf="userDataForm.get('nextPaymentDate')?.invalid && userDataForm.get('nextPaymentDate')?.touched" class="invalid-feedback">
                              {{ 'ADMIN.INVALID_NEXT_PAYMENT_DATE' | translate }}
                          </div>
                      </div>
                      <!-- Next Payment Amount -->
                      <div class="form-group mb-3">
                          <label for="nextPaymentAmount">{{ 'ADMIN.NEXT_PAYMENT_AMOUNT' | translate }}</label>
                          <input type="number" id="nextPaymentAmount" formControlName="nextPaymentAmount" class="form-control" [class.is-invalid]="userDataForm.get('nextPaymentAmount')?.invalid && userDataForm.get('nextPaymentAmount')?.touched" placeholder="{{ 'ADMIN.PLACEHOLDER_NEXT_PAYMENT_AMOUNT' | translate }}"
                          min="0" step="0.01" />
                          <div *ngIf="userDataForm.get('nextPaymentAmount')?.invalid && userDataForm.get('nextPaymentAmount')?.touched" class="invalid-feedback">
                              {{ 'ADMIN.INVALID_NEXT_PAYMENT_AMOUNT' | translate }}
                          </div>
                      </div>
                      <!-- Role Assignment Field -->
                      <div class="form-group mb-3">
                          <label for="role">{{ 'ADMIN.ROLE_ASSIGNMENT' | translate }}</label>
                          <select id="role" formControlName="role" class="form-select" required>
                              <option *ngFor="let role of roles" [value]="role">{{ role | translate }}</option>
                          </select>
                          <div *ngIf="userDataForm.get('role')?.invalid && userDataForm.get('role')?.touched" class="invalid-feedback">
                              {{ 'ADMIN.INVALID_ROLE' | translate }}
                          </div>
                      </div>
                      <!-- Save Button -->
                      <button type="submit" class="btn btn-success w-100 w-md-auto mt-3">
                          <i class="fas fa-save"></i> {{ 'ADMIN.SAVE_DATA_BUTTON' | translate }}
                      </button>
                  </form>
              </div>
          </div>
      </div>

      <!-- Billing Data Modal for Adding/Editing Billing Details -->
      <div *ngIf="isBillingDataModalOpen" class="modal-overlay">
          <div class="modal-content animate__animated animate__zoomIn">
              <div class="modal-header bg-primary text-white">
                  <h5 class="modal-title">
          <i class="fas fa-cogs me-2"></i>
          {{ billingData ? ('ADMIN.BILLING_MANAGEMENT.EDIT_BILLING_DATA' | translate) : ('ADMIN.BILLING_MANAGEMENT.ADD_BILLING_DATA' | translate) }}
        </h5>
                  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="closeBillingDataModal()"></button>
              </div>
              <div class="modal-body p-4">
                  <form [formGroup]="billingDataForm" (ngSubmit)="saveBillingDataDetails()">
                      <div class="row">
                          <!-- Carbon Tax -->
                          <div class="col-md-6 mb-3">
                              <label for="carbonTax" class="form-label">
                                  <i class="fas fa-tree me-2 text-success"></i> {{ 'ADMIN.BILLING_MANAGEMENT.CARBON_TAX' | translate }}
                              </label>
                              <input type="number" id="carbonTax" formControlName="carbonTax" class="form-control" min="0" required />
                              <div *ngIf="billingDataForm.get('carbonTax')?.invalid && billingDataForm.get('carbonTax')?.touched" class="text-danger">
                                  {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_CARBON_TAX' | translate }}
                              </div>
                          </div>
                          <!-- Energy Cost Per KW -->
                          <div class="col-md-6 mb-3">
                              <label for="energyCostPerKW" class="form-label">
                                  <i class="fas fa-bolt me-2 text-warning"></i> {{ 'ADMIN.BILLING_MANAGEMENT.ENERGY_COST_PER_KW' | translate }}
                              </label>
                              <input type="number" id="energyCostPerKW" formControlName="energyCostPerKW" class="form-control" min="0" required />
                              <div *ngIf="billingDataForm.get('energyCostPerKW')?.invalid && billingDataForm.get('energyCostPerKW')?.touched" class="text-danger">
                                  {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_ENERGY_COST' | translate }}
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <!-- Gas Cost Per M3 -->
                          <div class="col-md-6 mb-3">
                              <label for="gasCostPerM3" class="form-label">
                                  <i class="fas fa-fire me-2 text-danger"></i> {{ 'ADMIN.BILLING_MANAGEMENT.GAS_COST_PER_M3' | translate }}
                              </label>
                              <input type="number" id="gasCostPerM3" formControlName="gasCostPerM3" class="form-control" min="0" required />
                              <div *ngIf="billingDataForm.get('gasCostPerM3')?.invalid && billingDataForm.get('gasCostPerM3')?.touched" class="text-danger">
                                  {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_GAS_COST' | translate }}
                              </div>
                          </div>
                          <!-- Gas Standing Charge Per Day -->
                          <div class="col-md-6 mb-3">
                              <label for="gasStandingChargePerDay" class="form-label">
                                  <i class="fas fa-calendar-alt me-2 text-secondary"></i> {{ 'ADMIN.BILLING_MANAGEMENT.GAS_STANDING_CHARGE_PER_DAY' | translate }}
                              </label>
                              <input type="number" id="gasStandingChargePerDay" formControlName="gasStandingChargePerDay" class="form-control" min="0" required />
                              <div *ngIf="billingDataForm.get('gasStandingChargePerDay')?.invalid && billingDataForm.get('gasStandingChargePerDay')?.touched" class="text-danger">
                                  {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_GAS_CHARGE' | translate }}
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <!-- Internet Fee -->
                          <div class="col-md-6 mb-3">
                              <label for="internet_fee" class="form-label">
                                  <i class="fas fa-wifi me-2 text-info"></i> {{ 'ADMIN.BILLING_MANAGEMENT.INTERNET_FEE' | translate }}
                              </label>
                              <input type="number" id="internet_fee" formControlName="internet_fee" class="form-control" min="0" required />
                              <div *ngIf="billingDataForm.get('internet_fee')?.invalid && billingDataForm.get('internet_fee')?.touched" class="text-danger">
                                  {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_INTERNET_FEE' | translate }}
                              </div>
                          </div>
                          <!-- PSO Levy -->
                          <div class="col-md-6 mb-3">
                              <label for="psoLevy" class="form-label">
                                  <i class="fas fa-hand-holding-usd me-2 text-success"></i> {{ 'ADMIN.BILLING_MANAGEMENT.PSO_LEVY' | translate }}
                              </label>
                              <input type="number" id="psoLevy" formControlName="psoLevy" class="form-control" min="0" required />
                              <div *ngIf="billingDataForm.get('psoLevy')?.invalid && billingDataForm.get('psoLevy')?.touched" class="text-danger">
                                  {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_PSO_LEVY' | translate }}
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <!-- Standing Charge Per Day -->
                          <div class="col-md-6 mb-3">
                              <label for="standingChargePerDay" class="form-label">
                                  <i class="fas fa-money-bill-wave me-2 text-primary"></i> {{ 'ADMIN.BILLING_MANAGEMENT.STANDING_CHARGE_PER_DAY' | translate }}
                              </label>
                              <input type="number" id="standingChargePerDay" formControlName="standingChargePerDay" class="form-control" min="0" required />
                              <div *ngIf="billingDataForm.get('standingChargePerDay')?.invalid && billingDataForm.get('standingChargePerDay')?.touched" class="text-danger">
                                  {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_STANDING_CHARGE' | translate }}
                              </div>
                          </div>
                          <!-- Trash Fee -->
                          <div class="col-md-6 mb-3">
                              <label for="trash_fee" class="form-label">
                                  <i class="fas fa-trash me-2 text-muted"></i> {{ 'ADMIN.BILLING_MANAGEMENT.TRASH_FEE' | translate }}
                              </label>
                              <input type="number" id="trash_fee" formControlName="trash_fee" class="form-control" min="0" required />
                              <div *ngIf="billingDataForm.get('trash_fee')?.invalid && billingDataForm.get('trash_fee')?.touched" class="text-danger">
                                  {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_TRASH_FEE' | translate }}
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <!-- VAT Rate -->
                          <div class="col-md-6 mb-3">
                              <label for="vatRate" class="form-label">
                                  <i class="fas fa-percent me-2 text-success"></i> {{ 'ADMIN.BILLING_MANAGEMENT.VAT_RATE' | translate }}
                              </label>
                              <input type="number" id="vatRate" formControlName="vatRate" class="form-control" min="0" required />
                              <div *ngIf="billingDataForm.get('vatRate')?.invalid && billingDataForm.get('vatRate')?.touched" class="text-danger">
                                  {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_VAT_RATE' | translate }}
                              </div>
                          </div>
                          <div class="col-md-6 mb-3">
                              <label for="energyCostPerKW" class="form-label">
                                  <i class="fas fa-bolt me-2 text-warning"></i> {{ 'ADMIN.BILLING_MANAGEMENT.ENERGY_NIGHT_COST_PER_KW' | translate }}
                              </label>
                              <input type="number" id="nightEnergyCostPerKW" formControlName="nightEnergyCostPerKW" class="form-control" min="0" required />
                              <div *ngIf="billingDataForm.get('nightEnergyCostPerKW')?.invalid && billingDataForm.get('nightEnergyCostPerKW')?.touched" class="text-danger">
                                  {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_ENERGY_COST' | translate }}
                              </div>
                          </div>
                      </div>
                      <!-- Form Actions -->
                      <div class="d-flex justify-content-end">
                          <button type="button" class="btn btn-secondary me-2" (click)="closeBillingDataModal()">
                              <i class="fas fa-times me-1"></i>{{ 'ADMIN.BILLING_MANAGEMENT.CANCEL' | translate }}
                          </button>
                          <button type="submit" class="btn btn-success">
                              <i class="fas fa-save me-1"></i> {{ 'ADMIN.BILLING_MANAGEMENT.SAVE_BILLING_DATA' | translate }}
                          </button>
                      </div>
                  </form>
              </div>
          </div>
      </div>

     <!-- Billing Data Modal for Adding New Billing Period -->
    <div *ngIf="isAddBillingModalOpen" class="modal-overlay">
        <div class="modal-content animate__animated animate__zoomIn">
            <div class="modal-header bg-success text-white">
                <h5 class="modal-title">
                    <i class="fas fa-plus-circle me-2"></i>
                    {{ 'ADMIN.ADD_NEW_BILLING_PERIOD' | translate }}
                </h5>
                <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="closeAddBillingModal()"></button>
            </div>
            <div class="modal-body p-4">
                <form [formGroup]="newBillingForm" (ngSubmit)="saveNewBillingData()">
                    <!-- Type Select Dropdown -->
                    <div class="mb-3">
                        <label for="billingType" class="form-label">
                            <i class="fas fa-list-alt me-2 text-primary"></i> {{ 'BILL_CALCULATORS.TYPE' | translate }}
                        </label>
                        <select formControlName="type" class="form-select" id="billingType" (change)="onTypeChange($event)" required>
                            <option value="" disabled selected>{{ 'ADMIN.BILLING_MANAGEMENT.SELECT_TYPE' | translate }}</option>
                            <option *ngFor="let type of uniqueTypes" [value]="type">{{ type | translate }}</option>
                        </select>
                        <div *ngIf="newBillingForm.get('type')?.invalid && newBillingForm.get('type')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_TYPE' | translate }}
                        </div>
                    </div>
                    <!-- Provider Icon Select Dropdown -->
                    <div class="mb-3" *ngIf="filteredProviderIcons.length > 0">
                        <label for="billingProviderIcon" class="form-label">
                            <i class="fas fa-camera me-2 text-primary"></i> {{ 'BILL_CALCULATORS.PROVIDER_ICON' | translate }}
                        </label>
                        <select formControlName="providerIcon" class="form-select" id="billingProviderIcon" required>
                            <option value="" disabled selected>{{ 'ADMIN.BILLING_MANAGEMENT.SELECT_PROVIDER_ICON' | translate }}</option>
                            <option *ngFor="let icon of filteredProviderIcons" [value]="icon.iconUrl">
                                {{ icon.label }}
                            </option>
                        </select>
                        <div *ngIf="newBillingForm.get('providerIcon')?.invalid && newBillingForm.get('providerIcon')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_PROVIDER_ICON' | translate }}
                        </div>
                    </div>
                    <!-- Per Person Radio Buttons -->
                    <div class="mb-3">
                        <label class="form-label">
                            <i class="fas fa-users me-2 text-primary"></i> {{ 'BILL_CALCULATORS.IS_PER_PERSON' | translate }}
                        </label>
                        <div class="d-flex align-items-center">
                            <div class="form-check me-3">
                                <input type="radio" formControlName="isPerPerson" class="form-check-input" id="isPerPersonYes" [value]="true">
                                <label for="isPerPersonYes" class="form-check-label">{{ 'Yes' | translate }}</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" formControlName="isPerPerson" class="form-check-input" id="isPerPersonNo" [value]="false">
                                <label for="isPerPersonNo" class="form-check-label">{{ 'No' | translate }}</label>
                            </div>
                        </div>
                        <div *ngIf="newBillingForm.get('isPerPerson')?.invalid && newBillingForm.get('isPerPerson')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_IS_PER_PERSON' | translate }}
                        </div>
                    </div>
                    <!-- Amount Input -->
                    <div class="mb-3">
                        <label for="newBillingAmount" class="form-label">
                            <i class="fas fa-euro-sign me-2 text-warning"></i> {{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}
                        </label>
                        <input type="number" formControlName="amount" class="form-control" id="newBillingAmount" min="0" required>
                        <div *ngIf="newBillingForm.get('amount')?.invalid && newBillingForm.get('amount')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_AMOUNT' | translate }}
                        </div>
                    </div>
                    <!-- Period Input -->
                    <div class="mb-3">
                        <label for="newBillingPeriod" class="form-label">
                            <i class="fas fa-calendar-alt me-2 text-secondary"></i> {{ 'BILL_CALCULATORS.PERIOD' | translate }}
                        </label>
                        <input type="text" formControlName="period" class="form-control" id="newBillingPeriod" placeholder="e.g., January 2024" required>
                        <div *ngIf="newBillingForm.get('period')?.invalid && newBillingForm.get('period')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_PERIOD' | translate }}
                        </div>
                    </div>
                    
                    <!-- Status Select -->
                    <div class="mb-3">
                        <label for="newBillingStatus" class="form-label">
                            <i class="fas fa-user-check me-2 text-success"></i> {{ 'BILL_CALCULATORS.STATUS' | translate }}
                        </label>
                        <select formControlName="status" class="form-select" id="newBillingStatus" required>
                            <option value="Active">{{ 'ADMIN.STATUS_ACTIVE' | translate }}</option>
                            <option value="Completed">{{ 'ADMIN.STATUS_COMPLETED' | translate }}</option>
                        </select>
                        <div *ngIf="newBillingForm.get('status')?.invalid && newBillingForm.get('status')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_STATUS' | translate }}
                        </div>
                    </div>
                    <!-- Bill Image Input -->
                    <div class="form-group">
                        <label for="billingImageNew" class="form-label">
                          <i class="fas fa-image me-2 text-info"></i>
                          {{ 'ADMIN.BILLING_MANAGEMENT.BILL_IMAGE' | translate }}
                        </label>
                        <input
                          type="file"
                          (change)="onFileSelectedNew($event)"
                          class="form-control"
                          id="billingImageNew"
                          accept="image/*"
                        />
                      </div>
                    <!-- Form Actions -->
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-secondary me-2" (click)="closeAddBillingModal()">
                            <i class="fas fa-times me-1"></i>{{ 'ADMIN.BILLING_MANAGEMENT.CANCEL' | translate }}
                        </button>
                        <button type="submit" class="btn btn-success"    [disabled]="newBillingForm.invalid">
                            <i class="fas fa-save me-1"></i>{{ 'ADMIN.BILLING_MANAGEMENT.SAVE_NEW_BILLING' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

      <!-- Edit Billing Data Modal -->
    <div *ngIf="isBillingModalOpen" class="modal-overlay">
        <div class="modal-content animate__animated animate__zoomIn">
            <div class="modal-header bg-primary text-white">
                <h5 class="modal-title">
                    <i class="fas fa-edit me-2"></i>
                    {{ 'ADMIN.EDIT_CURRENT_BILLING' | translate }}
                </h5>
                <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="closeBillingModal()"></button>
            </div>
            <div class="modal-body p-4">
                <form [formGroup]="billingForm" (ngSubmit)="saveBillingData()">
                    <!-- Type Select Dropdown -->
                    <div class="mb-3">
                        <label for="billingType" class="form-label">
                            <i class="fas fa-list-alt me-2 text-primary"></i> {{ 'BILL_CALCULATORS.TYPE' | translate }}
                        </label>
                        <select formControlName="type" class="form-select" id="billingType" (change)="onTypeChange($event)" required>
                            <option value="" disabled selected>{{ 'ADMIN.BILLING_MANAGEMENT.SELECT_TYPE' | translate }}</option>
                            <option *ngFor="let type of uniqueTypes" [value]="type">{{ type | translate }}</option>
                        </select>
                        <div *ngIf="billingForm.get('type')?.invalid && billingForm.get('type')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_TYPE' | translate }}
                        </div>
                    </div>
                    <!-- Provider Icon Select Dropdown -->
                    <div class="mb-3" *ngIf="filteredProviderIcons.length > 0">
                        <label for="billingProviderIcon" class="form-label">
                            <i class="fas fa-camera me-2 text-primary"></i> {{ 'BILL_CALCULATORS.PROVIDER_ICON' | translate }}
                        </label>
                        <select formControlName="providerIcon" class="form-select" id="billingProviderIcon" required>
                            <option value="" disabled selected>{{ 'ADMIN.BILLING_MANAGEMENT.SELECT_PROVIDER_ICON' | translate }}</option>
                            <option *ngFor="let icon of filteredProviderIcons" [value]="icon.iconUrl">
                                {{ icon.label }}
                            </option>
                        </select>
                        <div *ngIf="billingForm.get('providerIcon')?.invalid && billingForm.get('providerIcon')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_PROVIDER_ICON' | translate }}
                        </div>
                    </div>
                        <!-- Per Person Radio Buttons -->
                        <div class="mb-3">
                        <label class="form-label">
                            <i class="fas fa-users me-2 text-primary"></i> {{ 'BILL_CALCULATORS.IS_PER_PERSON' | translate }}
                        </label>
                        <div class="d-flex align-items-center">
                            <div class="form-check me-3">
                                <input type="radio" formControlName="isPerPerson" class="form-check-input" id="isPerPersonYes" [value]="true">
                                <label for="isPerPersonYes" class="form-check-label">{{ 'Yes' | translate }}</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" formControlName="isPerPerson" class="form-check-input" id="isPerPersonNo" [value]="false">
                                <label for="isPerPersonNo" class="form-check-label">{{ 'No' | translate }}</label>
                            </div>
                        </div>
                        <div *ngIf="billingForm.get('isPerPerson')?.invalid && billingForm.get('isPerPerson')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_IS_PER_PERSON' | translate }}
                        </div>
                    </div>
                    <!-- Amount Input -->
                    <div class="mb-3">
                        <label for="billingAmount" class="form-label">
                            <i class="fas fa-euro-sign me-2 text-warning"></i> {{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}
                        </label>
                        <input type="number" formControlName="amount" class="form-control" id="billingAmount" min="0" required>
                        <div *ngIf="billingForm.get('amount')?.invalid && billingForm.get('amount')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_AMOUNT' | translate }}
                        </div>
                    </div>
                    <!-- Period Input -->
                    <div class="mb-3">
                        <label for="billingPeriod" class="form-label">
                            <i class="fas fa-calendar-alt me-2 text-secondary"></i> {{ 'BILL_CALCULATORS.PERIOD' | translate }}
                        </label>
                        <input type="text" formControlName="period" class="form-control" id="billingPeriod" placeholder="e.g., January 2024" required>
                        <div *ngIf="billingForm.get('period')?.invalid && billingForm.get('period')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_PERIOD' | translate }}
                        </div>
                    </div>
                    
                    <!-- Status Select -->
                    <div class="mb-3">
                        <label for="billingStatus" class="form-label">
                            <i class="fas fa-user-check me-2 text-success"></i> {{ 'BILL_CALCULATORS.STATUS' | translate }}
                        </label>
                        <select formControlName="status" class="form-select" id="billingStatus" required>
                            <option value="Active">{{ 'ADMIN.STATUS_ACTIVE' | translate }}</option>
                            <option value="Completed">{{ 'ADMIN.STATUS_COMPLETED' | translate }}</option>
                        </select>
                        <div *ngIf="billingForm.get('status')?.invalid && billingForm.get('status')?.touched" class="text-danger">
                            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_STATUS' | translate }}
                        </div>
                    </div>
                    <!-- Bill Image Input -->
                    <div class="form-group">
                        <label for="billingImageEdit" class="form-label">
                          <i class="fas fa-image me-2 text-info"></i>
                          {{ 'ADMIN.BILLING_MANAGEMENT.BILL_IMAGE' | translate }}
                        </label>
                        <input
                          type="file"
                          (change)="onFileSelectedEdit($event)"
                          class="form-control"
                          id="billingImageEdit"
                          accept="image/*"
                        />
                      </div>
                    <!-- Form Actions -->
                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-secondary me-2" (click)="closeBillingModal()">
                            <i class="fas fa-times me-1"></i>{{ 'ADMIN.BILLING_MANAGEMENT.CANCEL' | translate }}
                        </button>
                        <button type="submit" class="btn btn-success"  [disabled]="billingForm.invalid">
                            <i class="fas fa-save me-1"></i>{{ 'ADMIN.BILLING_MANAGEMENT.SAVE_BILLING_DATA' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

  </div>
</div>