<!-- receipt-tracker.component.html -->
<div class="container-fluid d-flex flex-column flex-lg-row animate__animated animate__fadeIn">

  <!-- Left Side: Summary Section -->
  <div class="summary-card card p-4 flex-grow-1 me-lg-3 mb-3 mb-lg-0">
    <div class="d-flex justify-content-between align-items-center">
      <h2>
        <img src="assets/revenue.png" alt="Revenue Icon" class="icon-label me-2" />
        {{ 'REVENUE' | translate:{ year: year } }}
      </h2>
    </div>
    <hr>
    <div class="d-flex justify-content-between flex-wrap">
      <!-- Income Section -->
      <div class="income-section me-lg-3 mb-3 mb-lg-0">
        <h3>{{ 'INCOME' | translate }} :</h3>
        <div class="income-amount animate__animated animate__fadeInDown">
          <img src="assets/euro.png" alt="Euro Icon" class="icon-label" />
          {{ totalIncomeThisYear | number:'1.0-2' }}
        </div>
      </div>
    
      <!-- Expense Section -->
      <div class="expense-section">
        <h3>{{ 'EXPENSE' | translate }} :</h3>
        <div class="revenue-amount animate__animated animate__fadeInDown">
          <img src="assets/euro.png" alt="Euro Icon" class="icon-label" />
          {{ totalSpentThisYear | number:'1.0-2' }}
        </div>
      </div>
    </div>
    <hr />
    <h3>
      <img src="assets/graph.png" alt="Graph Icon" class="icon-label me-2" />
      {{ 'REVENUE_BY_TYPE' | translate }}
    </h3>
    <div class="chart-container mb-4">
      <canvas baseChart 
              [data]="revenueData" 
              [options]="chartOptions" 
              [type]="'doughnut'"></canvas>
    </div>

    <div class="revenue-details">
      <div class="detail-item animate__animated animate__fadeInUp" *ngFor="let label of revenueLabels; let i = index">
        <span class="color-indicator" [ngClass]="{
          'sale': label === 'General',
          'rental': label === 'Cleaning Product',
          'parts': label === 'Others',
          'labor': label === 'Transfers'
        }"></span> 
        {{ label | translate }}: €{{ revenueData.datasets[0].data[i] | number:'1.0-2' }}
      </div>
    </div>
  </div>

  <!-- Right Side: Upload Section -->
  <div class="upload-card card p-4 flex-grow-1 animate__animated animate__fadeInRight">   
    <h2 class="mt-4">
      <img src="assets/funds-transfer.png" alt="Funds Transfer Icon" class="icon-label me-2" />
      {{ 'TRANSFER_FUND' | translate }}
    </h2>
    <form (submit)="transferFunds()" novalidate>
      <!-- Transfer Amount Input -->
      <div class="form-group mb-3 position-relative">
        <i class="fas fa-euro-sign input-icon"></i>
        <input type="number" id="transferAmount" class="form-control ps-5" [(ngModel)]="transfer.amount" name="transferAmount" placeholder="{{ 'AMOUNT_TO_TRANSFER' | translate }}" required />
      </div>
    
      <!-- Transfer Proof Image Upload -->
      <div class="form-group mb-3 position-relative">
        <i class="fas fa-file-upload input-icon"></i>
        <input type="file" id="proofImage" class="form-control ps-5" (change)="onProofImageChange($event)" accept="image/*,application/pdf" required />
      </div>
    
      <!-- Progress Bar for Transfer Upload -->
      <div *ngIf="uploadTransferProgress !== null && transfer.proofImageUrl === ''" class="progress-container mb-3">
        <progress [value]="uploadTransferProgress" max="100"></progress>
        <span>{{ uploadTransferProgress | number: '1.0-0' }}%</span>
      </div>
    
      <!-- Submit Button -->
      <button type="button" class="btn btn-primary w-100" (click)="confirmTransfer()">
        <i class="fas fa-money-bill-wave me-2"></i>{{ 'TRANSFER_FUND_BUTTON' | translate }}
      </button>
    </form>
    <hr />
    <h2>
      <img src="assets/uploadreceipt.png" alt="Upload Receipt Icon" class="icon-label me-2" />
      {{ 'UPLOAD_RECEIPT' | translate }}
    </h2>
    <!-- Display Available Balance -->
    <p class="text-muted">{{ 'AVAILABLE_BALANCE' | translate:{ availableBalance: availableBalance | number:'1.0-2' } }}</p>
    <form (submit)="uploadReceipt()" novalidate>
      <!-- Item Type Selection -->
      <div class="form-group mb-3 position-relative">
        <i class="fas fa-tag input-icon"></i>
        <select id="itemType" class="form-control ps-5" [(ngModel)]="receipt.itemType" name="itemType" required>
          <option value="" disabled selected>{{ 'ITEM_TYPE' | translate }}</option>
          <option *ngFor="let type of itemTypes" [value]="type">{{ type | translate }}</option>
        </select>
      </div>
    
      <!-- Price Input -->
      <div class="form-group mb-3 position-relative">
        <i class="fas fa-euro-sign input-icon"></i>
        <input type="number" id="price" class="form-control ps-5" [(ngModel)]="receipt.price" name="price" placeholder="{{ 'PRICE' | translate }}" required />
      </div>
    
      <!-- Date Input -->
      <div class="form-group mb-3 position-relative">
        <i class="fas fa-calendar-alt input-icon"></i>
        <input type="date" id="date" class="form-control ps-5" [(ngModel)]="receipt.date" name="date" placeholder="{{ 'DATE' | translate }}" required />
      </div>
    
      <!-- Receipt Image Upload -->
      <div class="form-group mb-3 position-relative">
        <i class="fas fa-file-image input-icon"></i>
        <input type="file" id="receiptImage" class="form-control ps-5" (change)="onFileChange($event)" accept="image/*,application/pdf" required />
      </div>
    
      <!-- Description Input -->
      <div class="form-group mb-3 position-relative">
        <i class="fas fa-align-left input-icon"></i>
        <input type="text" id="description" class="form-control ps-5" [(ngModel)]="receipt.description" name="description" placeholder="{{ 'DESCRIPTION_OPTIONAL' | translate }}" />
      </div>
    
      <!-- Validation Message for Insufficient Balance -->
      <div *ngIf="receipt.price > availableBalance" class="alert alert-danger">
        {{ 'NOT_ENOUGH_BALANCE' | translate }}
      </div>
    
      <!-- Validation Message for Missing Image -->
      <div *ngIf="!receipt.imageUrl && receiptFile" class="alert alert-warning">
        {{ 'UPLOAD_IMAGE_WARNING' | translate }}
      </div>
    
      <!-- Progress Bar for Receipt Upload -->
      <div *ngIf="uploadProgress !== null && receipt.imageUrl === ''" class="progress-container mb-3">
        <progress [value]="uploadProgress" max="100"></progress>
        <span>{{ uploadProgress | number: '1.0-0' }}%</span>
      </div>
    
      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary w-100" [disabled]="!isReceiptFormValid()">
        <i class="fas fa-upload me-2"></i>{{ 'UPLOAD_RECEIPT_BUTTON' | translate }}
      </button>
    </form>
  </div>


  <!-- Combined Available Balance and Monthly Expense Card -->
  <div class="balance-expense-card card p-4 flex-grow-1 animate__animated animate__fadeInUp">
    <!-- Available Balance -->
    <h2>
      <img src="assets/wallet.png" alt="Wallet Icon" class="icon-label me-2" />
      {{ 'AVAILABLE_BALANCE_HEADER' | translate }}
    </h2>
    <div class="balance-amount animate__animated animate__fadeInDown">
      €{{ availableBalance | number:'1.0-2' }}
    </div>
    <hr />

    <!-- Monthly Expenses -->
    <h3>
      <img src="assets/calendar.png" alt="Calendar Icon" class="icon-label me-2" />
      {{ 'MONTHLY_EXPENSES' | translate }}
    </h3>
    <div *ngFor="let month of months">
      <button 
        class="btn btn-link w-100 text-left d-flex justify-content-between align-items-center" 
        (click)="toggleMonth(month)" 
        [attr.aria-expanded]="month.isExpanded"
        [attr.aria-controls]="'month-expenses-' + month.name"
      >
        {{ month.name }}
        <span>
          <i class="fas" [ngClass]="month.isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </span>
      </button>
      <div 
        *ngIf="month.isExpanded" 
        id="month-expenses-{{month.name}}" 
        class="mb-3"
      >
        <div 
          class="monthly-expense-item card p-3 mb-2" 
          [ngClass]="{
            'receipt-card': item.expenseType === 'receipt',
            'transfer-card': item.expenseType === 'transfer'
          }"
          *ngFor="let item of month.expenses"
        >
          <p><strong>{{ 'NAME' | translate }}</strong>{{ item.name }}</p>
          <p><strong>{{ 'ITEM_TYPE' | translate }}: </strong> {{ item.itemType }}</p>
          <p><strong>{{ 'EXPENSE_TYPE' | translate }}</strong> {{ item.expenseType === 'receipt' ? ('RECEIPT' | translate) : ('TRANSFER' | translate) }}</p>
          <p><strong>{{ 'DATE' | translate }}:</strong>  {{ item.date }}</p>
          <p><strong>{{ 'TOTAL' | translate }}</strong> €{{ item.price | number:'1.0-2' }}</p>
          <!-- Link to View Receipt or Transfer Proof -->
          <p *ngIf="item.expenseType === 'receipt' && item.imageUrl">
            <a href="{{ item.imageUrl }}" target="_blank" rel="noopener noreferrer">{{ 'VIEW_RECEIPT' | translate }}</a>
          </p>
          <p *ngIf="item.expenseType === 'transfer' && item.proofImageUrl">
            <a href="{{ item.proofImageUrl }}" target="_blank" rel="noopener noreferrer">{{ 'VIEW_TRANSFER_PROOF' | translate }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Pending Items Card -->
  <div *ngIf="userRole === 'Financial Management Admin'" class="pending-items-card card p-4 flex-grow-1 animate__animated animate__fadeInUp">
    <h2>
      <img src="assets/pending.png" alt="Pending Icon" class="icon-label me-2" />
      {{ 'PENDING_ITEMS' | translate }}
    </h2>
    <div *ngFor="let item of pendingItems" class="pending-item card p-3 mb-2">
      <!-- Display common data -->
      <p><strong>{{ 'TYPE' | translate }}:</strong> {{ item.data.type }}</p>
      <p><strong>{{ 'NAME' | translate }}:</strong> {{ item.data.userName }}</p>
      <p><strong>{{ 'DATE' | translate }}:</strong> {{ item.data.date | date:'medium' }}</p>
      
      <!-- Use type guards to display type-specific data -->
      <ng-container *ngIf="isReceipt(item.data)">
        <p *ngIf="item.data.description != null && item.data.description != ''"><strong>{{ 'DESCRIPTION' | translate }}:</strong> {{ item.data.description }}</p>
        <p><strong>{{ 'PRICE' | translate }}:</strong> €{{ item.data.price | number:'1.0-2' }}</p>
        <p><strong>{{ 'PRICE' | translate }}:</strong> €{{ item.data.price | number:'1.0-2' }}</p>
        <!-- Link to view receipt image -->
        <p *ngIf="item.data.imageUrl">
          <a href="{{ item.data.imageUrl }}" target="_blank" rel="noopener noreferrer">{{ 'VIEW_IMAGE' | translate }}</a>
        </p>
      </ng-container>

      <ng-container *ngIf="isTransfer(item.data)">
        <p><strong>{{ 'AMOUNT' | translate }}:</strong> €{{ item.data.amount | number:'1.0-2' }}</p>
        <!-- Link to view proof image -->
        <p *ngIf="item.data.proofImageUrl">
          <a href="{{ item.data.proofImageUrl }}" target="_blank" rel="noopener noreferrer">{{ 'VIEW_PROOF_IMAGE' | translate }}</a>
        </p>
      </ng-container>
      
      <!-- Approve and Reject Buttons -->
      <div class="d-flex justify-content-end">
        <button class="btn btn-success me-2" (click)="approveItem(item.key, item.data)">
          <i class="fas fa-check me-1"></i>{{ 'APPROVE' | translate }}
        </button>
        <button class="btn btn-danger" (click)="rejectItem(item.key, item.data)">
          <i class="fas fa-times me-1"></i>{{ 'REJECT' | translate }}
        </button>
      </div>
    </div>
  </div>



</div>

