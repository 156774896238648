<header class="toolbar d-flex justify-content-between align-items-center">
  <!-- Show logo only when the user is NOT signed in -->
  <img 
    src="assets/logo.png" 
    alt="Logo" 
    class="logo" 
    (click)="navigateHome()" 
  />

  <!-- Spacer to keep the right side aligned -->
  <div class="toolbar-spacer"></div>

  <!-- Show rest of the toolbar only when the user is signed in -->
  <div class="d-flex align-items-center justify-content-end">
    <!-- Language Selector -->
    <select [(ngModel)]="selectedLanguage" (change)="switchLanguage(selectedLanguage)" class="language-selector me-3">
      <option *ngFor="let lang of availableLanguages" [value]="lang.code">
        {{ lang.label }}
      </option>
    </select>
    
    <!-- Admin Icon (Visible to admins only) -->
    <img
      *ngIf="isAdmin"
      src="assets/settings.png"
      alt="Admin"
      class="admin-icon me-3"
      (click)="navigateToAdmin()"
    />
     <!-- Landlord Icon (Visible to landlords only) -->
     <img
     *ngIf="isLandlord"
     src="assets/landlord.png"
     alt="Landlord"
     class="landlord-icon me-3"
     (click)="navigateToLandlord()"
     title="Landlord Dashboard"
   />
    <!-- User Profile Icon (Uses profileImageUrl) -->
    <img
      *ngIf="authUser"
      [src]="profileImageUrl"
      alt="User Profile"
      class="user-icon me-3"
      (click)="navigateToProfile()"
    />
    
    <!-- Sign Out Button -->
    <button class="sign-out-button" (click)="signOut()" *ngIf="authUser">{{'SIGN_OUT' | translate}}</button>
  </div>
</header>
