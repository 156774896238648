import { Component } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Auth, authState } from '@angular/fire/auth';
import { ref, get, getDatabase } from 'firebase/database';
import {
  Chart,
  DoughnutController,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { faL } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bill-calculator',
  templateUrl: './bill-calculator.component.html',
  styleUrls: ['./bill-calculator.component.css']
})
export class BillCalculatorComponent {
  // Billing-related data to be fetched from Firebase
  internetFee: number = 0;
  trashFee: number = 0;

  // Pricing variables fetched from Firebase
  energyCostPerKW: number = 0;
  standingChargePerDay: number = 0;
  psoLevy: number = 0;
  vatRate: number = 0;
  gasCostPerM3: number = 0;
  gasStandingChargePerDay: number = 0;
  carbonTax: number = 0;
   // Properties for Payment Status
  utilitiesFeesExist: boolean = false;
  internetFeeData: string[] = [];
  trashFeeData: string[] = [];
  // Dynamically calculated intrub amount (Per Person)
  intrubamount: number = 0;

  totalBill: number = 0; // Total bill entered by user
  startDate: string = ''; // Start date for bill calculation
  endDate: string = '';   // End date for bill calculation

  // Tenants
  tenants: Array<{ 
    name: string; 
    days: number; 
    amount: number; 
    elecgas: number; 
    intrub: number; 
    profileUrl: string 
  }> = [];
  
  address: string = '';
  db = getDatabase();
  internetPaidColor: string = '#2196f3'; // Blue color for Internet Fee
  trashPaidColor: string = '#4caf50';    // Green color for Trash Fee
  unpaidColor: string = '#e0e0e0';  
  // Individual Billing Inputs
  individualBilling = {
    currentKW: 0,
    previousKW: 0,
    currentNightKW: 0,      // New field for Night Electricity
    previousNightKW: 0,     // New field for Night Electricity
    currentGas: 0,           // Gas fields
    previousGas: 0,          // Gas fields
    daysLived: 1
  };
  
  // New Variables for Form Controls
  electricityType: string = ''; // 'allDay' or 'dayNight'
  includeGas: boolean = false;
  year = new Date().getFullYear();
  // Individual Billing Results
  individualResults = {
    energyCost: 0,
    electricStandingChargeCost: 0,
    psoLevyCost: 0,
    vatAmount: 0,
    gasCost: 0,
    gasStandingChargeCost: 0,
    carbonTaxCost: 0,
    totalAmount: 0,
    nightEnergyCost: 0
  };
 // List of months
 months: string[] = [
  'MONTHS.JANUARY',
  'MONTHS.FEBRUARY',
  'MONTHS.MARCH',
  'MONTHS.APRIL',
  'MONTHS.MAY',
  'MONTHS.JUNE',
  'MONTHS.JULY',
  'MONTHS.AUGUST',
  'MONTHS.SEPTEMBER',
  'MONTHS.OCTOBER',
  'MONTHS.NOVEMBER',
  'MONTHS.DECEMBER',
];
  // Current Period Bill Data
  currentPeriodBills: Array<{ amount: number, period: string, status: string, billImage: string, providerIcon: string , type: string, isPerPerson: string}> = [];
  // Night Energy Cost Per KW (Assuming separate rate)
  nightEnergyCostPerKW: number = 0;
  uid: string = '';
  constructor(
    private auth: Auth,
    private notifier: NotifierService, 
    private translate: TranslateService
  ) {
   
  }

  ngOnInit() {
    Chart.register(DoughnutController, ArcElement, Tooltip, Legend, ChartDataLabels);
    this.fetchUserAddress(); // Fetch the user's address on component initialization
  }
  ngAfterViewInit(): void {
    // Check and render charts after view initialization
  }
  // Fetch the logged-in user's address from Firebase
  fetchUserAddress() {
    authState(this.auth).subscribe(user => {
      if (user && user.uid) {
        const userAddressRef = ref(this.db, `/Accounts/${user.uid}/Location`);
        this.uid = user.uid;
        get(userAddressRef).then(snapshot => {
          if (snapshot.exists()) {
            this.address = snapshot.val();
            this.fetchTenants(); // Fetch tenants based on address
            this.fetchCurrentPeriodBill(); // Fetch current period bill
            this.fetchPaymentStatus();
          } else {
            this.translate.get('NOTIFICATIONS.ADDRESS_NOT_FOUND').subscribe((msg: string) => {
              this.notifier.notify('error', msg);
            });
          }
        }).catch(error => {
          this.translate.get('NOTIFICATIONS.FETCH_ADDRESS_ERROR').subscribe((msg: string) => {
            this.notifier.notify('error', msg);
          });
          console.error('Error fetching address:', error);
        });
      }
    });
  }

  // Fetch billing data (internetFee, trashFee, energyCostPerKW, etc.) from Firebase
  fetchBillingData() {
    const billingRef = ref(this.db, `/billing/${this.address}`);
    get(billingRef).then(snapshot => {
      if (snapshot.exists()) {
        const billingData = snapshot.val();
        this.internetFee = billingData.internet_fee;
        this.trashFee = billingData.trash_fee;

        // Fetch pricing data
        this.energyCostPerKW = billingData.energyCostPerKW;
        this.standingChargePerDay = billingData.standingChargePerDay;
        this.psoLevy = billingData.psoLevy;
        this.vatRate = billingData.vatRate;
        this.gasCostPerM3 = billingData.gasCostPerM3;
        this.gasStandingChargePerDay = billingData.gasStandingChargePerDay;
        this.carbonTax = billingData.carbonTax;
        // Fetch night electricity rates if available
        this.nightEnergyCostPerKW = billingData.nightEnergyCostPerKW || this.energyCostPerKW; // Default to day rate if not found

        this.calculateIntrubAmount(); // Calculate intrubamount (per person) based on the data
      } else {
        this.translate.get('NOTIFICATIONS.BILLING_DATA_NOT_FOUND').subscribe((msg: string) => {
          this.notifier.notify('error', msg);
        });
      }
    }).catch(error => {
      this.translate.get('NOTIFICATIONS.FETCH_BILLING_DATA_ERROR').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
      console.error('Error fetching billing data:', error);
    });
  }

  // Fetch Internet and Trash Fee payment status
  fetchPaymentStatus() {
    const utilitiesFeesRef = ref(
      this.db,
      `/billing/${this.address}/utilitiesfees/${this.year}`
    );

    get(utilitiesFeesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const paymentData = snapshot.val();
          const listOfmonths: string[] = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december'
          ];
          // Prepare Internet Fee and Trash Fee data
          this.internetFeeData = listOfmonths.map((monthKey) =>
            paymentData[monthKey]?.internetfeepaid?.includes(this.uid)
              ? 'paid'
              : 'unpaid'
          );
          this.trashFeeData = listOfmonths.map((monthKey) =>
            paymentData[monthKey]?.trashfeepaid?.includes(this.uid)
              ? 'paid'
              : 'unpaid'
          );

          // Check if any data exists
          this.utilitiesFeesExist =
            this.internetFeeData.some(
              (status) => status === 'paid' || status === 'unpaid'
            ) ||
            this.trashFeeData.some(
              (status) => status === 'paid' || status === 'unpaid'
            );

          // Render Charts
          setTimeout(() => {
            this.renderDoughnutChart(
              'internetFeeDoughnutChart',
              this.internetFeeData,
              this.months,
              'BILL_CALCULATORS.INTERNET_FEE_TITLE',
              'assets/wifi.png',
              this.internetPaidColor
            );
            this.renderDoughnutChart(
              'trashFeeDoughnutChart',
              this.trashFeeData,
              this.months,
              'BILL_CALCULATORS.TRASH_FEE_TITLE',
              'assets/trash.png',
              this.trashPaidColor
            );
          }, 0);
        } else {
          console.warn('No payment data found for this year.');
          this.utilitiesFeesExist = false;
        }
      })
      .catch((error) => {
        console.error('Error fetching payment status:', error);
        this.utilitiesFeesExist = false;
      });
  }
  
  // Render a chart using Chart.js
  renderDoughnutChart(
    canvasId: string,
    data: string[],
    monthsKeys: string[],
    titleKey: string,
    iconPath: string,
    paidColor: string
  ) {
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
  
    if (!canvas) {
      console.error(`Canvas element with ID ${canvasId} not found.`);
      return;
    }
  
    const context = canvas.getContext('2d');
    if (!context) {
      console.error(`Cannot acquire context for canvas ID: ${canvasId}`);
      return;
    }
  
    // Set each data point to 1 to ensure all slices are equal
    const numericalData = data.map(() => 1);
  
    // Assign colors based on payment status
    const colors = data.map((status) =>
      status === 'paid' ? paidColor : this.unpaidColor
    );
  
    // Preload the icon image
    const image = new Image();
    image.src = iconPath;
  
    // Translation keys for statuses
    const statusKeys = data.map((status) =>
      status === 'paid' ? 'BILL_STATUS.PAID' : 'BILL_STATUS.UNPAID'
    );
  
    // Get all translations
    const translationKeys = [...monthsKeys, titleKey, ...statusKeys];
  
    this.translate.get(translationKeys).subscribe((translations) => {
      // Get translated months
      const translatedMonths = monthsKeys.map((key) => translations[key]);
  
      // Get translated statuses
      const translatedStatuses = statusKeys.map((key) => translations[key]);
  
      // Get translated title
      const translatedTitle = translations[titleKey];
  
      new Chart(context, {
        type: 'doughnut',
        data: {
          labels: translatedMonths,
          datasets: [
            {
              data: numericalData,
              backgroundColor: colors,
              borderWidth: 1,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          cutout: '50%',
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  const status = translatedStatuses[tooltipItem.dataIndex];
                  const label = translatedMonths[tooltipItem.dataIndex];
                  return `${label}: ${status}`;
                },
              },
            },
            datalabels: {
              color: '#fff',
              formatter: (value, ctx) => {
                const index = ctx.dataIndex;
                return translatedMonths[index].substr(0, 3); // Show abbreviated month (e.g., Jan)
              },
            },
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
        },
        plugins: [
          {
            id: 'centerIconPlugin',
            beforeDraw: (chart) => {
              if (image.complete) {
                this.drawCenterContent(chart, image, translatedTitle);
              } else {
                image.onload = () =>
                  this.drawCenterContent(chart, image, translatedTitle);
              }
            },
          },
        ],
      });
    });
  }
  
  
  private drawCenterContent(
    chart: Chart<'doughnut', number[], unknown>,
    image: HTMLImageElement,
    title: string
  ) {
    const ctx = chart.ctx;
    const { width, height } = chart.chartArea;
    const centerX = width / 2 + chart.chartArea.left;
    const centerY = height / 2 + chart.chartArea.top;

    const iconSize = 40;
    ctx.drawImage(
      image,
      centerX - iconSize / 2,
      centerY - iconSize / 2 - 10,
      iconSize,
      iconSize
    );

    ctx.fillStyle = '#333';
    ctx.font = '14px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(title, centerX, centerY + 30);
  }
  // Fetch tenants based on the logged-in user's address
  fetchTenants() {
    const tenantsRef = ref(this.db, `/Addresses/${this.address}`);

    get(tenantsRef).then(snapshot => {
      if (snapshot.exists()) {
        const tenantsData = snapshot.val();

        // Map through each tenant and extract necessary data, including profileUrl
        this.tenants = Object.keys(tenantsData)
          .filter(uid => tenantsData[uid].status === 'Active') // Only keep active tenants
          .map(uid => ({
            name: typeof tenantsData[uid].name === 'object' 
                    ? tenantsData[uid].name.fullName || tenantsData[uid].name.firstName || 'Unknown Name' 
                    : tenantsData[uid].name, // Safely extract name from the object
            days: 0, // Default values for tenants
            amount: 0,
            elecgas: 0,
            intrub: 0,
            profileUrl: tenantsData[uid].profileUrl || 'assets/user.png' // Use profileUrl or default image
          }));

        if (this.tenants.length === 0) {
          this.translate.get('NOTIFICATIONS.NO_ACTIVE_TENANTS').subscribe((msg: string) => {
            this.notifier.notify('info', msg);
          });
        }

        this.calculateIntrubAmount(); // Recalculate intrubamount after tenants are loaded
        this.fetchBillingData(); // Fetch billing data after tenants are loaded
      } else {
        this.translate.get('NOTIFICATIONS.NO_TENANTS_FOUND').subscribe((msg: string) => {
          this.notifier.notify('error', msg);
        });
      }
    }).catch(error => {
      this.translate.get('NOTIFICATIONS.FETCH_TENANTS_ERROR').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
      console.error('Error fetching tenants:', error);
    });
  }


  //Calculate intrubamount (per person) based on the number of tenants and billing data
  calculateIntrubAmount() {
    const numberOfTenants = this.tenants.length;
  
    if (numberOfTenants > 0 && this.startDate && this.endDate) {
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
  
      if (end < start) {
        this.intrubamount = 0;
        return;
      }
  
      // Calculate the total number of days between start and end date
      const totalDays = Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1;
  
      // Calculate the number of 28-day periods, ensuring at least 1 month
      const numberOfMonths = Math.max(1, Math.ceil(totalDays / 28));
      console.log(numberOfMonths)
      // Calculate intrubamount for the total period
      this.intrubamount = parseFloat(((this.internetFee + this.trashFee) / numberOfTenants).toFixed(2)) * numberOfMonths;
    } else {
      this.intrubamount = 0;
    }
  }
  
  

  // Recalculate the amounts for all tenants based on their days
  recalculateAmounts() {
    const totalDays = this.getTotalDays();
    const perDayRate = this.totalBill / totalDays;
  
    this.tenants.forEach(tenant => {
      if (tenant.days > 0) {
        tenant.elecgas = parseFloat((perDayRate * tenant.days).toFixed(2));
        //tenant.intrub = parseFloat(this.intrubamount.toFixed(2));
        tenant.amount = parseFloat((tenant.elecgas + tenant.intrub).toFixed(2));
      } else {
        tenant.elecgas = 0;
        tenant.intrub = 0;
        tenant.amount = 0;
      }
    });
  }

  // Increase days for tenant and recalculate amounts
  increaseDays(tenant: { days: number }) {
    tenant.days++;
    this.recalculateAmounts();
  }

  // Decrease days for tenant and recalculate amounts
  decreaseDays(tenant: { days: number }) {
    if (tenant.days > 0) {
      tenant.days--;
      this.recalculateAmounts();
    }
  }

  // Update bill based on tenant's days
  updateBill(tenant: { amount: number; days: number }) {
    const totalDays = this.getTotalDays();
    if (this.totalBill > 0 && totalDays > 0) {
      this.recalculateAmounts();
    } else {
      this.translate.get('BILL_CALCULATORS.INVALID_DATE_RANGE').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
    }
  }

  // Get total days lived by all tenants
  getTotalDays(): number {
    return this.tenants.reduce((total, tenant) => total + tenant.days, 0);
  }
  isCalculated: boolean = false;
  // Initialize the days and calculate the bill per day
  initializeDaysAndCalculateBill() {
    const start = new Date(this.startDate);
    const end = new Date(this.endDate);

    if (!this.startDate || !this.endDate || end < start) {
      this.translate.get('BILL_CALCULATORS.INVALID_DATE_RANGE').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
      return;
    }

    const totalDays = Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1;

    // Update days for each tenant
    this.tenants.forEach(tenant => (tenant.days = totalDays));

    this.calculateTenantBill(totalDays);
    this.isCalculated = true;
  }

  // Calculate the tenant bill
  calculateTenantBill(totalDays: number) {
    if (this.totalBill <= 0) {
      this.translate.get('BILL_CALCULATORS.INVALID_TOTAL_BILL').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
      return;
    }

    if (totalDays <= 0) {
      this.translate.get('BILL_CALCULATORS.INVALID_TOTAL_DAYS').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
      return;
    }

    this.recalculateAmounts();
  }

  // Get individual results array for display in the template
   // Get individual results array for display in the template
  getResultsArray(results: any): Array<{ label: string; value: number }> {
    const resultsArray: Array<{ label: string; value: number }> = [
      {
        label: this.translate.instant('BILL_CALCULATORS.ENERGY_COST', {
          rate: this.energyCostPerKW.toFixed(4)
        }),
        value: results.energyCost
      },
      {
        label: this.translate.instant('BILL_CALCULATORS.ELECTRIC_STANDING_CHARGE', {
          rate: this.standingChargePerDay.toFixed(4) // Replace {{rate}} with the actual value
        }),
        value: results.electricStandingChargeCost
      }
    ];

    // Include Night Electricity if applicable
    if (this.electricityType === 'dayNight') {
      resultsArray.push({
        label: this.translate.instant('BILL_CALCULATORS.ENERGY_COST_NIGHT', {
          rate: this.nightEnergyCostPerKW.toFixed(4)
        }),
        value: results.nightEnergyCost
      });
    }

    // Include Gas if applicable
    if (this.includeGas) {
      resultsArray.push({
        label: this.translate.instant('BILL_CALCULATORS.GAS_COST', {
          rate: this.gasCostPerM3.toFixed(4)
        }),
        value: results.gasCost
      });
      resultsArray.push({
        label: this.translate.instant('BILL_CALCULATORS.GAS_STANDING_CHARGE', {
          rate: this.gasStandingChargePerDay.toFixed(4)
        }),
        value: results.gasStandingChargeCost
      });
      resultsArray.push({
        label: this.translate.instant('BILL_CALCULATORS.CARBON_TAX', {
          rate: this.carbonTax.toFixed(4)
        }),
        value: results.carbonTaxCost
      });
    }
    resultsArray.push({
      label: this.translate.instant('BILL_CALCULATORS.PSO_LEVY', {
        rate: this.psoLevy.toFixed(2)
      }),
      value: results.psoLevyCost
    });
    resultsArray.push({
      label: this.translate.instant('BILL_CALCULATORS.VAT', {
        rate: (this.vatRate * 100).toFixed(2) + '%' // Convert to percentage and format
      }),
      value: results.vatAmount
    });
    return resultsArray;
  }  
  isPersonalBillCalculated: boolean = false;
  // Calculate individual bill based on inputs for energy and gas usage
  calculateIndividualBill() {
    const { currentKW, previousKW, currentNightKW, previousNightKW, currentGas, previousGas, daysLived } = this.individualBilling;

    // Validation
    if (
        currentKW < previousKW ||
        (this.electricityType === 'dayNight' && currentNightKW < previousNightKW) ||
        (this.includeGas && currentGas < previousGas) ||
        daysLived <= 0 ||
        currentKW < 0 ||
        previousKW < 0 ||
        (this.electricityType === 'dayNight' && (currentNightKW < 0 || previousNightKW < 0)) ||
        (this.includeGas && (currentGas < 0 || previousGas < 0))
    ) {
        this.translate.get('BILL_CALCULATORS.INVALID_INDIVIDUAL_INPUTS').subscribe((msg: string) => {
            this.notifier.notify('error', msg);
        });
        return;
    }

    const energyUsed = currentKW - previousKW;
    const nightEnergyUsed = this.electricityType === 'dayNight' ? (currentNightKW - previousNightKW) : 0;
    const gasUsed = this.includeGas ? (currentGas - previousGas) : 0;

    // Calculate costs
    const energyCost = energyUsed > 0 ? energyUsed * this.energyCostPerKW : 0;
    const nightEnergyCost = nightEnergyUsed > 0 ? nightEnergyUsed * this.nightEnergyCostPerKW : 0;
    const electricStandingChargeCost = energyUsed > 0 ? daysLived * this.standingChargePerDay : 0;
    const psoLevyCost = this.psoLevy; // Assuming PSO levy is constant and does not depend on usage  
    const carbonTaxCost = (gasUsed > 0 ? this.carbonTax * gasUsed : 0);
    const vatAmount = (energyCost + nightEnergyCost + electricStandingChargeCost + psoLevyCost + (this.includeGas ? (gasUsed * this.gasCostPerM3 + daysLived * this.gasStandingChargePerDay) : 0) + carbonTaxCost) * this.vatRate;
    const gasCost = this.includeGas && gasUsed > 0 ? gasUsed * this.gasCostPerM3 : 0;
    const gasStandingChargeCost = this.includeGas && gasUsed > 0 ? daysLived * this.gasStandingChargePerDay : 0;

    const totalAmount =
        energyCost +
        nightEnergyCost +
        electricStandingChargeCost +
        psoLevyCost +
        vatAmount +
        gasCost +
        gasStandingChargeCost +
        carbonTaxCost;

    // Update individualResults
    this.individualResults = {
        energyCost: parseFloat(energyCost.toFixed(2)),
        electricStandingChargeCost: parseFloat(electricStandingChargeCost.toFixed(2)),
        psoLevyCost: parseFloat(psoLevyCost.toFixed(2)),
        vatAmount: parseFloat(vatAmount.toFixed(2)),
        gasCost: parseFloat(gasCost.toFixed(2)),
        gasStandingChargeCost: parseFloat(gasStandingChargeCost.toFixed(2)),
        carbonTaxCost: parseFloat(carbonTaxCost.toFixed(2)),
        totalAmount: parseFloat(totalAmount.toFixed(2)),
        nightEnergyCost: parseFloat(nightEnergyCost.toFixed(2))
    };
    this.isPersonalBillCalculated = true;
    this.translate.get('BILL_CALCULATORS.INDIVIDUAL_BILL_CALCULATED').subscribe((msg: string) => {
        this.notifier.notify('success', msg);
    });
  }

  // Fetch the current period bill data from Firebase
  fetchCurrentPeriodBill() {
    const currentBillRef = ref(this.db, `/billing/${this.address}/currentbilling`);
    get(currentBillRef).then(snapshot => {
      if (snapshot.exists()) {
        const currentBillData = snapshot.val();
        const billingEntries = Object.keys(currentBillData).map(key => {
          console.log(currentBillData[key].billImage )
          return {
            isPerPerson: currentBillData[key].isPerPerson ? 'Yes' : 'No',
            type: currentBillData[key].type,
            amount: currentBillData[key].amount,
            period: currentBillData[key].period,
            status: currentBillData[key].status,
            billImage: currentBillData[key].billImage ?? '',
            providerIcon: currentBillData[key].providerIcon ?? '/assets/elecgasbill.png'
          };
        });
  
        // Store all the billing entries in an array
        this.currentPeriodBills = billingEntries;
      } else {
        this.currentPeriodBills = [];

      }
    }).catch(error => {
      console.error('Error fetching current period bill:', error);
      this.translate.get('NOTIFICATIONS.FETCH_CURRENT_BILL_ERROR').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
    });
  }
  
  
}
