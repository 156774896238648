import { Component, OnInit } from '@angular/core';
import { Auth, signInWithEmailAndPassword, sendEmailVerification, User, signOut } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core'; // Import TranslateService
import { AuthService } from 'src/app/services/auth-service';
import { ref, get, getDatabase } from 'firebase/database';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  email: string = '';
  password: string = '';
  authUser: User | null = null; // Stores the current authenticated user
  returnUrl: string = '/home'; // Default return URL
  authUID: string = ''; // Store the auth UID
  showEmailVerificationSection: boolean = false; // Show section if email is not verified
  emailSentMessage: string = ''; // Message shown after email verification is resent
  db = getDatabase();

  constructor(
    private auth: Auth,
    private authService: AuthService, // Inject AuthService
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    // Capture the returnUrl from query parameters
    this.route.queryParams.subscribe(params => {
      if (params['returnUrl']) {
        this.returnUrl = params['returnUrl'];
      }
    });

    // Check authentication state on app load
    this.checkAuthState();
  }

  signIn() {
    signInWithEmailAndPassword(this.auth, this.email.trim(), this.password.trim())
      .then(result => {
        this.authUser = result.user;

        if (result.user.emailVerified) {
          // Fetch user data from 'Accounts' node
          this.getUserStatusAndRole(result.user.uid)
            .then(userStatus => {
              const userRole = userStatus.role;
              const userStatusValue = userStatus.status;

              if (
                (userRole !== 'Admin' && userRole !== 'Landlord') &&
                userStatusValue === 'Inactive'
              ) {
                // For non-admin/landlord users with 'Inactive' status, sign them out
                signOut(this.auth)
                  .then(() => {
                    this.clearAuthState();

                    // Notify the user
                    this.translate
                      .get('NOTIFICATIONS.ACCOUNT_INACTIVE')
                      .subscribe((res: string) => {
                        this.notifier.notify('error', res);
                      });

                    // Redirect to auth page
                    this.router.navigate(['/auth']);
                  })
                  .catch(error => {
                    // Handle sign-out error
                    this.handleFirebaseError(error);
                  });
              } else {
                // Proceed with sign-in
                this.translate.get('NOTIFICATIONS.SIGN_IN_SUCCESS').subscribe((res: string) => {
                  this.notifier.notify('success', res);
                });
                this.refreshSession();
                this.authService.updateAuthState(result.user);
                this.router.navigate([this.returnUrl]);
              }
            })
            .catch(error => {
              // Handle error in fetching user status and role
              this.handleFirebaseError(error);
            });
        } else {
          // Show email verification section
          this.showEmailVerificationSection = true;
          this.translate.get('NOTIFICATIONS.EMAIL_NOT_VERIFIED').subscribe((res: string) => {
            this.notifier.notify('error', res);
          });
        }
      })
      .catch(error => {
        this.handleFirebaseError(error);
      });
  }

  async getUserStatusAndRole(uid: string): Promise<{ status: string; role: string }> {
    const userRef = ref(this.db, `Accounts/${uid}`);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      const userData = snapshot.val();
      return {
        status: userData.Status || 'Active', // Default to 'Active' if Status is not set
        role: userData.role || 'User' // Default to 'User' if role is not set
      };
    } else {
      // If user data doesn't exist, treat as inactive
      return { status: 'Inactive', role: 'User' };
    }
  }

  // Add this method to navigate to forgot-password page
  navigateToForgotPassword() {
    this.router.navigate(['/auth/forgot-password']); // Navigate to forgot-password route
  }

  // Resend email verification
  resendVerificationEmail() {
    const user = this.auth.currentUser;
    if (user) {
      sendEmailVerification(user)
        .then(() => {
          this.translate.get('NOTIFICATIONS.VERIFICATION_EMAIL_SENT').subscribe((res: string) => {
            this.notifier.notify('success', res);
          });
          this.emailSentMessage = 'Verification email sent. Please check your inbox.';
        })
        .catch((error) => {
          this.handleFirebaseError(error);
        });
    }
  }

  // Check if user is authenticated based on localStorage auth state
  checkAuthState() {
    this.auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        // If user is authenticated and email is verified
        this.authUser = user;
        this.authUID = user.uid;
        this.refreshSession(); // Refresh the session timestamp and save auth state in localStorage
        this.router.navigate([this.returnUrl]); // Navigate to the return URL
      } else {
        // If user is not authenticated or email is not verified
        this.clearAuthState(); // Clear stored credentials
        this.authUser = null;
      }
    });
  }
  

  // Refresh authentication session in localStorage
  refreshSession() {
    const authState = { user: this.authUser, lastActivity: new Date().getTime() };
    localStorage.setItem('authState', JSON.stringify(authState));
    this.authUID = this.authUser?.uid || '';
  }

  // Clear authentication state
  clearAuthState() {
    localStorage.removeItem('authState');
    this.authUser = null;
    this.authUID = '';
  }

  // Simulated method to check if the user exists (you can implement this based on your database structure)
  async checkIfUserExists(uid: string): Promise<boolean> {
    // Implement your user check logic here
    return true; // For now, assuming the user exists
  }

  navigateToInvitation() {
    this.router.navigate(['/auth/invitation']); // Adjust the route based on your app routing
  }

  // Handle Firebase exceptions
  // Handle Firebase exceptions
handleFirebaseError(error: any) {
  console.log(error)
  let errorMessage = 'An unknown error occurred. Please try again later.';

  switch (error.code) {
    case 'auth/app-deleted':
      errorMessage = this.translate.instant('AUTH.APP_DELETED');
      break;
    case 'auth/app-not-authorized':
      errorMessage = this.translate.instant('AUTH.APP_NOT_AUTHORIZED');
      break;
    case 'auth/argument-error':
      errorMessage = this.translate.instant('AUTH.ARGUMENT_ERROR');
      break;
    case 'auth/invalid-api-key':
      errorMessage = this.translate.instant('AUTH.INVALID_API_KEY');
      break;
    case 'auth/invalid-user-token':
      errorMessage = this.translate.instant('AUTH.INVALID_USER_TOKEN');
      break;
    case 'auth/network-request-failed':
      errorMessage = this.translate.instant('AUTH.NETWORK_ERROR');
      break;
    case 'auth/operation-not-allowed':
      errorMessage = this.translate.instant('AUTH.OPERATION_NOT_ALLOWED');
      break;
    case 'auth/requires-recent-login':
      errorMessage = this.translate.instant('AUTH.REQUIRES_RECENT_LOGIN');
      break;
    case 'auth/too-many-requests':
      errorMessage = this.translate.instant('AUTH.TOO_MANY_REQUESTS');
      break;
    case 'auth/unauthorized-domain':
      errorMessage = this.translate.instant('AUTH.UNAUTHORIZED_DOMAIN');
      break;
    case 'auth/user-disabled':
      errorMessage = this.translate.instant('AUTH.USER_DISABLED');
      break;
    case 'auth/user-token-expired':
      errorMessage = this.translate.instant('AUTH.USER_TOKEN_EXPIRED');
      break;
    case 'auth/web-storage-unsupported':
      errorMessage = this.translate.instant('AUTH.WEB_STORAGE_UNSUPPORTED');
      break;
    case 'auth/email-already-in-use':
      errorMessage = this.translate.instant('AUTH.EMAIL_ALREADY_IN_USE');
      break;
    case 'auth/expired-action-code':
      errorMessage = this.translate.instant('AUTH.EXPIRED_ACTION_CODE');
      break;
    case 'auth/invalid-action-code':
      errorMessage = this.translate.instant('AUTH.INVALID_ACTION_CODE');
      break;
    case 'auth/invalid-email':
      errorMessage = this.translate.instant('AUTH.INVALID_EMAIL');
      break;
    case 'auth/user-not-found':
      errorMessage = this.translate.instant('AUTH.USER_NOT_FOUND');
      break;
    case 'auth/wrong-password':
      errorMessage = this.translate.instant('AUTH.WRONG_PASSWORD');
      break;
    case 'auth/invalid-verification-code':
      errorMessage = this.translate.instant('AUTH.INVALID_VERIFICATION_CODE');
      break;
    case 'auth/invalid-verification-id':
      errorMessage = this.translate.instant('AUTH.INVALID_VERIFICATION_ID');
      break;
    case 'auth/account-exists-with-different-credential':
      errorMessage = this.translate.instant('AUTH.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL');
      break;
    case 'auth/credential-already-in-use':
      errorMessage = this.translate.instant('AUTH.CREDENTIAL_ALREADY_IN_USE');
      break;
    case 'auth/invalid-credential':
      errorMessage = this.translate.instant('AUTH.INVALID_CREDENTIAL');
      break;
    case 'auth/invalid-phone-number':
      errorMessage = this.translate.instant('AUTH.INVALID_PHONE_NUMBER');
      break;
    case 'auth/missing-phone-number':
      errorMessage = this.translate.instant('AUTH.MISSING_PHONE_NUMBER');
      break;
    case 'auth/quota-exceeded':
      errorMessage = this.translate.instant('AUTH.QUOTA_EXCEEDED');
      break;
    case 'auth/invalid-password':
      errorMessage = this.translate.instant('AUTH.INVALID_PASSWORD');
      break;
    case 'auth/missing-password':
      errorMessage = this.translate.instant('AUTH.MISSING_PASSWORD');
      break;
    case 'auth/popup-closed-by-user':
      errorMessage = this.translate.instant('AUTH.POPUP_CLOSED_BY_USER');
      break;
    case 'auth/popup-blocked':
      errorMessage = this.translate.instant('AUTH.POPUP_BLOCKED');
      break;
    case 'auth/timeout':
      errorMessage = this.translate.instant('AUTH.TIMEOUT');
      break;
    case 'auth/unverified-email':
      errorMessage = this.translate.instant('AUTH.UNVERIFIED_EMAIL');
      break;
    case 'auth/internal-error':
      errorMessage = this.translate.instant('AUTH.INTERNAL_ERROR');
      break;
    case 'auth/null-user':
      errorMessage = this.translate.instant('AUTH.NULL_USER');
      break;
    case 'auth/missing-android-pkg-name':
      errorMessage = this.translate.instant('AUTH.MISSING_ANDROID_PKG_NAME');
      break;
    case 'auth/missing-continue-uri':
      errorMessage = this.translate.instant('AUTH.MISSING_CONTINUE_URI');
      break;
    case 'auth/missing-ios-bundle-id':
      errorMessage = this.translate.instant('AUTH.MISSING_IOS_BUNDLE_ID');
      break;
    case 'auth/invalid-continue-uri':
      errorMessage = this.translate.instant('AUTH.INVALID_CONTINUE_URI');
      break;
    case 'auth/unauthorized-continue-uri':
      errorMessage = this.translate.instant('AUTH.UNAUTHORIZED_CONTINUE_URI');
      break;
    case 'auth/user-mismatch':
      errorMessage = this.translate.instant('AUTH.USER_MISMATCH');
      break;
    case 'auth/invalid-recipient-email':
      errorMessage = this.translate.instant('AUTH.INVALID_RECIPIENT_EMAIL');
      break;
    case 'auth/missing-email':
      errorMessage = this.translate.instant('AUTH.MISSING_EMAIL');
      break;
    case 'auth/captcha-check-failed':
      errorMessage = this.translate.instant('AUTH.CAPTCHA_CHECK_FAILED');
      break;
    case 'auth/invalid-app-credential':
      errorMessage = this.translate.instant('AUTH.INVALID_APP_CREDENTIAL');
      break;
    case 'auth/invalid-app-id':
      errorMessage = this.translate.instant('AUTH.INVALID_APP_ID');
      break;
    case 'auth/invalid-oauth-provider':
      errorMessage = this.translate.instant('AUTH.INVALID_OAUTH_PROVIDER');
      break;
    case 'auth/missing-oauth-client-secret':
      errorMessage = this.translate.instant('AUTH.MISSING_OAUTH_CLIENT_SECRET');
      break;
    case 'auth/unauthorized-oauth-client':
      errorMessage = this.translate.instant('AUTH.UNAUTHORIZED_OAUTH_CLIENT');
      break;
    default:
      errorMessage = this.translate.instant('AUTH.UNKNOWN_ERROR');
      break;
  }

  this.notifier.notify('error', errorMessage);
}

}
