<div class="container-fluid d-flex flex-column flex-lg-row">
  
  <!-- Full Calendar View -->
  <div class="calendar-container flex-grow-1 mb-3 mb-lg-0">
    <full-calendar [options]="calendarOptions"></full-calendar>
  </div>

  <!-- Task Schedules Container -->
  <div class="task-schedules-container flex-grow-1 ms-lg-3 d-flex flex-column">
    
    <!-- Own Task Schedule List -->
    <div class="schedule-container">
      <h1 class="animate__animated animate__fadeIn">
        <img [src]="'assets/notes.png'" alt="notes icon" class="task-icon me-3" />
        {{ 'TASK.MY_UPCOMING_EVENTS' | translate }}
      </h1>
      <hr>
      <!-- Scrollable Task List -->
      <div class="task-list">
        <div *ngFor="let task of ownTasks" class="task-item mb-3 animate__animated animate__fadeInUp">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <!-- Task Icon -->
              <img 
                  [src]="'assets/' + task.type + '.png'" 
                  alt="{{ task.type }} icon" 
                  class="task-icon me-3"
                  (error)="onImageError($event)"
                />
              <div>
                <p class="task-title">{{ task.type | translate }}</p>
                <p class="task-date">{{ task.date | date: 'mediumDate' }}</p>
              </div>
            </div>
            <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center">
              <!-- Reminder Toggle -->
              <div class="d-flex align-items-center mb-2 mb-md-0 me-md-3">
                <label class="form-check-label me-2" for="reminderToggle{{task.id}}">
                  {{ 'TASK.REMINDER' | translate }}
                </label>
                <div class="form-check form-switch">
                  <input 
                    class="form-check-input" 
                    type="checkbox" 
                    id="reminderToggle{{task.id}}" 
                    [checked]="task.reminder" 
                    (change)="toggleReminder(task, $event)"
                  />
                </div>
              </div>
              <!-- Delete Button -->
              <button (click)="deleteTask(task)" class="btn btn-danger delete-btn">{{ 'TASK.DELETE' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- All Task Schedule List -->
    <div class="schedule-container">
      <h1 class="animate__animated animate__fadeIn">
        <img [src]="'assets/task.png'" alt="all tasks icon" class="task-icon me-3" />
        {{ 'TASK.ALL_UPCOMING_EVENTS' | translate }}
      </h1>
      <hr>
      <!-- Scrollable Task List -->
      <div class="task-list">
        <div *ngFor="let task of allTasks" class="task-item mb-3 animate__animated animate__fadeInUp">
          <div class="d-flex flex-column">

            <div class="d-flex align-items-center mb-2">
              <!-- Profile Icon -->
              <img 
                [src]="task.profileUrl || 'assets/user.png'" 
                alt="{{ task.userName }}'s profile image" 
                class="profile-icon me-3"
                (error)="onImageError($event)"
              />

              <div>
                <p class="task-user mb-0">{{ task.userName }}</p>
              </div>
            </div>
            <!-- Separator Bar -->
            <hr class="task-separator mb-2">
            <!-- Task Information -->
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <!-- Task Icon -->
                <img 
                  [src]="'assets/' + task.type + '.png'" 
                  alt="{{ task.type }} icon" 
                  class="task-icon me-3"
                  (error)="onImageError($event)"
                />
                <div>
                  <p class="task-title">{{ task.type | translate }}</p>
                  <p class="task-date">{{ task.date | date: 'mediumDate' }}</p>
                </div>
              </div>
              <!-- Action Buttons for Admin -->
              <div *ngIf="isAdmin" class="d-flex">
                <button (click)="editTask(task)" class="btn btn-primary btn-sm me-2">{{ 'TASK.EDIT' | translate }}</button>
                <button (click)="deleteTask(task)" class="btn btn-danger btn-sm">{{ 'TASK.DELETE' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<!-- Custom Modal for Adding/Editing Tasks -->
<div class="custom-modal" *ngIf="showModal">
  <div class="modal-content">
    <div class="modal-header">
      <h2>{{ isEditing ? ('TASK.EDIT_TASK' | translate) : ('TASK.ADD_NEW' | translate) }}</h2>
      <button class="close-button" (click)="closeModal()">&times;</button>
    </div>
    <div class="modal-body">
      <form>
        <!-- If Admin, show user selection -->
        <div class="form-group" *ngIf="isAdmin">
          <label for="assignUser">{{ 'TASK.ASSIGN_TO_USER' | translate }}</label>
          <select [(ngModel)]="selectedUserId" name="assignUser" id="assignUser" class="form-control" required>
            <option value="" disabled selected>{{ 'TASK.SELECT_USER' | translate }}</option>
            <option *ngFor="let user of activeUsers" [value]="user.userId">{{ user.userName }}</option>
          </select>
          <div *ngIf="!selectedUserId" class="error-message">
            {{ 'TASK.SELECT_USER_ERROR' | translate }}
          </div>
        </div>

        <div class="form-group">
          <label for="taskType">{{ 'TASK.TASK_TYPE' | translate }}</label>
          <select [(ngModel)]="newTask.type" (change)="onTaskTypeChange($event)" id="taskType" name="taskType" class="form-control" required>
            <option value="" disabled selected>{{ 'TASK.SELECT_TASK_TYPE' | translate }}</option>
            <option *ngFor="let type of taskTypes" [value]="type.type">{{ type.label | translate }}</option>
            <option value="other">{{ 'TASK.OTHER' | translate }}</option>
          </select>
          <div *ngIf="!newTask.type" class="error-message">
            {{ 'TASK.SELECT_TASK_TYPE_ERROR' | translate }}
          </div>
        </div>

        <!-- Show custom task type input if "Other" is selected -->
        <div class="form-group" *ngIf="newTask.type === 'other'">
          <label for="customTaskType">{{ 'TASK.ENTER_CUSTOM_TYPE' | translate }}</label>
          <input [(ngModel)]="customTaskType" id="customTaskType" name="customTaskType" class="form-control" required />
          <div *ngIf="!customTaskType && newTask.type === 'other'" class="error-message">
            {{ 'TASK.ENTER_CUSTOM_TYPE_ERROR' | translate }}
          </div>
        </div>

        <div class="form-group">
          <label for="taskDate">{{ 'TASK.TASK_DATE' | translate }}</label>
          <input type="date" [(ngModel)]="selectedDate" id="taskDate" name="taskDate" class="form-control" required />
          <div *ngIf="!selectedDate" class="error-message">
            {{ 'TASK.SELECT_DATE_ERROR' | translate }}
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary" (click)="closeModal()">{{ 'TASK.CANCEL' | translate }}</button>
      <button class="btn btn-primary" (click)="saveTask()">
        {{ isEditing ? ('TASK.UPDATE_TASK' | translate) : ('TASK.ADD_TASK' | translate) }}
      </button>
    </div>
  </div>
</div>  
