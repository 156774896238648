<!-- src/app/auth/invitation/invitation.component.html -->
<div class="invitation-container">
  <div class="invitation-card">
    <div class="invitation-header">
      <img src="assets/letter.png" alt="{{ 'INVITATION.ENTER_INVITATION_CODE' | translate }}" class="invitation-icon" />
      <h2>{{ 'INVITATION.ENTER_INVITATION_CODE' | translate }}</h2>
    </div>
    <div class="invitation-form">
      <input
        type="text"
        [(ngModel)]="invitationCode"
        [placeholder]="'INVITATION.PLACEHOLDER_ENTER_CODE' | translate"
        class="invitation-input"
      />
      <button (click)="onValidateInvitation()" class="invitation-button">
        {{ 'INVITATION.VALIDATE_BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
