// src/app/toolbar/toolbar.component.ts
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Auth, signOut, User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AdminGuard } from '../admin.guard';
import { Database, ref, get } from '@angular/fire/database';
import { Subscription, forkJoin, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LandlordGuard } from '../landlord.guard';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  isAdmin: boolean = false;
  isLandlord: boolean = false; // Property for landlord role
  availableLanguages = [
    { code: 'en', label: 'English' },
    { code: 'zh', label: '中文' }
  ];
  selectedLanguage: string = 'zh'; // Default language
  authUser: User | null = null; // Stores the current authenticated user
  authUID: string = ''; // Store the auth UID
  profileImageUrl: string = 'assets/user.png'; // Default profile image
  private subscriptions: Subscription = new Subscription(); // To manage subscriptions

  constructor(
    private auth: Auth,
    private adminGuard: AdminGuard,
    private landlordGuard : LandlordGuard,
    private router: Router,
    private translate: TranslateService,
    private db: Database // Inject Firebase database
  ) {}

  ngOnInit(): void {
    // Listen to authentication state changes
    const authSub = this.auth.onAuthStateChanged((user) => {

      if (user && user.emailVerified) {
        this.authUser = user;
        this.authUID = user.uid;

        // Check both admin and landlord roles in parallel
        const roles$ = forkJoin({
          isAdmin: this.adminGuard.isAdmin(this.authUID),
          isLandlord: this.landlordGuard.isLandlord(this.authUID)
        });

        const rolesSub = roles$.subscribe({
          next: ({ isAdmin, isLandlord }) => {
            this.isAdmin = isAdmin;
            this.isLandlord = isLandlord;

          },
          error: (error) => {
            console.error('Error fetching roles:', error);
            this.isAdmin = false;
            this.isLandlord = false;
          }
        });

        // Add subscription to the subscriptions collection
        this.subscriptions.add(rolesSub);

        // Load profile image URL from localStorage, or fetch it if not available
        this.loadCachedProfileImage();
      } else {
        // Reset roles and user information if not authenticated
        this.isAdmin = false;
        this.isLandlord = false;
        this.authUser = null;
        this.authUID = '';
        this.profileImageUrl = 'assets/user.png'; // Reset to default if not logged in
      }
    });

    // Note: onAuthStateChanged does not return a Subscription, so no need to add to subscriptions

    // Load the selected language from localStorage
    this.loadLanguagePreference();
  }

  ngOnDestroy(): void {
    // Clean up all subscriptions to prevent memory leaks
    this.subscriptions.unsubscribe();
  }

  /**
   * Loads the cached profile image or fetches it from the database.
   */
  loadCachedProfileImage(): void {
    const cachedImage = this.getFromLocalStorage('profileImageUrl');
    if (cachedImage) {
      this.profileImageUrl = cachedImage;
    } else {
      this.fetchUserProfileImage();
    }
  }

  /**
   * Fetches the user's profile image from Firebase Realtime Database.
   */
  fetchUserProfileImage(): void {
    if (!this.authUID) return;

    const userRef = ref(this.db, `Accounts/${this.authUID}`);
    from(get(userRef)).subscribe({
      next: (snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          if (userData.profileUrl) {
            this.profileImageUrl = userData.profileUrl; // Use profile image if exists
            this.storeToLocalStorage('profileImageUrl', userData.profileUrl); // Cache the profile URL

          }
        }
      },
      error: (error) => {
        console.error('Error fetching profile image:', error);
        this.profileImageUrl = 'assets/user.png'; // Fallback to default image if error
      }
    });
  }

  /**
   * Stores a key-value pair in localStorage.
   *
   * @param key The key under which the value is stored.
   * @param value The value to store.
   */
  storeToLocalStorage(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);

    } catch (error) {
      console.error(`Failed to store ${key} in localStorage`, error);
    }
  }

  /**
   * Retrieves a value from localStorage by key.
   *
   * @param key The key of the item to retrieve.
   * @returns The retrieved value or null if not found.
   */
  getFromLocalStorage(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      console.error(`Error retrieving ${key} from localStorage:`, error);
      return null;
    }
  }

  /**
   * Loads the user's language preference from localStorage.
   */
  loadLanguagePreference(): void {
    const savedLanguage = this.getFromLocalStorage('language');
    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
      this.translate.use(this.selectedLanguage);

    } else {
      // Optionally, set default language if not found
      this.translate.use(this.selectedLanguage);

    }
  }

  /**
   * Switches the application's language.
   *
   * @param lang The language code to switch to.
   */
  switchLanguage(lang: string): void {
    this.translate.use(lang);
    this.selectedLanguage = lang;
    this.storeToLocalStorage('language', lang); // Save language in localStorage

  }

  /**
   * Navigates the user to the home page or sign-in page based on authentication.
   */
  navigateHome(): void {
    if (this.auth.currentUser) {
      this.router.navigate(['/home']);

    } else {
      this.router.navigate(['/auth/sign-in']);

    }
  }

  /**
   * Navigates the user to their profile page.
   */
  navigateToProfile(): void {
    this.router.navigate(['/profile']);

  }

  /**
   * Navigates the user to the admin dashboard.
   */
  navigateToAdmin(): void {
    this.router.navigate(['/admin']);

  }

  /**
   * Navigates the user to the landlord dashboard.
   */
  navigateToLandlord(): void {
    this.router.navigate(['/landlord']);

  }

  /**
   * Signs out the authenticated user.
   */
  async signOut(): Promise<void> {
    try {
      await signOut(this.auth);
      this.clearAuthState(); // Clear stored credentials
      this.router.navigate(['/auth/sign-in']);

    } catch (err: any) {
      console.error('Error during sign out:', err);
      alert(err.message);
    }
  }

  /**
   * Clears the authentication state and related data from localStorage and component properties.
   */
  clearAuthState(): void {
    try {
      localStorage.removeItem('authState');
      localStorage.removeItem('profileImageUrl'); // Clear profile image URL
      localStorage.removeItem('language'); // Optionally clear language preference
      this.authUser = null;
      this.authUID = '';
      this.isAdmin = false;
      this.isLandlord = false;
      this.profileImageUrl = 'assets/user.png'; // Reset to default

    } catch (error) {
      console.error('Error clearing auth state from localStorage:', error);
    }
  }
}
