<!-- src/app/app.component.html -->
<div *ngIf="showCookie" id="dismiss-toast" class="hs-removing:translate-x-5 hs-removing:opacity-0 opacity-100 transition-all duration-300 fixed bottom-0 right-0 z-[60] sm:max-w-xl w-full mx-auto p-6">
  <div class="p-4 bg-white border border-gray-200 rounded-xl dark:bg-gray-800 dark:border-gray-700 shadow-lg">
      <div class="flex gap-x-5">
          <img src="assets/cookie.png" class="hidden sm:block flex-shrink-0 w-20 h-20" alt="cookies">

          <div class="grow">
              <h2 class="text-lg font-semibold text-gray-800 dark:text-white">
                  We are using cookies to improve your experience!
              </h2>
              <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  By clicking "Allow All", you agree to use of all cookies. Visit our <a
                      class="inline-flex items-center gap-x-1.5 text-sky-600 decoration-2 hover:underline font-medium"
                      href="/policy">Cookies Policy</a> to learn more.
              </p>
              <div class="mt-5 inline-flex gap-x-2">
                  <button type="button"  data-hs-remove-element="#dismiss-toast" (click)="acceptCookies()"
                      class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-sky-500 text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">
                      Allow All
                  </button>
                  <button type="button" (click)="necessaryCookies()"
                      class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-sky-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                      Allow Necessary Cookies
                  </button>
              </div>
          </div>
      </div>
  </div>
</div>
<div class="app-container">
  <app-toolbar></app-toolbar>
  <div *ngIf="isLoading$ | async" class="loading-overlay">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  
  <router-outlet *ngIf="!(isLoading$ | async)"></router-outlet>
  <notifier-container></notifier-container>
</div>
<footer>
  <nav>
    <ul>
      <li><a routerLink="/terms-and-conditions">{{ 'FOOTER.TERMS_LINK' | translate }}</a></li>
      <!-- Add other footer links here -->
    </ul>
  </nav>
</footer>