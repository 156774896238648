<!-- src/app/notifications/notifications.component.html -->
<div class="notifications-container">
  <div class="notifications-header">
    <!-- Optional: Add a header icon if desired -->
    <!-- <i class="fas fa-bell notifications-header-icon"></i> -->
    <h2>{{ 'NOTIFICATIONS.TITLE' | translate }}</h2>
  </div>

  <!-- Check if there are any notifications (top or regular) -->
  <ng-container *ngIf="topNotifications.length > 0 || regularNotifications.length > 0; else noNotifications">

    <!-- Top Notifications Section -->
    <div *ngIf="topNotifications.length > 0" class="top-notifications-section">
      <h3 class="section-title">{{ 'NOTIFICATIONS.TOP_NOTIFICATIONS' | translate }}</h3>
      <div class="notifications-grid">
        <div class="notification-card" *ngFor="let notification of topNotifications">
          <!-- Icon based on notification type -->
          <div class="notification-icon">
            <img [src]="getIconPath(notification.type)" [alt]="notification.type | titlecase" />
            <!-- If using Font Awesome or another icon library, uncomment the following and comment out the img tag above -->
            <!-- <i [ngClass]="getIconClass(notification.type)"></i> -->
          </div>

          <!-- Notification Content -->
          <div class="notification-content">
            <h3>{{ notification.title | translate }}</h3>
            <p>{{ notification.content | translate }}</p>
            <span class="notification-date">
              {{ notification.timestamp | date: 'yyyy-MM-dd HH:mm:ss' }}
            </span>
          </div>

          <!-- Author Information -->
          <div class="notification-author">
            <img 
              [src]="notification.author.icon" 
              [alt]="notification.author.name" 
              class="author-icon" 
              (error)="onImageError($event)" 
            />
            <span class="author-name">{{ notification.author.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Separator Bar -->
    <div *ngIf="topNotifications.length > 0 && regularNotifications.length > 0" class="separator-bar"></div>

    <!-- Regular Notifications Section -->
    <div *ngIf="regularNotifications.length > 0" class="regular-notifications-section">
      <h3 class="section-title">{{ 'NOTIFICATIONS.OTHER_NOTIFICATIONS' | translate }}</h3>
      <div class="notifications-grid">
        <div class="notification-card" *ngFor="let notification of regularNotifications">
          <!-- Icon based on notification type -->
          <div class="notification-icon">
            <img [src]="getIconPath(notification.type)" [alt]="notification.type | titlecase" />
            <!-- If using Font Awesome or another icon library, uncomment the following and comment out the img tag above -->
            <!-- <i [ngClass]="getIconClass(notification.type)"></i> -->
          </div>

          <!-- Notification Content -->
          <div class="notification-content">
            <h3>{{ notification.title | translate }}</h3>
            <p>{{ notification.content | translate }}</p>
            <span class="notification-date">
              {{ notification.timestamp | date: 'yyyy-MM-dd HH:mm:ss' }}
            </span>
          </div>

          <!-- Author Information -->
          <div class="notification-author">
            <img 
              [src]="notification.author.icon" 
              [alt]="notification.author.name" 
              class="author-icon" 
              (error)="onImageError($event)" 
            />
            <span class="author-name">{{ notification.author.name }}</span>
          </div>
        </div>
      </div>
    </div>

  </ng-container>

  <!-- Template to display when there are no notifications -->
  <ng-template #noNotifications>
    <p class="no-notifications">{{ 'NOTIFICATIONS.NO_NOTIFICATIONS' | translate }}</p>
  </ng-template>
</div>
