import { Component, OnInit } from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Database, ref, get, update } from '@angular/fire/database';
import { Storage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject, getStorage, getMetadata } from '@angular/fire/storage'; // Import getMetadata
import imageCompression from 'browser-image-compression';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

interface UserProfileData {
  depositAmount?: number;
  contractStartDate?: string;
  contractExpireDate?: string;
  contractLink?: string;
  nextPaymentDate?: string;
  nextPaymentAmount?: number;
  Location?: string;
  Status?: string;
  profileUrl?: string; // Profile Image URL
  role?: string; // 新增的角色字段
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user: User | null = null;
  userProfileData: UserProfileData | null = null;
  isLoading: boolean = true;
  isUploading: boolean = false; // Add isUploading flag
  selectedFile: File | null = null;
  allowedImageTypes = [
    'image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp',
    'image/tiff', 'image/x-icon', 'image/heic', 'image/heif', 'image/svg+xml'
  ];

  storage = getStorage();

  constructor(
    private auth: Auth,
    private router: Router,
    private db: Database,
    private notifier: NotifierService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    authState(this.auth).subscribe(user => {
      if (user) {
        this.user = user;
        this.fetchUserProfileData();
      } else {
        this.user = null;
        this.translate.get('PROFILE.NOT_AUTHENTICATED').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
        this.router.navigate(['/login']);
      }
    });
  }

  // Handle file selection
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
  
      // Check if the file is an allowed image type
      if (!this.allowedImageTypes.includes(file.type)) {
        this.translate.get('PROFILE.INVALID_FILE_TYPE').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
        return;
      }
  
      this.compressAndUploadImage(file); // Compress and upload the image
    } else {
      this.selectedFile = null;
    }
  }

  // Compress the image and upload it to Firebase
  async compressAndUploadImage(file: File): Promise<void> {
    if (!this.user) return;
  
    this.isUploading = true; // Set the isUploading flag to true
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
  
    try {
      const compressedFile = await imageCompression(file, options);
  
      const filePath = `profileIcons/${this.user.uid}/${this.user.displayName}_profile.png`;
      const storageReference = storageRef(this.storage, filePath);
  
      if (this.userProfileData?.profileUrl) {
        try {
          const oldImageRef = storageRef(this.storage, this.userProfileData.profileUrl);
          await getMetadata(oldImageRef);
          await deleteObject(oldImageRef);
        } catch (error) {

        }
      }
  
      // Upload the compressed image to Firebase Storage
      await uploadBytes(storageReference, compressedFile);
  
      // Get the download URL for the uploaded image
      const downloadURL = await getDownloadURL(storageReference);
  
      await update(ref(this.db, `Accounts/${this.user.uid}`), { profileUrl: downloadURL });
      await update(ref(this.db, `Addresses/${this.userProfileData?.Location}/${this.user.uid}`), { profileUrl: downloadURL });
  
      this.userProfileData = { ...this.userProfileData, profileUrl: downloadURL };
      localStorage.setItem('profileImageUrl', downloadURL);
  
      this.translate.get('PROFILE.IMAGE_UPLOAD_SUCCESS').subscribe((res: string) => {
        this.notifier.notify('success', res);
      });

      // 如果角色信息有变更，可以在此处处理
      // 例如，如果角色信息需要重新加载，可以调用 fetchUserProfileData()

      window.location.reload();
    } catch (error) {
      console.error('Error uploading profile image:', error);
      this.translate.get('PROFILE.IMAGE_UPLOAD_ERROR').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
    } finally {
      this.isUploading = false; // Set the isUploading flag back to false when upload is finished
    }
  }
  
  // Fetch user profile data from Firebase Realtime Database
  async fetchUserProfileData(): Promise<void> {
    if (!this.user) {
      this.translate.get('PROFILE.NOT_AUTHENTICATED').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      this.router.navigate(['/login']);
      return;
    }

    const userRef = ref(this.db, `Accounts/${this.user.uid}`);
    try {
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const data = snapshot.val() as UserProfileData;
        this.userProfileData = data;
      } else {
        this.userProfileData = null;
        this.translate.get('PROFILE.NO_DATA_AVAILABLE').subscribe((res: string) => {
          this.notifier.notify('info', res);
        });
      }
    } catch (error) {
      console.error('Error fetching user profile data:', error);
      this.translate.get('PROFILE.ERROR_FETCHING_DATA').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
    } finally {
      this.isLoading = false;
    }
  }
  getTranslatedRole(role: string | undefined): string {
    if (!role) {
      return 'ADMIN.ROLES.NORMAL_USER';
    }

    switch (role) {
      case 'Head of Tenant':
        return 'ADMIN.ROLES.HEAD_OF_TENANT';
      case 'Financial Management Admin':
        return 'ADMIN.ROLES.FINANCIAL_MANAGEMENT_ADMIN';
      case 'Task Management Admin':
        return 'ADMIN.ROLES.TASK_MANAGEMENT_ADMIN';
      case 'Normal User':
      default:
        return 'ADMIN.ROLES.NORMAL_USER';
    }
  }
}
