// src/app/profile-item/profile-item.component.ts

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-profile-item',
  templateUrl: './profile-item.component.html',
  styleUrls: ['./profile-item.component.css']
})
export class ProfileItemComponent {
  @Input() iconClass!: string;
  @Input() label!: string;
  @Input() data!: string | number | null | undefined;
  @Input() isLink: boolean = false;

  isString(value: any): value is string {
    return typeof value === 'string';
  }

  isNumber(value: any): value is number {
    return typeof value === 'number';
  }
}
