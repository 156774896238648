// src/app/admin/admin.component.ts

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Database, ref, push, update, get, remove, set, equalTo, orderByChild, query } from '@angular/fire/database';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Auth, authState } from '@angular/fire/auth';
import { User } from 'firebase/auth';

// Interface Definitions
interface UserData {
  Name: string;
  Email: string;
  Location: string;
  Status: string;
  UID: string;
  reports?: string[];
  depositAmount?: number;
  contractStartDate?: string; // ISO date string
  contractExpireDate?: string; // ISO date string
  contractLink?: string;
  nextPaymentDate?: string; // ISO date string
  nextPaymentAmount?: number;
  role?: string; // User role
}

interface BillingData {
  carbonTax: number;
  energyCostPerKW: number;
  gasCostPerM3: number;
  gasStandingChargePerDay: number;
  internet_fee: number;
  psoLevy: number;
  standingChargePerDay: number;
  trash_fee: number;
  vatRate: number;
}

interface LandlordData {
  addresses: { [address: string]: boolean }; // Using object for address existence checks
  // Add other landlord-specific fields if necessary
}

interface LandlordPermissions {
  [landlordUID: string]: LandlordData;
}

interface UserList {
  uid: string;
  name: string;
  email: string;
  role: string;
}
interface ProviderIcon {
  label: string; // Display name
  value: string; // URL or identifier
  // Optional: Add an icon URL if you want to display images in the dropdown
  iconUrl?: string;
}
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  // Form controls
  reportForm: FormGroup;
  assignLandlordForm: FormGroup;
  assignAddressForm: FormGroup;
  assignUserAsLandlordForm: FormGroup; // New FormGroup for assigning users as landlords

  // User information
  currentUser: User | null = null;

  // State variables
  isSubmitting: boolean = false;
  generatedCode: string | null = null;
  addressesWithUsers: string[] = []; // List of addresses with users
  usersByAddress: { [key: string]: UserData[] } = {}; // Group users by address
  filteredUsers: UserData[] = []; // Store filtered users based on selectedAddress

  // Form fields
  selectedAddress: string = '';
  email: string = '';

  // Dynamically fetched addresses
  addresses: string[] = [];

  // Modal state variables
  isModalOpen: boolean = false;
  selectedUser: UserData | null = null;
  userDataForm: FormGroup;
  currentBillingData: any = null;
  previousBillingData: any[] = [];
  // Form for editing current billing
  billingForm: FormGroup;
  newBillingForm: FormGroup;

  isBillingModalOpen: boolean = false;
  isAddBillingModalOpen: boolean = false;
  currentBillingDataList: any[] = [];
  billingData: BillingData | null = null;
  billingDataForm: FormGroup;
  isBillingDataModalOpen: boolean = false;
  roles: string[] = [
    'Head of Tenant',
    'Financial Management Admin',
    'Task Management Admin',
    'Normal User'
  ];

  // Role Assignment
  landlordRoles: string[] = ['Landlord']; // Additional roles can be added here

  // List of users who can be assigned as landlords
  potentialLandlords: UserList[] = [];

  // List of current landlords
  landlordsList: UserList[] = [];

  // Role of the current user
  userRole: 'admin' | 'landlord' | null = null;

  // Selected landlord's addresses
  selectedLandlordAddresses: string[] = []; // New Variable


  providerIcons: ProviderIcon[] = [
    { label: 'Bord Gáis Energy', value: 'https://www.bordgaisenergy.ie/', iconUrl: 'https://www.bordgaisenergy.ie/static/images/bge.svg' },
    { label: 'Electric Ireland', value: 'https://www.electricireland.ie/', iconUrl: 'https://www.electricireland.ie/' },
    { label: 'SSE Airtricity', value: 'https://www.sseairtricity.com/ie/home', iconUrl: 'https://images.ctfassets.net/dchiez7z7khl/1aZkTdDNOwOQvivdUO9OIG/2a9dd2aeab08cc7019aacb074c92235d/sseairtricity-brand-logo.svg' },
    { label: 'Energia', value: 'https://www.energia.ie/home', iconUrl: 'https://www.energia.ie/getmedia/24b8d67c-1679-4c0a-bd74-04272af2897f/Image-of-Meenadreen-windfarm.jpg?width=1920&height=1079&ext=.jpg' },
    { label: 'Flogas', value: 'https://www.flogas.ie/', iconUrl: 'https://www.flogas.ie/assets/components/mor/General/img/flogas-logo.svg' },
    { label: 'Pinergy', value: 'https://pinergy.ie/', iconUrl: 'https://pinergy.ie/wp-content/uploads/2024/03/logo.svg' },
    { label: 'Prepay Power', value: 'https://www.prepaypower.ie/', iconUrl: 'https://www.prepaypower.ie/themes/custom/prepaypower/logo.svg' },
    { label: 'Yuno Energy', value: 'https://yunoenergy.ie/', iconUrl: 'assets/yunoenergy.png' },
    { label: 'Waterpower', value: 'https://www.waterpower.ie/', iconUrl: 'assets/waterpower.png' },
    { label: 'Ecopower', value: 'https://ecopower.ie/', iconUrl: 'assets/ecopower.png' },
    { label: 'Community Power', value: 'https://communitypower.ie/', iconUrl: 'https://communitypower.ie/wp-content/uploads/2020/02/cp-logo.png' },
    // Add more predefined provider icons as needed
  ];

  constructor(
    private fb: FormBuilder,
    private db: Database,
    private router: Router,
    private notifier: NotifierService,
    private translate: TranslateService,
    private auth: Auth
  ) {
    // Initialize the report form with validators
    this.reportForm = this.fb.group({
      issueType: ['', Validators.required],
      description: ['', [Validators.required, Validators.maxLength(500)]],
      email: ['', [Validators.email]] // Email is optional
    });

    // Initialize the assignLandlordForm with validators
    this.assignLandlordForm = this.fb.group({
      userUID: ['', Validators.required],
    });

    // Initialize the assignAddressForm with validators
    this.assignAddressForm = this.fb.group({
      landlordUID: ['', Validators.required],
      newAddress: ['', [Validators.required, Validators.minLength(5)]], // Adjust minLength as needed
    });

    // Initialize the form for assigning users as landlords (New FormGroup)
    this.assignUserAsLandlordForm = this.fb.group({
      userUID: ['', Validators.required],
    });

    // Initialize the user data form for the modal
    this.userDataForm = this.fb.group({
      depositAmount: [null], // No validators
      contractStartDate: [''], // No required validator
      contractExpireDate: [''], // No required validator
      contractLink: [''], // No required validator
      nextPaymentDate: [''], // No required validator
      nextPaymentAmount: [null], // No validators
      role: ['Normal User', Validators.required] // Default role
    });

    // Initialize the form for editing billing
    this.billingForm = this.fb.group({
      providerIcon: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(0)]],
      period: ['', Validators.required],
      status: ['', Validators.required],
      billImage: [''],
      key: [''],
    });

    // Initialize the form for adding new billing
    this.newBillingForm = this.fb.group({
      providerIcon: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(0)]],
      period: ['', Validators.required],
      status: ['Active', Validators.required],
      billImage: ['']
    });

    // Initialize the billing data form
    this.billingDataForm = this.fb.group({
      carbonTax: [0, [Validators.required, Validators.min(0)]],
      energyCostPerKW: [0, [Validators.required, Validators.min(0)]],
      gasCostPerM3: [0, [Validators.required, Validators.min(0)]],
      gasStandingChargePerDay: [0, [Validators.required, Validators.min(0)]],
      internet_fee: [0, [Validators.required, Validators.min(0)]],
      psoLevy: [0, [Validators.required, Validators.min(0)]],
      standingChargePerDay: [0, [Validators.required, Validators.min(0)]],
      trash_fee: [0, [Validators.required, Validators.min(0)]],
      vatRate: [0, [Validators.required, Validators.min(0)]]
    });
  }

  ngOnInit(): void {
    // Subscribe to authentication state
    authState(this.auth).subscribe((user) => {
      if (user) {
        this.currentUser = user;
        // Determine the user's role by checking admin and landlord permissions
        this.handleUserRoleAndFetchData();
        
      } else {
        // User is not authenticated, navigate to login
        this.currentUser = null;
        this.translate.get('ADMIN.NOT_AUTHENTICATED').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
        this.router.navigate(['/login']);
      }
    });
  }

  /**
   * Handles user role determination and fetches data based on the role.
   * @param uid - The UID of the current user.
   */
  private async handleUserRoleAndFetchData(): Promise<void> {
    try {
    
      // Fetch potential landlords and existing landlords only for admins
      await this.fetchPotentialLandlords(); // Fetch potential landlords only for admins
      await this.fetchLandlords();          // Fetch existing landlords only for admins
      // Fetch additional data based on role
      await this.fetchAvailableAddresses();
      await this.fetchUsersByAddress();
    } catch (error) {
      console.error('Error in handleUserRoleAndFetchData:', error);
      // Notify the user about the error
      this.translate
        .get('ADMIN.ERROR_HANDLING_DATA_FETCH')
        .subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
    }
  }

  /**
   * Fetches a list of users who can be assigned as landlords.
   * Excludes users who are already landlords or admins.
   */
  async fetchPotentialLandlords(): Promise<void> {
    const accountsRef = ref(this.db, 'Accounts');
    const adminRef = ref(this.db, 'Permission/admin');
    const landlordRef = ref(this.db, 'Permission/landlord');
  
    try {
      // Fetch Accounts, Admins, and Landlords concurrently
      const [accountsSnapshot, adminsSnapshot, landlordsSnapshot] = await Promise.all([
        get(accountsRef),
        get(adminRef),
        get(landlordRef),
      ]);
  
      if (accountsSnapshot.exists()) {
        const users = accountsSnapshot.val() as { [uid: string]: UserData };
        const admins = adminsSnapshot.exists()
          ? (adminsSnapshot.val() as { [uid: string]: boolean })
          : {};
        const landlords = landlordsSnapshot.exists()
          ? (landlordsSnapshot.val() as LandlordPermissions)
          : {};
  
        this.potentialLandlords = [];
  
        for (const uid in users) {
          if (users.hasOwnProperty(uid)) {
            // Exclude admins
            if (admins[uid]) continue;
  
            // Exclude existing landlords
            if (landlords[uid]) continue;
            
            if(users[uid].role === 'Landlord') continue;

            // **New Condition: Exclude current user if they are a landlord**
            if (
              this.userRole === 'landlord' && // Check if current user is a landlord
              this.currentUser?.uid === uid // Check if the user in the loop is the current user
            ) {
              continue; // Skip adding the current user to potential landlords
            }
  
            this.potentialLandlords.push({
              uid: uid,
              name: users[uid].Name,
              email: users[uid].Email,
              role: users[uid].role || 'Normal User',
            });
          }
        }
  
      } else {
      }
    } catch (error) {
      console.error('Error fetching potential landlords:', error);
      // Optionally, rethrow or handle the error
      throw error;
    }
  }
  

  /**
   * Fetches the list of current landlords from Firebase.
   */
  async fetchLandlords(): Promise<void> {
    const landlordsRef = ref(this.db, 'Permission/landlord');
    const accountsRef = ref(this.db, 'Accounts');

    try {
      // Fetch Landlords and Accounts concurrently
      const [landlordsSnapshot, accountsSnapshot] = await Promise.all([
        get(landlordsRef),
        get(accountsRef),
      ]);

      if (landlordsSnapshot.exists()) {
        const landlordsData = landlordsSnapshot.val() as LandlordPermissions;
        const users = accountsSnapshot.exists()
          ? (accountsSnapshot.val() as { [uid: string]: UserData })
          : {};

        this.landlordsList = [];

        for (const landlordUID in landlordsData) {
          if (
            landlordsData.hasOwnProperty(landlordUID) &&
            users[landlordUID]
          ) {
            this.landlordsList.push({
              uid: landlordUID,
              name: users[landlordUID].Name,
              email: users[landlordUID].Email,
              role: users[landlordUID].role || 'Landlord',
            });
          }
        }
        for (const uid in users) {
          // Check if a landlord with this UID already exists in landlordsList
          const landlordExists = this.landlordsList.some(landlord => landlord.uid === uid);
          
          if (users[uid].role === 'Landlord' && !landlordExists) {
            this.landlordsList.push({
              uid: uid,
              name: users[uid].Name,
              email: users[uid].Email,
              role: users[uid].role || 'Landlord',
            });
          }
        }
        
      } else {
        this.landlordsList = [];
      }
    } catch (error) {
      console.error('Error fetching landlords:', error);

      throw error;
    }
  }

  /**
   * Fetches the list of addresses for a specific landlord.
   * @param uid - The UID of the landlord.
   */
  async fetchLandlordAddresses(uid: string): Promise<void> {
    const landlordAddressesRef = ref(this.db, `Permission/landlord/${uid}/addresses`);
    try {
      const snapshot = await get(landlordAddressesRef);
      if (snapshot.exists()) {

        //this.addresses = Object.keys(snapshot.val());
      } else {
        this.addresses = [];
      }
    } catch (error) {
      // Optionally, rethrow or handle the error
      throw error;
    }
  }

  /**
   * Fetches the list of addresses available based on the user's role.
   * - Admins fetch all available addresses.
   * - Landlords fetch only their own addresses.
   */
  async fetchAvailableAddresses(): Promise<void> {
      // Admins fetch all available addresses
      const addressesRef = ref(this.db, 'AvailableAddresses');
      try {
        const snapshot = await get(addressesRef);
        if (snapshot.exists()) {
          const addressesData = snapshot.val();
       
          // Use Object.values() to retrieve address values
          this.addresses = Object.values(addressesData);
        } else {
          this.addresses = [];
        }
      } catch (error) {
        console.error('Error fetching available addresses:', error);
        // Optionally, handle the error (e.g., notify the user)
        this.notifier.notify('error', 'Failed to fetch available addresses.');
      }
  }
  

  /**
   * Returns the list of current landlords.
   */
  getCurrentLandlords(): UserList[] {
    return this.landlordsList;
  }

  /**
   * Assigns the landlord role to a selected user.
   */
  assignUserAsLandlord(): void {
    if (this.assignLandlordForm.invalid) {
      this.translate.get('ADMIN.FORM_INVALID').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    const userUID = this.assignLandlordForm.value.userUID;

    const updates: { [key: string]: any } = {};

    // Update the user's role in Accounts
    updates[`Accounts/${userUID}/role`] = 'Landlord';

    // Add the user to Permission/landlord with an empty addresses object
    updates[`Permission/landlord/${userUID}/addresses`] = {};

    // Update Firebase with multi-location updates
    const rootRef = ref(this.db);

    update(rootRef, updates)
      .then(() => {
        this.translate.get('ADMIN.LANDLORD_ROLE_ASSIGNED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });

        // Refresh potential landlords and landlords list
        this.fetchPotentialLandlords();
        this.fetchLandlords();

        // Reset the form
        this.assignLandlordForm.reset();
      })
      .catch(error => {
        console.error('Error assigning landlord role:', error);
        this.translate.get('ADMIN.ERROR_ASSIGNING_LANDLORD_ROLE').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
      });
  }


    /**
   * Assigns a new address to a selected landlord.
   * If the address is already assigned to another landlord, it removes the assignment first.
   * Additionally, it adds the new address to AvailableAddresses node if it doesn't already exist.
   */
  assignAddressToLandlord(): void {
    // Validate the form
    if (this.assignAddressForm.invalid) {
      this.translate.get('ADMIN.FORM_INVALID').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    const landlordUID = this.assignAddressForm.value.landlordUID;
    const newAddress = this.assignAddressForm.value.newAddress.trim();

    // Validate the new address input
    if (!newAddress) {
      this.translate.get('ADMIN.INVALID_ADDRESS').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    // References to relevant Firebase nodes
    const newAddressRef = ref(this.db, `Permission/landlord/${landlordUID}/addresses/${newAddress}`);
    const landlordsRef = ref(this.db, 'Permission/landlord');
    const availableAddressesRef = ref(this.db, 'AvailableAddresses');

    // Initialize an object for multi-location updates
    const updates: { [key: string]: any } = {};

    // Flags to track assignment status
    let isAddressAssigned = false;
    let currentLandlordUID: string | null = null;

    // Step 1: Check if the address is already assigned to another landlord
    get(landlordsRef)
      .then(snapshot => {
        if (snapshot.exists()) {
          const landlords = snapshot.val() as LandlordPermissions;

          // Iterate through all landlords to find if the address is already assigned
          for (const uid in landlords) {
            if (landlords.hasOwnProperty(uid)) {
              const landlord = landlords[uid];
              if (landlord.addresses && landlord.addresses[newAddress]) {
                isAddressAssigned = true;
                currentLandlordUID = uid;
                break; // Assuming addresses are unique per landlord
              }
            }
          }

          if (isAddressAssigned && currentLandlordUID) {
            // Prevent removing the address from the same landlord
            if (currentLandlordUID === landlordUID) {
              // The address is already assigned to the selected landlord
              this.translate.get('ADMIN.ADDRESS_ALREADY_ASSIGNED_TO_LANDLORD').subscribe((res: string) => {
                this.notifier.notify('warning', res);
              });
              // Abort the operation
              return Promise.reject('Address already assigned to this landlord.');
            }

            // Remove the address from the current landlord
            updates[`Permission/landlord/${currentLandlordUID}/addresses/${newAddress}`] = null;
          }

          // Assign the address to the new landlord
          updates[`Permission/landlord/${landlordUID}/addresses/${newAddress}`] = true;

          // Perform the multi-location update
          return update(ref(this.db), updates);
        } else {
          // No landlords exist, simply assign the address
          updates[`Permission/landlord/${landlordUID}/addresses/${newAddress}`] = true;
          return update(ref(this.db), updates);
        }
      })
      .then(() => {
        // Step 2: Ensure the address exists in AvailableAddresses
        return get(availableAddressesRef);
      })
      .then(snapshot => {
        if (snapshot.exists()) {
          const addressesArray = Object.values(snapshot.val()) as string[];
          const addressExists = addressesArray.includes(newAddress);

          if (!addressExists) {
            // Add the new address to AvailableAddresses using push
            const newAddressKeyRef = ref(this.db, `AvailableAddresses/${push(ref(this.db, 'AvailableAddresses')).key}`);
            return set(newAddressKeyRef, newAddress);
          } else {
            // Address already exists in AvailableAddresses; do nothing
            return Promise.resolve();
          }
        } else {
          // AvailableAddresses node doesn't exist; create it and add the new address
          return set(ref(this.db, `AvailableAddresses/0`), newAddress);
        }
      })
      .then(() => {
        // Step 3: Notify success based on assignment type
        if (isAddressAssigned && currentLandlordUID && currentLandlordUID !== landlordUID) {
          // Address was reassigned from another landlord
          this.translate.get('ADMIN.ADDRESS_REASSIGNED').subscribe((res: string) => {
            this.notifier.notify('success', res);
          });
        } else {
          // New address assignment
          this.translate.get('ADMIN.ADDRESS_ASSIGNED').subscribe((res: string) => {
            this.notifier.notify('success', res);
          });
        }

        // Refresh available addresses to reflect the new assignment
        this.fetchAvailableAddresses();

        // Refresh the selected landlord's addresses to update the UI
        this.fetchSelectedLandlordAddresses(landlordUID);

        // Reset the assignment form for a better user experience
        this.assignAddressForm.reset();
      })
      .catch(error => {
        if (error !== 'Address already assigned to this landlord.') {
          // Handle unexpected errors
          console.error('Error assigning address to landlord:', error);
          this.translate.get('ADMIN.ERROR_ASSIGNING_ADDRESS').subscribe((res: string) => {
            this.notifier.notify('error', res);
          });
        }
        // If the address was already assigned to the landlord, no further action is needed
      });
  }


  /**
   * Fetches and updates the addresses of the selected landlord.
   * @param landlordUID - The UID of the landlord.
   */
  async fetchSelectedLandlordAddresses(landlordUID: string): Promise<void> {
    const landlordAddressesRef = ref(this.db, `Permission/landlord/${landlordUID}/addresses`);
    try {
      const snapshot = await get(landlordAddressesRef);
      if (snapshot.exists()) {
        this.selectedLandlordAddresses = Object.keys(snapshot.val());
      } else {
        this.selectedLandlordAddresses = [];
      }
    } catch (error) {
      console.error('Error fetching selected landlord addresses:', error);
      this.translate.get('ADMIN.ERROR_FETCHING_LANDLORD_ADDRESSES').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
    }
  }

  /**
   * Removes an address from a landlord's assigned addresses.
   * @param address - The address to remove.
   */
  removeAddress(address: string): void {
    if (!this.assignAddressForm.value.landlordUID) {
      this.translate.get('ADMIN.SELECT_LANDLORD_FIRST').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    const landlordUID = this.assignAddressForm.value.landlordUID;

    const addressRef = ref(this.db, `Permission/landlord/${landlordUID}/addresses/${address}`);

    // Remove the address
    remove(addressRef)
      .then(() => {
        this.translate.get('ADMIN.ADDRESS_REMOVED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });

        // Refresh available addresses
        this.fetchAvailableAddresses();

        // Refresh the selected landlord's addresses
        this.fetchSelectedLandlordAddresses(landlordUID);
      })
      .catch(error => {
        console.error('Error removing address from landlord:', error);
        this.translate.get('ADMIN.ERROR_REMOVING_ADDRESS').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
      });
  }

  /**
   * Fetches users and groups them by address based on the user's role.
   * - Admin: Fetches all users across all addresses.
   * - Landlord: Fetches only users associated with their addresses.
   */
  async fetchUsersByAddress(): Promise<void> {
    this.usersByAddress = {}; // Reset usersByAddress
    this.addressesWithUsers = []; // Reset addressesWithUsers

    try {
      const userPromises = this.addresses.map(async (address) => {
        // Create a query for users with Location equal to current address
        const accountsQuery = query(
          ref(this.db, 'Accounts'),
          orderByChild('Location'),
          equalTo(address)
        );

        const snapshot = await get(accountsQuery);

        if (snapshot.exists()) {
          const users = snapshot.val() as { [uid: string]: UserData };
          const usersArray: UserData[] = [];

          for (const userId in users) {
            const user = users[userId] as UserData;

            // Fetch reports if available
            const userReports = await this.fetchUserReports(userId);

            usersArray.push({
              ...user,
              reports: userReports
            });
          }

          if (usersArray.length > 0) {
            this.usersByAddress[address] = usersArray;
            this.addressesWithUsers.push(address);
          }
        }
      });

      await Promise.all(userPromises); // Wait for all queries to complete

      if (this.addressesWithUsers.length === 0) {

      }
    } catch (error) {
      console.error('Error fetching users by address:', error);
      this.translate.get('ADMIN.ERROR_FETCHING_USERS').subscribe(msg => {
        this.notifier.notify('error', msg);
      });
    } finally {

    }
  }

  /**
   * Fetches reports associated with a user.
   * @param userId - The UID of the user.
   * @returns An array of report strings.
   */
  async fetchUserReports(userId: string): Promise<string[]> {
    const reportsRef = ref(this.db, `reports/${userId}`);
    const snapshot = await get(reportsRef);

    if (snapshot.exists()) {
      const reports = snapshot.val();
      if (Array.isArray(reports)) {
        return reports;
      } else if (typeof reports === 'object' && reports !== null) {
        return Object.values(reports).filter((report): report is string => typeof report === 'string');
      }
    }
    return [];
  }

  /**
   * Filters users based on the selected address.
   */
  filterUsersByAddress(): void {
    if (this.selectedAddress) {
      if (this.usersByAddress[this.selectedAddress]) {
        this.filteredUsers = this.usersByAddress[this.selectedAddress];
      } else {
        this.filteredUsers = [];
      }
      this.onAddressSelected(this.selectedAddress);
      this.fetchBillingDataDetails(this.selectedAddress);
    } else {
      this.filteredUsers = []; // No users for the selected address
      this.currentBillingData = null;
      this.previousBillingData = [];
      this.isBillingModalOpen = false;
      this.isAddBillingModalOpen = false;
      this.currentBillingDataList = [];
    }
  }

  /**
   * Method to generate a random 6-character code with uppercase letters and numbers
   */
  generateInvitationCode(): void {
    if (!this.selectedAddress) {
      this.translate.get('ADMIN.ADDRESS_REQUIRED').subscribe((res: string) => {
        this.notifier.notify('warning', res);
      });
      return;
    }

    if (this.email && !this.isValidEmail(this.email)) {
      this.translate.get('ADMIN.INVALID_EMAIL').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    this.isSubmitting = true; // Start loading

    // Generate the invitation code
    this.generatedCode = this.generateRandomCode(6);

    // Store the generated code in Firebase
    const invitationData = {
      code: this.generatedCode,
      address: this.selectedAddress,
      email: this.email || 'Not Provided', // Handle optional email
      generatedBy: this.currentUser ? this.currentUser.uid : 'Unknown',
      timestamp: new Date().toISOString()
    };

    const invitationsRef = ref(this.db, 'invitations');
    push(invitationsRef, invitationData)
      .then(() => {
        this.translate.get('ADMIN.CODE_GENERATED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });

        this.filterUsersByAddress(); // Update the filtered users
      })
      .catch(error => {
        console.error('Error generating invitation code:', error);
        this.translate.get('ADMIN.CODE_GENERATION_FAILED', { error }).subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
      })
      .finally(() => {
        this.isSubmitting = false; // End loading
      });
  }

  /**
   * Helper method to generate a random code
   * @param length - The length of the code to generate.
   * @returns A shuffled random code string.
   */
  private generateRandomCode(length: number): string {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const allCharacters = letters + numbers;

    let code = '';

    // Ensure at least one letter
    code += letters.charAt(Math.floor(Math.random() * letters.length));

    // Ensure at least one number
    code += numbers.charAt(Math.floor(Math.random() * numbers.length));

    // Generate remaining characters
    for (let i = 2; i < length; i++) {
      code += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
    }

    // Shuffle the code to mix letters and numbers
    code = this.shuffleString(code);

    return code;
  }

  /**
   * Helper method to shuffle a string
   * @param str - The string to shuffle.
   * @returns A shuffled string.
   */
  private shuffleString(str: string): string {
    const arr = str.split('');
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr.join('');
  }

  /**
   * Method to copy the generated code to clipboard
   */
  copyCode(): void {
    if (this.generatedCode) {
      navigator.clipboard.writeText(this.generatedCode).then(
        () => {
          this.translate.get('ADMIN.CODE_COPIED').subscribe((res: string) => {
            this.notifier.notify('success', res);
          });
        },
        err => {
          console.error('Could not copy text: ', err);
          this.translate.get('ADMIN.CODE_COPY_FAILED').subscribe((res: string) => {
            this.notifier.notify('error', res);
          });
        }
      );
    }
  }

  /**
   * Validate email format
   * @param email - The email string to validate.
   * @returns Boolean indicating if the email is valid.
   */
  isValidEmail(email: string): boolean {
    if (!email) return true; // Email is optional
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  /**
   * Handles address selection and fetches corresponding billing data
   * @param address - The selected address.
   */
  onAddressSelected(address: string): void {
    this.selectedAddress = address;
    this.fetchBillingData(address);
  }

  /**
   * Method to fetch the billing data for the selected address
   * @param address - The address for which to fetch billing data.
   */
  fetchBillingData(address: string): void {
    const billingRef = ref(this.db, `billing/${address}`);

    get(billingRef).then(snapshot => {
      if (snapshot.exists()) {
        const billingData = snapshot.val();

        // Handle current billing data (allowing multiple entries)
        if (billingData.currentbilling) {
          const billingDataModel = billingData.currentbilling;

          // Store all current billing entries, including their keys
          this.currentBillingDataList = Object.keys(billingDataModel).map(key => ({
            ...billingDataModel[key], // Copy all billing data properties
            key: key // Append the key for each billing entry
          }));

        } else {
          this.currentBillingDataList = [];
        }

        // Handle previous billing data (if available)
        if (billingData.previousbilling) {
          this.previousBillingData = Object.values(billingData.previousbilling).filter((bill): bill is any => typeof bill === 'object' && bill !== null);
        } else {
          this.previousBillingData = [];
        }
      } else {
        this.currentBillingDataList = [];
        this.previousBillingData = [];
      }
    }).catch(error => {
      console.error('Error fetching billing data:', error);
    });
  }

  /**
   * Opens the modal to view and edit user data
   * @param user - The user data to view/edit.
   */
  openViewDataModal(user: UserData): void {
    this.selectedUser = user;
    this.fetchUserData(user);
    this.isModalOpen = true;
  }

  /**
   * Closes the user data modal
   */
  closeModal(): void {
    this.isModalOpen = false;
    this.selectedUser = null;
    this.userDataForm.reset({
      depositAmount: 0,
      contractStartDate: '',
      contractExpireDate: '',
      contractLink: '',
      nextPaymentDate: '',
      nextPaymentAmount: 0,
      role: 'Normal User'
    });
  }

  /**
   * Fetches additional data for a user from Firebase
   * @param user - The user data.
   */
  async fetchUserData(user: UserData): Promise<void> {
    if (!user.UID) {
      this.translate.get('ADMIN.USER_UID_NOT_FOUND').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    const userRef = ref(this.db, `Accounts/${user.UID}`);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      const userData = snapshot.val() as UserData;
      // Populate the form with existing data if available
      this.userDataForm.patchValue({
        depositAmount: userData.depositAmount || 0,
        contractStartDate: userData.contractStartDate || '',
        contractExpireDate: userData.contractExpireDate || '',
        contractLink: userData.contractLink || '',
        nextPaymentDate: userData.nextPaymentDate || '',
        nextPaymentAmount: userData.nextPaymentAmount || 0,
        role: userData.role || 'Normal User' // Populate role
      });
    } else {
      // No additional data exists, reset the form
      this.userDataForm.reset({
        depositAmount: 0,
        contractStartDate: '',
        contractExpireDate: '',
        contractLink: '',
        nextPaymentDate: '',
        nextPaymentAmount: 0,
        role: 'Normal User'
      });
    }
  }

  /**
   * Saves or updates user data in Firebase
   */
  saveUserData(): void {
    if (!this.selectedUser || !this.selectedUser.UID) {
      this.translate.get('ADMIN.SELECT_USER_FIRST').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    // Prepare an object to hold only non-empty fields
    const updatedData: Partial<UserData> = {};

    // Add fields to the updatedData object only if they have values
    if (this.userDataForm.get('depositAmount')?.value !== null) {
      updatedData.depositAmount = this.userDataForm.get('depositAmount')?.value;
    }

    if (this.userDataForm.get('contractStartDate')?.value) {
      updatedData.contractStartDate = this.userDataForm.get('contractStartDate')?.value;
    }

    if (this.userDataForm.get('contractExpireDate')?.value) {
      updatedData.contractExpireDate = this.userDataForm.get('contractExpireDate')?.value;
    }

    if (this.userDataForm.get('contractLink')?.value) {
      updatedData.contractLink = this.userDataForm.get('contractLink')?.value;
    }

    if (this.userDataForm.get('nextPaymentDate')?.value) {
      updatedData.nextPaymentDate = this.userDataForm.get('nextPaymentDate')?.value;
    }

    if (this.userDataForm.get('nextPaymentAmount')?.value !== null) {
      updatedData.nextPaymentAmount = this.userDataForm.get('nextPaymentAmount')?.value;
    }

    if (this.userDataForm.get('role')?.value) {
      updatedData.role = this.userDataForm.get('role')?.value;
    }

    // Ensure at least one field is provided
    if (Object.keys(updatedData).length === 0) {
      this.translate.get('ADMIN.NO_DATA_PROVIDED').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    const userRef = ref(this.db, `Accounts/${this.selectedUser.UID}`);

    // Save or update the non-empty fields in Firebase
    update(userRef, updatedData)
      .then(() => {
        this.translate.get('ADMIN.USER_DATA_SAVED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });
        // Update the local user data using non-null assertion
        Object.assign(this.selectedUser!, updatedData);
        this.closeModal();
      })
      .catch(error => {
        console.error('Error saving user data:', error);
        this.translate.get('ADMIN.ERROR_SAVING_USER_DATA').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
      });
  }

  /**
   * Toggles user status between Active and Inactive
   * @param user - The user whose status is to be toggled.
   */
  toggleUserStatus(user: any): void {
    // Determine the new status
    const newStatus = user.Status === 'Active' ? 'Inactive' : 'Active';

    // Prepare the multi-location updates
    const updates: { [key: string]: any } = {};
    updates[`Accounts/${user.UID}/Status`] = newStatus;
    updates[`Addresses/${user.Location}/${user.UID}/status`] = newStatus;

    const rootRef = ref(this.db);

    // Perform the multi-location update
    update(rootRef, updates)
      .then(() => {
        // Update the local UI state
        user.Status = newStatus;

        // Notify the user of the successful update
        this.translate.get('ADMIN.STATUS_UPDATED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });
      })
      .catch(error => {
        // Handle any errors during the update

        // Notify the user of the error
        this.translate.get('ADMIN.ERROR_UPDATING_STATUS').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });

        // Log the error for debugging purposes
        console.error('Error updating user status:', error);
      });
  }

  /**
   * Deletes a user from Firebase
   * @param user - The user to delete.
   */
  deleteUser(user: UserData): void {
    this.translate.get('ADMIN.CONFIRM_DELETE_USER').subscribe((confirmMessage: string) => {
      if (confirm(confirmMessage)) {
        const userRef = ref(this.db, `Accounts/${user.UID}`);

        // Use remove() function to delete the user
        remove(userRef)
          .then(() => {
            this.translate.get('ADMIN.USER_DELETED').subscribe((res: string) => {
              this.notifier.notify('success', res);
            });

            // Remove user from the local data structure
            const usersForAddress = this.usersByAddress[user.Location];
            this.usersByAddress[user.Location] = usersForAddress.filter(u => u.UID !== user.UID);

            // If no more users are present under the selected address, reset the selected address
            if (this.usersByAddress[user.Location].length === 0) {
              delete this.usersByAddress[user.Location];
              this.selectedAddress = '';
              this.filterUsersByAddress(); // Update the filtered users
            }
          })
          .catch(error => {
            this.translate.get('ADMIN.ERROR_DELETING_USER').subscribe((res: string) => {
              this.notifier.notify('error', res);
            });
            console.error('Error deleting user:', error);
          });
      }
    });
  }

  /**
   * Opens the billing data modal
   */
  openBillingDataModal(): void {
    this.isBillingDataModalOpen = true;
  }

  /**
   * Closes the billing data modal
   */
  closeBillingDataModal(): void {
    this.isBillingDataModalOpen = false;
  }

  /**
   * Opens the modal to edit billing
   * @param billingData - The billing data to edit.
   */
  openEditBillingModal(billingData: any): void {
    this.billingForm.patchValue({
      providerIcon: billingData.providerIcon,
      amount: billingData.amount,
      period: billingData.period,
      status: billingData.status,
      billImage: billingData.billImage,
      key: billingData.key
    });
    this.isBillingModalOpen = true;
  }

  /**
   * Closes the edit billing modal
   */
  closeBillingModal(): void {
    this.isBillingModalOpen = false;
  }

  /**
   * Opens the modal to add new billing
   */
  openAddBillingModal(): void {
    this.isAddBillingModalOpen = true;
  }

  /**
   * Closes the add billing modal
   */
  closeAddBillingModal(): void {
    this.isAddBillingModalOpen = false;
  }

  /**
   * Saves the new billing data
   */
  saveNewBillingData(): void {
    if (!this.selectedAddress) {
      this.translate.get('ADMIN.SELECT_ADDRESS_FIRST').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    if (this.newBillingForm.invalid) {
      this.translate.get('ADMIN.FORM_INVALID').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    const newBillingData = this.newBillingForm.value;

    const billingRef = ref(this.db, `billing/${this.selectedAddress}/currentbilling`);
    push(billingRef, newBillingData)
      .then(() => {
        this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_DATA_ADDED').subscribe(msg => {
          this.notifier.notify('success', msg);
        });
        this.isAddBillingModalOpen = false; // Close the modal
        this.fetchBillingData(this.selectedAddress);
      })
      .catch(error => {
        console.error('Error adding new billing:', error);
        this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_ADDING_BILLING_DATA').subscribe(msg => {
          this.notifier.notify('error', msg);
        });
      });
  }

  /**
   * Saves the updated billing data
   */
  saveBillingData(): void {
    if (this.billingForm.invalid) {
      this.translate.get('ADMIN.FORM_INVALID').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    const updatedData = this.billingForm.value;

    // Check if the form has a key for the current billing entry
    const billingKey = this.billingForm.get('key')?.value;

    if (!billingKey) {
      this.translate.get('ADMIN.BILLING_MANAGEMENT.NO_BILLING_KEY').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    // If the status is 'Completed', move it to previous billing
    if (updatedData.status === 'Completed') {
      this.moveToPreviousBilling(billingKey, updatedData);
    } else {
      const currentBillingRef = ref(this.db, `billing/${this.selectedAddress}/currentbilling/${billingKey}`);
      update(currentBillingRef, updatedData)
        .then(() => {
          this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_UPDATED').subscribe(msg => {
            this.notifier.notify('success', msg);
          });
          // Refresh the billing data after the update
          this.fetchBillingData(this.selectedAddress);
        })
        .catch(error => {
          console.error('Error updating billing:', error);
          this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_UPDATING_BILLING_DATA').subscribe(msg => {
            this.notifier.notify('error', msg);
          });
        });
    }

    this.closeBillingModal();
  }

  /**
   * Moves the current billing to previous billing if status is 'Completed'
   * @param billingKey - The key of the billing entry.
   * @param updatedData - The updated billing data.
   */
  moveToPreviousBilling(billingKey: string, updatedData: any): void {
    const currentBillingRef = ref(this.db, `billing/${this.selectedAddress}/currentbilling/${billingKey}`);
    const previousBillingRef = ref(this.db, `billing/${this.selectedAddress}/previousbilling/${billingKey}`);

    // First move the data to previous billing
    set(previousBillingRef, updatedData)
      .then(() => {
        // Once the data is moved, remove it from current billing
        return remove(currentBillingRef);
      })
      .then(() => {
        this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_MOVED_TO_PREVIOUS').subscribe(msg => {
          this.notifier.notify('success', msg);
        });
        // Refresh the billing data after the move
        this.fetchBillingData(this.selectedAddress);
      })
      .catch(error => {
        console.error('Error moving billing to previous:', error);
        this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_MOVE_FAILED').subscribe(msg => {
          this.notifier.notify('error', msg);
        });
      });
  }

  /**
   * Deletes a billing entry
   * @param billingData - The billing data to delete.
   */
  deleteCurrentBilling(billingData: any): void {
    const billingKey = billingData.key; // Assuming billing entries are stored with unique keys

    const billingRef = ref(this.db, `billing/${this.selectedAddress}/currentbilling/${billingKey}`);

    remove(billingRef)
      .then(() => {
        this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_DELETED').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });
        this.fetchBillingData(this.selectedAddress); // Refresh the billing data
      })
      .catch(error => {
        this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_DELETING_BILLING').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });
        console.error('Error deleting billing data:', error);
      });
  }

  /**
   * Fetches billing data details for the selected address
   * @param address - The address for which to fetch billing data.
   */
  async fetchBillingDataDetails(address: string): Promise<void> {
    const billingDetailsRef = ref(this.db, `billing/${address}`);
    const snapshot = await get(billingDetailsRef);

    if (snapshot.exists()) {
      this.billingData = snapshot.val() as BillingData;
      this.billingDataForm.patchValue(this.billingData);
    } else {
      this.billingData = null;
      this.billingDataForm.reset({
        carbonTax: 0,
        energyCostPerKW: 0,
        gasCostPerM3: 0,
        gasStandingChargePerDay: 0,
        internet_fee: 0,
        psoLevy: 0,
        standingChargePerDay: 0,
        trash_fee: 0,
        vatRate: 0
      });
    }
  }

  /**
   * Saves or updates billing data details in Firebase
   */
  saveBillingDataDetails(): void {
    if (!this.selectedAddress) {
      this.translate.get('ADMIN.SELECT_ADDRESS_FIRST').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    if (this.billingDataForm.invalid) {
      this.translate.get('ADMIN.FORM_INVALID').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    if (this.billingData) {
      // Modify existing billing data
      const billingDetailsRef = ref(this.db, `billing/${this.selectedAddress}`);
      update(billingDetailsRef, this.billingDataForm.value)
        .then(() => {
          this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_DATA_UPDATED').subscribe((res: string) => {
            this.notifier.notify('success', res);
          });
          this.closeBillingDataModal();
          this.fetchBillingDataDetails(this.selectedAddress);
        })
        .catch(error => {
          console.error('Error updating billing data:', error);
          this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_UPDATING_BILLING_DATA').subscribe((res: string) => {
            this.notifier.notify('error', res);
          });
        });
    } else { 
      // Add new billing data
      const billingDetailsRef = ref(this.db, `billing/${this.selectedAddress}`);
      set(billingDetailsRef, this.billingDataForm.value)
        .then(() => {
          this.translate.get('ADMIN.BILLING_MANAGEMENT.BILLING_DATA_ADDED').subscribe((res: string) => {
            this.notifier.notify('success', res);
          });
          this.closeBillingDataModal();
        })
        .catch(error => {
          console.error('Error adding billing data:', error);
          this.translate.get('ADMIN.BILLING_MANAGEMENT.ERROR_ADDING_BILLING_DATA').subscribe((res: string) => {
            this.notifier.notify('error', res);
          });
        });
    }
  }

  /**
   * Handles the selection of a landlord and fetches their assigned addresses.
   */
  onLandlordSelected(): void {
    const landlordUID = this.assignAddressForm.get('landlordUID')?.value;
    if (landlordUID) {
      this.fetchSelectedLandlordAddresses(landlordUID);
    } else {
      this.selectedLandlordAddresses = [];
    }
  }

}
