import { Component } from '@angular/core';
import { ref, get, getDatabase } from 'firebase/database'; // Import Firebase methods
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from 'src/app/auth.guard';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css']
})
export class InvitationComponent {
  invitationCode: string = ''; // User input for invitation code
  db = getDatabase(); // Get a reference to the Realtime Database
  maxAttempts: number = 5; // Maximum number of allowed attempts
  lockoutTime: number = 5 * 60 * 1000; // Lockout time in milliseconds (5 minutes)

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private translate: TranslateService,
    private authguard: AuthGuard
  ) {}

  /**
   * Method to handle invitation validation with rate limiting.
   */
  onValidateInvitation() {
    const attemptsData = this.getAttemptData();
    if (attemptsData.lockedUntil && new Date().getTime() < attemptsData.lockedUntil) {
      // If the user is locked out, show a message and prevent further attempts
      this.translate.get('INVITATION.LOCKED_OUT').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
      return;
    }

    if (this.invitationCode.trim() === '') {
      this.translate.get('INVITATION.EMPTY_CODE').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
      return;
    }

    const dbRef = ref(this.db, 'invitations');

    get(dbRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          let invitationFound = false;
          const invitations = snapshot.val();

          // Loop through all invitations to find a match by the `code` field
          for (const key in invitations) {
            if (invitations[key].code === this.invitationCode.trim()) {
              invitationFound = true;

              // Reset the attempts if successful
              this.resetAttempts();

              // Store the invitation code and associated address in localStorage
              this.storeInvitationData(this.invitationCode.trim(), invitations[key].address);

              this.translate.get('INVITATION.INVITATION_VALIDATED').subscribe((msg: string) => {
                this.notifier.notify('success', msg);
              });
              this.authguard.validateInvitation();
              this.router.navigate(['/auth/sign-up']); // Navigate to the sign-up page
              break;
            }
          }

          if (!invitationFound) {
            this.incrementAttempts(); // Increment the attempts if not successful
            this.translate.get('INVITATION.INVALID_INVITATION').subscribe((msg: string) => {
              this.notifier.notify('error', msg);
            });
          }
        } else {
          this.incrementAttempts(); // Increment the attempts if not successful
          this.translate.get('INVITATION.INVALID_INVITATION').subscribe((msg: string) => {
            this.notifier.notify('error', msg);
          });
        }
      })
      .catch((error) => {
        this.translate.get('INVITATION.VALIDATION_ERROR').subscribe((msg: string) => {
          this.notifier.notify('error', msg);
        });
        console.error('Error validating invitation code:', error);
      });
  }

  /**
   * Store the invitation code and associated address in localStorage.
   * 
   * @param code - The invitation code to store
   * @param address - The associated address to store
   */
  storeInvitationData(code: string, address: string): void {
    try {
      localStorage.setItem('invitationCode', code);
      localStorage.setItem('invitationAddress', address);
    } catch (error) {
      console.error('Error storing invitation data in localStorage:', error);
    }
  }

  /**
   * Get the current attempt data from localStorage.
   */
  getAttemptData(): { attempts: number; lockedUntil: number | null } {
    const attempts = parseInt(localStorage.getItem('invitationAttempts') || '0', 10);
    const lockedUntil = parseInt(localStorage.getItem('lockedUntil') || '0', 10);
    return { attempts, lockedUntil: lockedUntil > 0 ? lockedUntil : null };
  }

  /**
   * Increment the attempt count and check if the user should be locked out.
   */
  incrementAttempts(): void {
    const { attempts } = this.getAttemptData();
    const newAttempts = attempts + 1;

    localStorage.setItem('invitationAttempts', newAttempts.toString());

    if (newAttempts >= this.maxAttempts) {
      // Lock the user out for 5 minutes
      const lockTime = new Date().getTime() + this.lockoutTime;
      localStorage.setItem('lockedUntil', lockTime.toString());

      this.translate.get('INVITATION.LOCKED_OUT').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
    }
  }

  /**
   * Reset the attempt count after a successful validation.
   */
  resetAttempts(): void {
    localStorage.setItem('invitationAttempts', '0');
    localStorage.setItem('lockedUntil', '0');
  }
}
