<!-- src/app/profile-item/profile-item.component.html -->

<div class="profile-item" *ngIf="data != null && data !== ''">
  <div class="profile-label">
    <i [class]="iconClass" aria-hidden="true"></i>
    <span>{{ label }}</span>
  </div>
  <hr>
  <p *ngIf="isString(data) && !isLink">{{ data }}</p>
  <p *ngIf="isString(data) && isLink">
    <a [href]="data" target="_blank">{{ data }}</a>
  </p>
  <p *ngIf="isNumber(data)">€{{ data | number:'1.2-2' }}</p>
</div>
