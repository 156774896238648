// src/app/auth/sign-up/sign-up.component.ts
import { Component, OnInit } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, sendEmailVerification } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ref, set, child, getDatabase, equalTo, get, orderByChild, query, remove } from 'firebase/database';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuard } from 'src/app/auth.guard';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  email: string = '';
  password: string = '';
  name: string = '';
  isDisclaimerChecked: boolean = false; // New variable for disclaimer
  invitationCode: string | null = null;
  invitationAddress: string | null = null;
  db = getDatabase();

  constructor(
    private auth: Auth,
    private router: Router,
    private notifier: NotifierService,
    private translate: TranslateService, // Inject TranslateService
    private authGuard: AuthGuard
    // Removed dbService injection
  ) {}

  ngOnInit(): void {
    // Retrieve the invitation code and address from localStorage
    this.invitationCode = this.getFromLocalStorage('invitationCode');
    this.invitationAddress = this.getFromLocalStorage('invitationAddress');

    if (!this.invitationCode || !this.invitationAddress) {
      // Redirect to invitation page if no valid invitation data
      this.router.navigate(['/auth/invitation']);
    }
  }

  // Method to check if the form is valid
  isFormValid(): boolean {
    return (
      this.email.trim() !== '' &&
      this.password.trim() !== '' &&
      this.name !== '' &&
      this.isDisclaimerChecked
    );
  }

  async signUp() {
    if (!this.isFormValid()) {
      this.translate.get('SIGN_UP.FORM_INVALID').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    try {
      // Create a new user with the provided email and password
      const userCredential = await createUserWithEmailAndPassword(
        this.auth,
        this.email.trim(),
        this.password.trim()
      );
      const user = userCredential.user;

      // Send email verification to the user
      await sendEmailVerification(user);

      // Notify user of successful sign-up and verification email sent
      this.translate.get('NOTIFICATIONS.SIGN_UP_SUCCESS').subscribe((res: string) => {
        this.notifier.notify('success', res);
      });

      // Now that the user is created, save user data in the database
      this.saveData(user, user.uid);

      // Remove the used invitation code and address from localStorage
      this.removeFromLocalStorage('invitationCode');
      this.removeFromLocalStorage('invitationAddress');

      // Optionally, remove the invitation from Firebase if needed
      if (this.invitationCode) {
        const invitationRef = ref(this.db, `invitations/${this.invitationCode}`);
        await set(invitationRef, null); // Delete the invitation code
      }

      // Redirect to the sign-in page after successful sign-up
      this.router.navigate(['/auth/sign-in']);
    } catch (error: any) {
      // Handle errors by calling the handleFirebaseError method
      this.handleFirebaseError(error);
    }
  }

  saveData(user: any, uid: string) {
    const accountRef = ref(this.db, 'Accounts');

    let newUser = {
      Email: user.email,
      Name: this.name,
      Location: this.invitationAddress,
      Status: "Active",
      UID: uid,
      AcceptsTerms: this.isDisclaimerChecked, // Store disclaimer acceptance
      EmailReminders: false // Store email reminder preference
    };

    set(child(accountRef, uid), newUser)
      .then(() => {
        this.deleteInvitationByCode();
        this.saveToAddress(uid, this.invitationAddress, this.name);
      })
      .catch(error => {
      });
  }

  saveToAddress(uid: string, location: string | null, name: string) {
    if (!location) {
      this.translate.get('SIGN_UP.INVALID_LOCATION').subscribe((res: string) => {
        this.notifier.notify('error', res);
      });
      return;
    }

    const addressRef = ref(this.db, `Addresses/${location}/${uid}`);
  
    // Prepare the data with name and status
    const data = {
      name: name,
      status: 'Active' // Default status to 'Active'
    };
  
    // Save name and status to the database
    set(addressRef, data)
      .then(() => {

      })
      .catch(error => {
        console.error('Error saving address data:', error);
      });
  }
  
  async deleteInvitationByCode(): Promise<void> {
    if (!this.invitationCode) {
      return;
    }

    try {
      // Reference to the 'invitations' node
      const invitationsRef = ref(this.db, 'invitations');

      // Create a query to find invitations with the matching code
      const invitationsQuery = query(
        invitationsRef,
        orderByChild('code'),
        equalTo(this.invitationCode)
      );

      // Execute the query
      const snapshot = await get(invitationsQuery);

      if (snapshot.exists()) {
        const invitations = snapshot.val();

        // Iterate over the matching invitations
        for (const key in invitations) {
          if (invitations.hasOwnProperty(key)) {
            const invitationKey = key;

            // Reference to the specific invitation
            const invitationToDeleteRef = ref(this.db, `invitations/${invitationKey}`);

            // Remove the invitation
            await remove(invitationToDeleteRef);
          }
        }
      } else {
      }
    } catch (error) {
    }
  }
  /**
   * Retrieve data from localStorage.
   * 
   * @param key - The key of the data to retrieve.
   * @returns string | null - The retrieved data or null if not found.
   */
  getFromLocalStorage(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      console.error(`Error retrieving ${key} from localStorage:`, error);
      return null;
    }
  }

  /**
   * Store data in localStorage.
   * 
   * @param key - The key under which the value is stored.
   * @param value - The value to store.
   */
  storeInLocalStorage(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.error(`Error storing ${key} in localStorage:`, error);
    }
  }

  /**
   * Remove data from localStorage.
   * 
   * @param key - The key of the data to remove.
   */
  removeFromLocalStorage(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing ${key} from localStorage:`, error);
    }
  }

  navigateToSignIn() {
    this.router.navigate(['/auth/sign-in']);
  }

  // Handle Firebase exceptions and convert them to user-friendly messages
  handleFirebaseError(error: any) {
    let errorMessage = 'An unknown error occurred. Please try again later.';

    switch (error.code) {
      case 'auth/email-already-in-use':
        errorMessage = this.translate.instant('AUTH.EMAIL_ALREADY_IN_USE');
        break;
      case 'auth/invalid-email':
        errorMessage = this.translate.instant('AUTH.INVALID_EMAIL');
        break;
      case 'auth/weak-password':
        errorMessage = this.translate.instant('AUTH.WEAK_PASSWORD');
        break;
      case 'auth/operation-not-allowed':
        errorMessage = this.translate.instant('AUTH.OPERATION_NOT_ALLOWED');
        break;
      case 'auth/network-request-failed':
        errorMessage = this.translate.instant('AUTH.NETWORK_ERROR');
        break;
      case 'auth/invalid-credential':
        errorMessage = this.translate.instant('AUTH.INVALID_CREDENTIAL');
        break;
      case 'auth/argument-error':
        errorMessage = this.translate.instant('AUTH.INVALID_EMAIL'); // Adjust as necessary
        break;
      case 'auth/timeout':
        errorMessage = this.translate.instant('AUTH.UNKNOWN_ERROR');
        break;
      case 'auth/quota-exceeded':
        errorMessage = this.translate.instant('AUTH.UNKNOWN_ERROR');
        break;
      case 'auth/user-disabled':
        errorMessage = this.translate.instant('AUTH.USER_DISABLED');
        break;
      case 'auth/invalid-password':
        errorMessage = this.translate.instant('AUTH.INVALID_PASSWORD');
        break;
      case 'auth/missing-email':
        errorMessage = this.translate.instant('AUTH.MISSING_EMAIL');
        break;
      case 'auth/internal-error':
        errorMessage = this.translate.instant('AUTH.UNKNOWN_ERROR');
        break;
      case 'auth/invalid-verification-code':
        errorMessage = this.translate.instant('AUTH.INVALID_VERIFICATION_CODE');
        break;
      case 'auth/invalid-verification-id':
        errorMessage = this.translate.instant('AUTH.INVALID_VERIFICATION_ID');
        break;
      default:
        errorMessage = error.message; // Optionally, display the original error message
        break;
    }

    // Notify the user of the error
    this.notifier.notify('error', errorMessage);
  }
}
