<!-- src/app/terms-and-conditions/terms-and-conditions.component.html -->

<div class="terms-container">
    <h1>{{ 'TERMS.title' | translate }}</h1>
  
    <section *ngFor="let section of termsSections">
      <h2>{{ section.heading | translate }}</h2>
      <p>{{ section.content | translate }}</p>
    </section>
  </div>
  