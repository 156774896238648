<!-- src/app/home/home.component.html -->
<div class="home-container">
  <!-- Include the ToolbarComponent -->
  <app-toolbar></app-toolbar>

  <!-- Main Content -->
  <div class="cards-container">
    <!-- Users Card -->
    <div class="card" (click)="navigateToGroup()">
      <img src="assets/group.png" alt="{{ 'HOME.GROUP.TITLE' | translate }}" class="card-icon" />
      <h3>{{ 'HOME.GROUP.TITLE' | translate }}</h3>
      <p class="card-description">
        {{ 'HOME.GROUP.DESCRIPTION' | translate }}
      </p>
    </div>
    <!-- **New Announcement Notifications Card** -->
    <div class="card" (click)="navigateToNotifications()">
      <img src="assets/mail-notification.png" alt="{{ 'HOME.ANNOUNCEMENT_NOTIFICATIONS.TITLE' | translate }}" class="card-icon" />
      <h3>{{ 'HOME.ANNOUNCEMENT_NOTIFICATIONS.TITLE' | translate }}</h3>
      <p class="card-description">
        {{ 'HOME.ANNOUNCEMENT_NOTIFICATIONS.DESCRIPTION' | translate }}
      </p>
    </div>
    <!-- Bill Calculator Card -->
    <div class="card" (click)="navigateToBillCalculator()">
      <img src="assets/calculator.png" alt="{{ 'HOME.BILL_CALCULATOR.TITLE' | translate }}" class="card-icon" />
      <h3>{{ 'HOME.BILL_CALCULATOR.TITLE' | translate }}</h3>
      <p class="card-description">
        {{ 'HOME.BILL_CALCULATOR.DESCRIPTION' | translate }}
      </p>
    </div>

    <!-- Clean/Bin Schedule Card -->
    <div class="card" (click)="navigateToCleanBinSchedule()">
      <img src="assets/project.png" alt="{{ 'HOME.CLEAN_BIN_SCHEDULE.TITLE' | translate }}" class="card-icon" />
      <h3>{{ 'HOME.CLEAN_BIN_SCHEDULE.TITLE' | translate }}</h3>
      <p class="card-description">
        {{ 'HOME.CLEAN_BIN_SCHEDULE.DESCRIPTION' | translate }}
      </p>
    </div>

    <!-- Receipt Tracker Card -->
    <div class="card" (click)="navigateToReceiptTracker()">
      <img src="assets/budget.png" alt="{{ 'HOME.RECEIPT_TRACKER.TITLE' | translate }}" class="card-icon" />
      <h3>{{ 'HOME.RECEIPT_TRACKER.TITLE' | translate }}</h3>
      <p class="card-description">
        {{ 'HOME.RECEIPT_TRACKER.DESCRIPTION' | translate }}
      </p>
    </div>

    <!-- Report Problems Card -->
    <div class="card" (click)="navigateToReportProblems()">
      <img src="assets/reports.png" alt="{{ 'HOME.REPORT_PROBLEMS.TITLE' | translate }}" class="card-icon" />
      <h3>{{ 'HOME.REPORT_PROBLEMS.TITLE' | translate }}</h3>
      <p class="card-description">
        {{ 'HOME.REPORT_PROBLEMS.DESCRIPTION' | translate }}
      </p>
    </div>
  </div>
</div>
