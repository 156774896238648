import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef } from '@angular/core';
import { getMessaging, getToken, onMessage, Messaging } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import { Database, ref, set, get, remove } from '@angular/fire/database';
import { Auth, authState, User } from '@angular/fire/auth';
import { environment } from 'environments/environment';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  private messaging: Messaging;
  currentMessage = new BehaviorSubject<any>(null);
  user: User | null = null;
  private notificationRef: ComponentRef<NotificationDialogComponent> | null = null;
  private messagingInitialized = false;

  constructor(
    private db: Database,
    private auth: Auth,
    private appRef: ApplicationRef,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {
    const app = initializeApp(environment.firebaseConfig);
    this.messaging = getMessaging(app);

    authState(this.auth).subscribe(async (user) => {
      this.user = user;
      if (user) {
        await this.requestPermission();
      }
    });
  }

  private setupOnMessageListener() {
    if (this.messagingInitialized) return; // Prevent multiple listeners

    onMessage(this.messaging, (payload) => {

      this.currentMessage.next(payload);

      // Display notification using data-only fields
      this.displayInAppNotification(payload.data);
    });

    this.messagingInitialized = true; // Mark listener as initialized
  }

  async requestPermission(): Promise<void> {
    try {
      const token = await getToken(this.messaging, {
        vapidKey: 'BPMUczE3Un2uOe1anKbGBQP2L0kDzF0u5kCn3H6NchfmJrYS4fW53_Tp6oSc21N5OhkS6WCTr2U8k3HNAeEp0JI',
      });

      if (token && this.user) {
        await this.saveToken(token);

        this.setupOnMessageListener(); // Initialize listener on successful token retrieval
      }
    } catch (error) {
      console.error('An error occurred while retrieving token:', error);
    }
  }

  private async saveToken(token: string): Promise<void> {
    if (!this.user) return;

    const tokensRef = ref(this.db, `Accounts/${this.user.uid}/fcmTokens`);
    const snapshot = await get(tokensRef);
    let tokensData = snapshot.exists() ? snapshot.val() : {};

    // Add the new token if it’s not already present
    if (!Object.values(tokensData).includes(token)) {
      const deviceId = `${uuidv4()}_${this.user.uid}`;
      tokensData[deviceId] = token;
      await set(tokensRef, tokensData);

    } else {

    }
  }

  async deleteToken(token: string): Promise<void> {
    if (!this.user) return;

    const tokensRef = ref(this.db, `Accounts/${this.user.uid}/fcmTokens`);
    try {
      const snapshot = await get(tokensRef);
      if (snapshot.exists()) {
        let tokensData = snapshot.val();

        // Filter out the token if it exists
        const tokenKey = Object.keys(tokensData).find(key => tokensData[key] === token);
        if (tokenKey) {
          delete tokensData[tokenKey];
          if (Object.keys(tokensData).length > 0) {
            await set(tokensRef, tokensData); // Update with remaining tokens
          } else {
            await remove(tokensRef); // Remove the tokens object if empty
          }

        } else {
 
        }
      }
    } catch (error) {
      console.error('Error removing FCM Token:', error);
    }
  }

  private displayInAppNotification(data: any): void {
    if (this.notificationRef) {
      this.removeNotificationComponent();
    }

    const factory = this.resolver.resolveComponentFactory(NotificationDialogComponent);
    this.notificationRef = factory.create(this.injector);

    this.appRef.attachView(this.notificationRef.hostView);
    const domElem = (this.notificationRef.hostView as any).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    // Use data fields instead of notification fields
    this.notificationRef.instance.title = data.title || 'Notification';
    this.notificationRef.instance.message = data.body || '';
    this.notificationRef.instance.type = data?.type || 'general';
    this.notificationRef.instance.author = data?.authorName || 'System';
    this.notificationRef.instance.timestamp = data?.timestamp || new Date().toISOString();
    this.notificationRef.instance.isVisible = true;

    this.notificationRef.instance.dismissEvent.subscribe(() => this.removeNotificationComponent());
  }

  private removeNotificationComponent(): void {
    if (this.notificationRef) {
      this.appRef.detachView(this.notificationRef.hostView);
      this.notificationRef.destroy();
      this.notificationRef = null;
    }
  }
}
