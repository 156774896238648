<div class="notification-overlay" *ngIf="isVisible">
  <div class="notification-dialog">
    <!-- Header with Icon Only -->
    <div class="header">
      <img [src]="icon" alt="Notification Icon" class="icon" />
    </div>

    <!-- Content Section -->
    <div class="content">
      <!-- Title -->
      <h1><u>{{ 'NOTIFICATIONS.TITLE' | translate }}</u></h1>
      <h4>{{title}}</h4>
      <!-- Message -->
      <p class="message">{{ message }}</p>

      <!-- Author and Timestamp -->
      <p class="author">{{ 'NOTIFICATIONS.AUTHOR' | translate }}: {{ author }}</p>
      <p class="timestamp">{{ 'NOTIFICATIONS.TIMESTAMP' | translate }}: {{ timestamp }}</p>
      
      <!-- Acknowledge Button -->
      <button class="acknowledge-button" (click)="dismiss()">
        {{ 'NOTIFICATIONS.ACKNOWLEDGE_BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
