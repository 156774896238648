<!-- list-of-tenants.component.html -->

<div class="tenant-list-container">
    <h2 class="text-center"><img src="assets/focus-group.png" class="log-icon"> {{ this.location }}</h2>
  
    <!-- 错误消息 -->
    <div *ngIf="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <hr>
    <br>

    <!-- 租户卡片 -->
    <div class="tenant-cards">
      <div class="tenant-card" *ngFor="let tenant of tenants">
        <div class="tenant-profile">
          <img [src]="tenant.profileUrl" alt="{{ tenant.name }}" (error)="onImageError($event)" loading="lazy" />
        </div>
        <div class="tenant-info">
          <h3>{{ tenant.name  }}</h3>
          <p>{{'STATUS' | translate}}: {{ tenant.status | translate }}</p>
        </div>
      </div>
    </div>
  
    <!-- 当没有活跃租户时显示消息 -->
    <div *ngIf="tenants.length === 0 && !errorMessage" class="no-tenants">
      <p>No active tenants found.</p>
    </div>
  </div>
  