// src/app/admin.guard.ts
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Auth, authState } from '@angular/fire/auth';
import { Database, ref, get } from '@angular/fire/database';
import { Observable, of, from } from 'rxjs';
import { map, take, switchMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private auth: Auth,
    private router: Router,
    private db: Database
  ) {}

  /**
   * Determines whether a route can be activated.
   * Grants access only if the user is an admin.
   *
   * @param route The activated route snapshot.
   * @param state The router state snapshot.
   * @returns An Observable that emits true if access is granted, otherwise a UrlTree for redirection.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return authState(this.auth).pipe(
      take(1), // Take the first emitted value and complete
      switchMap(user => {
        if (!user) {
          // User is not authenticated
          this.showAccessDenied();
          return of(this.router.createUrlTree(['/home']));
        }

        const uid = user.uid;

        // Check if user is an admin
        return this.isAdmin(uid).pipe(
          map(isAdmin => {
            if (isAdmin) {
              // User is an admin
              return true;
            } else {
              // User is not an admin
              this.showAccessDenied();
              return this.router.createUrlTree(['/home']);
            }
          }),
          catchError(error => {
            console.error('Error during admin verification:', error);
            this.showAccessDenied();
            return of(this.router.createUrlTree(['/home']));
          })
        );
      })
    );
  }

  /**
   * Checks if the user is an admin by verifying their UID in the RTDB.
   *
   * @param uid The user's UID.
   * @returns An Observable<boolean> indicating if the user is an admin.
   */
  public isAdmin(uid: string | null | undefined): Observable<boolean> {
    if (!uid) return of(false);

    const adminRef = ref(this.db, `Permission/admin/${uid}`);

    return from(get(adminRef)).pipe(
      map(snapshot => snapshot.exists()),
      catchError(error => {
        console.error('Error checking admin status:', error);
        return of(false);
      })
    );
  }

  /**
   * Displays an access denied alert to the user.
   */
  private showAccessDenied(): void {
    alert('Access denied. Admins only.');
    // Alternatively, use a notification service for better UX:
    // this.notifier.notify('error', 'Access denied. Admins only.');
  }
}
