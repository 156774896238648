import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import {  User, signOut } from "firebase/auth";
import { getDatabase, ref, set, update } from "firebase/database";
import { Subscription } from "rxjs";
import { MessagingService } from "../services/messaging.service";
import { Auth } from "@angular/fire/auth";

// src/app/home/home.component.ts
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  private messageSubscription: Subscription | null = null;
  db = getDatabase(); // Initialize Firebase Realtime Database

  constructor(
    private auth: Auth,
    private router: Router,
    public messagingService: MessagingService
  ) {}

  ngOnInit() {
    // Initial PWA status check and update
    this.updatePWAStatus(this.isInStandaloneMode());

    // Listen for the 'appinstalled' event to update PWA status
    window.addEventListener('appinstalled', () => {
      this.updatePWAStatus(true);
    });

    // Subscribe to messages and log them
    this.messageSubscription = this.messagingService.currentMessage.subscribe((message: any) => {
      if (message) {

      }
    });
  }

  /**
   * Checks if the app is running in standalone mode (as a PWA).
   * @returns {boolean} True if in standalone mode, else false.
   */
  isInStandaloneMode(): boolean {
    return (
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any).standalone === true
    );
  }

  /**
   * Updates the PWA installation status in Firebase Realtime Database.
   * @param {boolean} status - True if PWA is installed, else false.
   */
  /**
   * Updates the PWA installation status and additional data in Firebase Realtime Database without overwriting existing data.
   * @param {boolean} status - True if PWA is installed, else false.
   */
  async updatePWAStatus(status: boolean): Promise<void> {
  const user: User | null = this.auth.currentUser;
  if (user) {
    // Get the current time in the desired format
    const now = new Date();
    const formattedDate = now.toISOString().replace('T', ' ').substring(0, 19); // Format: 2024-11-16 12:48:03

    // Get device info from navigator
    const deviceInfo = window.navigator.userAgent || 'Unknown Device';

    // Fetch IP address using a public IP API
    let ipAddress = 'Unknown IP';
    try {
      const response = await fetch('https://api64.ipify.org?format=json'); // Using ipify API for IP fetching
      const data = await response.json();
      ipAddress = data.ip || 'Unknown IP';
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }

    // Firebase reference path
    const userRef = ref(this.db, `Accounts/${user.uid}`);

    // Data to update
    const updateData = {
      PWA: status,
      lastLoginDate: formattedDate,
      deviceInfo,
      ipAddress,
    };

    try {
      // Use update to add or modify the specified fields without overwriting the entire node
      await update(userRef, updateData);

    } catch (error) {
      console.error('Error updating user data:', error);
    }
  } else {
    console.error('User is not authenticated. Cannot update user data.');
  }
}


  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  signOut() {
    signOut(this.auth)
      .then(() => this.router.navigate(['/auth/sign-in']))
      .catch((err: { message: string; }) => alert('Error during sign-out: ' + err.message));
  }

  navigateHome() {
    this.router.navigate([this.auth.currentUser ? '/home' : '/auth/sign-in'])
      .catch((err: any) => console.error('Navigation error:', err));
  }

  navigateToProfile() {
    this.router.navigate(['/profile']).catch((err: any) => console.error('Navigation error:', err));
  }

  navigateToBillCalculator() {
    this.router.navigate(['/bill-calculator']).catch((err: any) => console.error('Navigation error:', err));
  }

  navigateToCleanBinSchedule() {
    this.router.navigate(['/clean-bin-schedule']).catch((err: any) => console.error('Navigation error:', err));
  }

  navigateToReceiptTracker() {
    this.router.navigate(['/receipt-tracker']).catch((err: any) => console.error('Navigation error:', err));
  }

  navigateToReportProblems() {
    this.router.navigate(['/report-problems']).catch((err: any) => console.error('Navigation error:', err));
  }

  navigateToGroup() {
    this.router.navigate(['/group']).catch((err: any) => console.error('Navigation error:', err));
  }

  /** *New Navigation Method** */
  navigateToNotifications() {
    this.router.navigate(['/notifications']).catch((err: any) => console.error('Navigation error:', err));
  }
}
