import { Component } from '@angular/core';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core'; // Import TranslateService

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  email: string = ''; // Store the user's email
  emailSent: boolean = false; // Track if the reset email was sent

  constructor(
    private auth: Auth,
    private router: Router,
    private notifier: NotifierService,
    private translate: TranslateService
  ) {}

  // Send a password reset email
  sendResetLink() {
    sendPasswordResetEmail(this.auth, this.email.trim())
      .then(() => {
        this.emailSent = true; // Show success message
        this.translate.get('FORGOT_PASSWORD.EMAIL_SENT_SUCCESS').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });
      })
      .catch((error) => {
        this.handleFirebaseError(error);
      });
  }

  // Handle Firebase errors
  handleFirebaseError(error: any) {
    let errorMessage = 'An unknown error occurred. Please try again later.';
    switch (error.code) {
      case 'auth/user-not-found':
        errorMessage = this.translate.instant('FORGOT_PASSWORD.USER_NOT_FOUND');
        break;
      case 'auth/invalid-email':
        errorMessage = this.translate.instant('FORGOT_PASSWORD.INVALID_EMAIL');
        break;
      default:
        errorMessage = this.translate.instant('FORGOT_PASSWORD.UNKNOWN_ERROR');
        break;
    }
    this.notifier.notify('error', errorMessage);
  }

  // Method to navigate back to the sign-in page
  navigateToSignIn() {
    this.router.navigate(['/auth/sign-in']);
  }
}
