import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Auth, User, onAuthStateChanged } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User | null>(null);
  currentUser$ = this.currentUserSubject.asObservable();

  constructor(private auth: Auth) {
    this.initializeAuthListener();
  }

  private initializeAuthListener() {
    onAuthStateChanged(this.auth, (user) => {
      this.currentUserSubject.next(user);
    });
  }

  // Manual sign-in or updates can trigger this method to broadcast the user.
  updateAuthState(user: User | null) {
    this.currentUserSubject.next(user);
  }
}
