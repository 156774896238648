<div class="auth-container">
    <div class="auth-card">
      <div class="auth-header">
        <img src="assets/forgot-password.png" alt="{{ 'FORGOT_PASSWORD.TITLE' | translate }}" class="auth-icon" />
        <h2>{{ 'FORGOT_PASSWORD.TITLE' | translate }}</h2>
      </div>
      
      <div class="auth-form">
        <input
          type="email"
          [(ngModel)]="email"
          placeholder="{{ 'FORGOT_PASSWORD.EMAIL_PLACEHOLDER' | translate }}"
          class="auth-input"
        />
        <button (click)="sendResetLink()" class="auth-button">{{ 'FORGOT_PASSWORD.SEND_RESET_LINK' | translate }}</button>
      </div>
  
      <p *ngIf="emailSent" class="email-sent-message">{{ 'FORGOT_PASSWORD.EMAIL_SENT_SUCCESS' | translate }}</p>
  
      <div class="auth-footer">
        <button (click)="navigateToSignIn()" class="secondary-button">{{ 'FORGOT_PASSWORD.BACK_TO_SIGN_IN' | translate }}</button>
      </div>
    </div>
  </div>
  