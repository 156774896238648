import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.css']
})
export class NotificationDialogComponent implements OnInit {
  @Input() isVisible: boolean = false;
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() type: 'general' | 'emergency' | 'maintenance' | 'update' = 'general';
  @Input() author: string = ''; // New input for the author name
  @Input() timestamp: string = ''; // New input for the timestamp
  @Output() dismissEvent = new EventEmitter<void>();

  icon: string = ''; // Icon property to dynamically set the icon

  ngOnInit(): void {
    this.setIcon();
  }

  setIcon() {
    switch (this.type) {
      case 'emergency':
        this.icon = 'assets/emergency-notification.png';
        break;
      case 'general':
        this.icon = 'assets/notification.png';
        break;
      case 'maintenance':
        this.icon = 'assets/maintenance.png';
        break;
      case 'update':
        this.icon = 'assets/status.png';
        break;
      default:
        this.icon = 'assets/notification.png';
    }
  }

  dismiss() {
    this.isVisible = false;
    this.dismissEvent.emit();
  }
}
