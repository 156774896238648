
<!-- src/app/report-problems/report-problems.component.html -->
<div class="report-container">
  <div class="report-card">
    <h2>{{ 'REPORT_PROBLEMS.TITLE' | translate }}</h2>
    <form (ngSubmit)="onSubmit()" [formGroup]="reportForm">
      <div class="form-group">
        <label for="issueType">{{ 'REPORT_PROBLEMS.ISSUE_TYPE' | translate }}</label>
        <select id="issueType" formControlName="issueType" required>
          <option value="" disabled selected>{{ 'REPORT_PROBLEMS.SELECT_ISSUE' | translate }}</option>
          <option value="broken_light">{{ 'REPORT_PROBLEMS.BROKEN_LIGHT' | translate }}</option>
          <option value="noise">{{ 'REPORT_PROBLEMS.NOISE' | translate }}</option>
          <option value="leak">{{ 'REPORT_PROBLEMS.LEAK' | translate }}</option>
          <option value="other">{{ 'REPORT_PROBLEMS.OTHER' | translate }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="description">{{ 'REPORT_PROBLEMS.DESCRIPTION_LABEL' | translate }}</label>
        <textarea id="description" formControlName="description" rows="5" required></textarea>
      </div>

      <button type="submit" [disabled]="reportForm.invalid">{{ 'REPORT_PROBLEMS.SUBMIT' | translate }}</button>
    </form>
  </div>
</div>
