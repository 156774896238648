// src/app/report-problems/report-problems.component.ts
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Database, ref, push } from '@angular/fire/database';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier'; // Import NotifierService
import { TranslateService } from '@ngx-translate/core'; // Import TranslateService
import { Auth, authState } from '@angular/fire/auth'; // Import Auth and authState
import { User } from 'firebase/auth'; // Import User interface

@Component({
  selector: 'app-report-problems',
  templateUrl: './report-problems.component.html',
  styleUrls: ['./report-problems.component.css']
})
export class ReportProblemsComponent implements OnInit {
  reportForm: FormGroup;
  currentUser: User | null = null; // To store the current user
  isSubmitting: boolean = false; // For handling form submission state

  constructor(
    private fb: FormBuilder,
    private db: Database, // Inject Realtime Database
    private router: Router,
    private notifier: NotifierService, // Inject NotifierService
    private translate: TranslateService, // Inject TranslateService
    private auth: Auth // Inject Auth service
  ) {
    this.reportForm = this.fb.group({
      issueType: ['', Validators.required],
      description: ['', [Validators.required, Validators.maxLength(500)]]
    });
  }

  ngOnInit(): void {
    // Subscribe to authentication state
    authState(this.auth).subscribe(user => {
      if (user) {
        this.currentUser = user;
      } else {
        this.currentUser = null;
        // Optionally, redirect unauthenticated users to login
        this.notifier.notify('error', this.translate.instant('REPORT_PROBLEMS.NOT_AUTHENTICATED'));
        this.router.navigate(['/login']); // Ensure you have a login route
      }
    });
  }

  async onSubmit() {
    if (this.reportForm.valid && this.currentUser) {
      this.isSubmitting = true; // Start loading
      const reportData = this.reportForm.value;
      // Add timestamp and user info
      reportData.timestamp = new Date().toISOString();
      reportData.userId = this.currentUser.uid;
      reportData.userEmail = this.currentUser.email || 'Anonymous';

      try {
        const reportsRef = ref(this.db, 'reports');
        await push(reportsRef, reportData);
        // Show success notification
        const successMessage = this.translate.instant('REPORT_PROBLEMS.SUCCESS_MESSAGE');
        this.notifier.notify('success', successMessage);
        this.router.navigate(['/home']);
      } catch (error) {
        console.error('Error submitting report:', error);
        // Show error notification
        const errorMessage = this.translate.instant('REPORT_PROBLEMS.ERROR_MESSAGE');
        this.notifier.notify('error', errorMessage);
      } finally {
        this.isSubmitting = false; // End loading
      }
    } else if (!this.currentUser) {
      // Handle unauthenticated user submission attempt
      const authErrorMessage = this.translate.instant('REPORT_PROBLEMS.NOT_AUTHENTICATED');
      this.notifier.notify('error', authErrorMessage);
      this.router.navigate(['/login']); // Ensure you have a login route
    }
  }
}
