// list-of-tenants.component.ts

import { Component, OnInit, OnDestroy } from '@angular/core';
import { authState, Auth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { getDatabase, ref, get, DatabaseReference, DataSnapshot } from 'firebase/database';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface Tenant {
  userId: string;
  name: string;
  status: string;
  profileUrl: string;
}

@Component({
  selector: 'app-list-of-tenants',
  templateUrl: './list-of-tenants.component.html',
  styleUrls: ['./list-of-tenants.component.css']
})
export class ListOfTenantsComponent implements OnInit, OnDestroy {
  location: string = ''; 
  tenants: Tenant[] = [];
  private unsubscribe$ = new Subject<void>();
  errorMessage: string = '';
  db = getDatabase();

  constructor(
    private auth: Auth,
    private notifier: NotifierService, 
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.fetchUserAddress();
  }

  /**
   * 获取当前用户的地址
   */
  fetchUserAddress(): void {
    authState(this.auth)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        if (user && user.uid) {
          const userAddressRef = ref(this.db, `/Accounts/${user.uid}/Location`);
          get(userAddressRef).then(snapshot => {
            if (snapshot.exists()) {
              this.location = snapshot.val();
              this.fetchActiveTenants(this.location);
            } else {
              this.translate.get('NOTIFICATIONS.ADDRESS_NOT_FOUND').subscribe((msg: string) => {
                this.notifier.notify('error', msg);
              });
            }
          }).catch(error => {
            this.translate.get('NOTIFICATIONS.FETCH_ADDRESS_ERROR').subscribe((msg: string) => {
              this.notifier.notify('error', msg);
            });
            console.error('Error fetching address:', error);
          });
        }
      });
  }

  /**
   * 获取所有租户并筛选出活跃的租户
   * @param location 地址
   */
  fetchActiveTenants(location: string): void {
    const tenantsRef: DatabaseReference = ref(this.db, `Addresses/${location}`);

    get(tenantsRef).then(snapshot => {
      if (snapshot.exists()) {
        const tenantsData = snapshot.val();

        if (typeof tenantsData === 'object' && tenantsData !== null) {
          this.tenants = Object.keys(tenantsData)
            .filter(userId => tenantsData[userId].status === 'Active') // 筛选活跃用户
            .map(userId => {
              const data = tenantsData[userId];
              return {
                userId, // 确保 userId 只被赋值一次
                name: data.name || 'Unnamed User',
                status: data.status || 'Inactive',
                profileUrl: data.profileUrl || 'assets/user.png'
              } as Tenant;
            });
        } else {
          this.tenants = [];
        }
      } else {
        this.tenants = [];
      }
    }).catch(error => {
      this.translate.get('NOTIFICATIONS.FETCH_ADDRESS_ERROR').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
      console.error('Error fetching tenants:', error);
    });
  }

  /**
   * 处理图像加载错误，使用默认图片
   * @param event 图像加载错误事件
   */
  onImageError(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = 'assets/user.png';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
