import { Component, OnInit } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from './services/cookie-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Home Management';
  cookieAccepted: boolean = false;
  showCookie: boolean = true;
  isLoading$ = new BehaviorSubject<boolean>(true); // Loading state observable
  workerExist = false;

  constructor(
    private auth: Auth,
    private translate: TranslateService,
    private cookieService: CookieService
  ) {
    // Unregister old service workers (useful in development)
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          if (
            registration.active &&
            registration.scope ===
              'https://rentsmanage.com/firebase-cloud-messaging-push-scope'
          ) {
            //registration.unregister();
            //console.log('Unregistered old service worker:', registration.scope);
            this.workerExist = true;
          }
        });
      });

      if (!this.workerExist) {
        // Register Firebase messaging service worker for background notifications
        navigator.serviceWorker
          .register('/firebase-messaging-sw.js')
          .then((registration) => {})
          .catch((error) => {
            console.error(
              'Firebase Messaging Service Worker registration failed:',
              error
            );
          });
      }
    }
  }

  acceptCookies() {
    this.cookieService.setCookie('cookie_accepted', 'true', 365);
    this.cookieAccepted = true;
    this.showCookie = false;
  }

  necessaryCookies() {
    this.cookieService.setCookie('cookie_accepted', 'necessary', 365);
    this.cookieAccepted = true;
    this.showCookie = false;
  }

  ngOnInit(): void {
    // Check if user has accepted cookies
    const cookieAcceptedValue = this.cookieService.getCookie('cookie_accepted');
    if (cookieAcceptedValue) {
      this.cookieAccepted = true;
      this.showCookie = false;
    } else {
      this.cookieAccepted = false;
      this.showCookie = true;
    }

    // Fetch language preference from localStorage if cookies are accepted
    const storedLanguage = this.getStoredLanguage();

    // Define supported languages
    const supportedLanguages = ['en', 'zh'];

    // Validate the stored language; if invalid or not present, default to 'zh'
    const defaultLanguage = 'zh';
    const languageToUse = supportedLanguages.includes(storedLanguage || '')
      ? storedLanguage!
      : defaultLanguage;

    // Set the default language and use the selected language
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(languageToUse);

    // Monitor the authentication state
    onAuthStateChanged(this.auth, (user) => {
      // Authentication state has been determined
      this.isLoading$.next(false);
    });

    // Check for updates and reload only when there's a new version available
    // if (this.updates.isEnabled) {
    //   this.updates.versionUpdates.subscribe(event => {
    //     if (event.type === 'VERSION_READY') {
    //       this.activateNewVersion();
    //     }
    //   });
    // }
  }

  /**
   * Method to switch languages.
   * This method can be called from the template when the user selects a different language.
   * It updates both the TranslateService and localStorage.
   *
   * @param lang - The language code to switch to (e.g., 'en', 'zh')
   */
  switchLanguage(lang: string): void {
    this.translate.use(lang);
    this.storeLanguage(lang); // Store the selected language in localStorage
  }

  /**
   * Store the selected language in localStorage if cookies are accepted.
   *
   * @param lang - The language code to store (e.g., 'en', 'zh')
   */
  storeLanguage(lang: string): void {
    if (this.cookieAccepted) {
      try {
        localStorage.setItem('language', lang);
      } catch (error) {
        console.error('Failed to store language in localStorage', error);
      }
    } else {
      console.warn(
        'Cookies not accepted. Language preference not stored in localStorage.'
      );
    }
  }

  /**
   * Retrieve the stored language from localStorage if cookies are accepted.
   *
   * @returns string | null - The stored language or null if not found or cookies not accepted.
   */
  getStoredLanguage(): string | null {
    if (this.cookieAccepted) {
      try {
        return localStorage.getItem('language');
      } catch (error) {
        console.error('Failed to retrieve language from localStorage', error);
        return null;
      }
    } else {
      console.warn(
        'Cookies not accepted. Cannot retrieve language preference from localStorage.'
      );
      return null;
    }
  }

  /**
   * Method to activate the new version of the app by reloading the page.
   */
  activateNewVersion(): void {
    //this.updates.activateUpdate().then(() => document.location.reload());
  }
}
