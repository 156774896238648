<div class="profile-container">

  <div class="profile-content">
    <h2>{{ 'PROFILE.TITLE' | translate }}</h2>
    <!-- Show loading indicator while the profile image is uploading -->
    <div *ngIf="isUploading" class="loading-overlay">
      <div class="loading-spinner">
        <i class="fas fa-spinner fa-spin"></i> {{ 'PROFILE.UPLOADING_IMAGE' | translate }}
      </div>
    </div>
    <!-- Basic User Information Card -->
    <div class="card fade-in">
      <div class="card-header">
        <!-- Default Placeholder if No Profile Image Exists -->
        <i *ngIf="!userProfileData?.profileUrl; else customProfile" class="fas fa-user-circle fa-2x"></i>
        
        <!-- Custom Profile Image if it Exists -->
        <ng-template #customProfile>
          <img [src]="userProfileData?.profileUrl" alt="Profile Icon" class="profile-icon" />
        </ng-template>
        <h3>{{ 'PROFILE.BASIC_INFO' | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="profile-grid">
           <!-- 新增的角色显示项 -->
           <app-profile-item
            iconClass="fas fa-user-shield"
            label="{{ 'PROFILE.ROLE_LABEL' | translate }}"
            [data]="getTranslatedRole(userProfileData?.role) | translate">
          </app-profile-item>
          <!-- Email (Assumed to Always Exist) -->
          <app-profile-item
            iconClass="fas fa-envelope"
            label="{{ 'PROFILE.EMAIL_LABEL' | translate }}"
            [data]="user?.email ?? null">
          </app-profile-item>

          <!-- Location (Displayed Only If Exists) -->
          <app-profile-item
            iconClass="fas fa-map-marker-alt"
            label="{{ 'PROFILE.LOCATION_LABEL' | translate }}"
            [data]="userProfileData?.Location ?? null">
          </app-profile-item>

          <!-- Status (Displayed Only If Exists) -->
          <app-profile-item
            iconClass="fas fa-user-tag"
            label="{{ 'PROFILE.STATUS_LABEL' | translate }}"
            [data]="userProfileData?.Status ?? null">
          </app-profile-item>
        </div>
      </div>
    </div>

    <!-- Upload Profile Image Card -->
    <div class="card fade-in">
      <div class="card-header">
        <img src="assets/camera.png" alt="camera Icon" class="icon-label me-2" />
        <h3>{{ 'PROFILE.UPLOAD_PROFILE_ICON' | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="profile-grid">
          <!-- Profile Image Preview -->
          <div class="profile-item">
            <div class="profile-label">
              <i class="fas fa-image"></i>
              <span>{{ 'PROFILE.CURRENT_PROFILE_IMAGE' | translate }}</span>
            </div>
            <div class="profile-image-preview">
              <img [src]="userProfileData?.profileUrl || 'assets/user.png'" alt="Profile Image" class="profile-icon-preview" />
            </div>
          </div>

          <!-- Upload Profile Image Section -->
          <div class="profile-item upload-item">
            <div class="profile-label">
              <i class="fas fa-upload"></i>
              <span>{{ 'PROFILE.UPLOAD_NEW_IMAGE' | translate }}</span>
            </div>
            <div class="upload-section">
              <label for="file-upload" class="upload-button">{{ 'PROFILE.CHOOSE_FILE' | translate }}</label>
              <input id="file-upload" type="file" (change)="onFileSelected($event)" accept="image/*" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Additional User Data Card -->
    <div class="card fade-in" *ngIf="userProfileData">
      <div class="card-header">
        <img src="assets/info.png" alt="info Icon" class="icon-label me-2" />
        <h3>{{ 'PROFILE.ADDITIONAL_INFO' | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="profile-grid">
          <app-profile-item
            iconClass="fas fa-money-bill-wave"
            label="{{ 'PROFILE.DEPOSIT_AMOUNT' | translate }}"
            [data]="userProfileData.depositAmount ?? null">
          </app-profile-item>

          <app-profile-item
            iconClass="fas fa-calendar-alt"
            label="{{ 'PROFILE.CONTRACT_START_DATE' | translate }}"
            [data]="userProfileData.contractStartDate ? (userProfileData.contractStartDate | date:'longDate') : null">
          </app-profile-item>

          <app-profile-item
            iconClass="fas fa-calendar-check"
            label="{{ 'PROFILE.CONTRACT_EXPIRE_DATE' | translate }}"
            [data]="userProfileData.contractExpireDate ? (userProfileData.contractExpireDate | date:'longDate') : null">
          </app-profile-item>

          <app-profile-item
            iconClass="fas fa-credit-card"
            label="{{ 'PROFILE.NEXT_PAYMENT_DATE' | translate }}"
            [data]="userProfileData.nextPaymentDate ? (userProfileData.nextPaymentDate | date:'longDate') : null">
          </app-profile-item>

          <app-profile-item
            iconClass="fas fa-coins"
            label="{{ 'PROFILE.NEXT_PAYMENT_AMOUNT' | translate }}"
            [data]="userProfileData.nextPaymentAmount ?? null">
          </app-profile-item>

        </div>
      </div>
    </div>

    <!-- Loading Indicator -->
    <div *ngIf="isLoading" class="loading">
      <i class="fas fa-spinner fa-spin"></i> {{ 'PROFILE.LOADING' | translate }}
    </div>

    <!-- No Additional Data Message -->
    <div *ngIf="!isLoading && !userProfileData" class="no-data">
      {{ 'PROFILE.NO_DATA_AVAILABLE' | translate }}
    </div>
  </div>
</div>
