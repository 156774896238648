import { Component, OnInit, ViewChild } from '@angular/core';
import { 
  Database, 
  ref, 
  get, 
  set, 
  getDatabase, 
  push, 
  onValue
} from 'firebase/database'; 
import { Auth, authState } from '@angular/fire/auth';
import { 
  getStorage, 
  ref as storageRef, 
  getDownloadURL, 
  uploadBytesResumable, 
  deleteObject 
} from 'firebase/storage'; // Firebase Storage
import { ChartOptions, ChartData, registerables, Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin
import { BaseChartDirective } from 'ng2-charts';
import { NotifierService } from 'angular-notifier'; // Import NotifierService
import { TranslateService } from '@ngx-translate/core'; // Import TranslateService
import imageCompression from 'browser-image-compression'; // Import image compression library

interface Receipt {
  userName: string;
  itemType: string;
  price: number;
  date: string;
  description?: string;
  imageUrl?: string;
  status?: string; // Add this line
  type: string;
  uid: string;
}

interface Transfer {
  userName: string;
  amount: number;
  proofImageUrl?: string;
  date?: string;
  status?: string; // Add this line
  type: string;
  uid: string;
}

interface MonthlyExpense {
  name: string;
  itemType: string;
  date: string;
  price: number;
  imageUrl?: string;
  proofImageUrl?: string;
  expenseType?: 'receipt' | 'transfer';
}

interface Month {
  name: string;
  expenses: MonthlyExpense[];
  isExpanded: boolean;
}

interface DoughnutChartData extends ChartData<'doughnut'> {
  labels: string[];
  datasets: [
    {
      data: number[];
      backgroundColor: string[];
    }
  ];
}

@Component({
  selector: 'app-receipt-tracker',
  templateUrl: './receipt-tracker.component.html',
  styleUrls: ['./receipt-tracker.component.css']
})
export class ReceiptTrackerComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  transfer: Transfer = {
    userName: '',
    amount: 0,
    proofImageUrl: '',
    type: "Transfer",
    uid: ''
  };
  availableBalance: number = 0;
  totalSpentThisYear: number = 0;
  transferAmount: number = 0;
  userLocation: string = '';
  uid: string = '';
  userName: string = 'Admin';
  totalIncomeThisYear: number = 0;
  
  receipt: Receipt = {
    userName: 'Admin',
    itemType: '',
    price: 0,
    date: '',
    description: '',
    imageUrl: '',
    type: 'Receipt',
    uid: ''
  };

  // Temporary variables to store selected files
  receiptFile: File | null = null;
  transferProofFile: File | null = null;

  year: number = new Date().getFullYear();
  itemTypes: string[] = ['General', 'Cleaning Product', 'Others'];

  storage: any;

  revenueLabels: string[] = ['General', 'Cleaning Product', 'Others', 'Transfers'];
  revenueData: DoughnutChartData = {
    labels: this.revenueLabels,
    datasets: [
      {
        data: [0, 0, 0, 0],
        backgroundColor: ['#ff5722', '#ffc107', '#03a9f4', '#4caf50']
      }
    ]
  };

  chartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    plugins: {
      legend: { position: 'right' },
      datalabels: {
        display: false,
        formatter: (value, context) => {
          const data = context.chart.data.datasets[0].data as number[];
          const sum = data.reduce((a, b) => a + b, 0);
          return sum === 0 ? '' : value;
        },
        color: '#000',
        font: { weight: 'bold', size: 16 },
        anchor: 'end',
        align: 'end'
      }
    },
    animation: { duration: 1000, easing: 'easeOutBounce' }
  };

  months: Month[] = [];

  uploadProgress: number | null = null;
  uploadTransferProgress: number | null = null;

  receiptFilePath: string | null = null;
  transferFilePath: string | null = null;
  userRole: string = 'Normal User';
  pendingItems: Array<{ key: string; data: Receipt | Transfer }> = [];
  db = getDatabase();
  
  constructor(
    private auth: Auth, 
    private notifier: NotifierService, 
    private translate: TranslateService
  ) { 
    Chart.register(...registerables);
    Chart.register(ChartDataLabels);
    this.storage = getStorage();
  }

  ngOnInit(): void {
    this.fetchUserData();
  }

  fetchUserData() {
    authState(this.auth).subscribe(user => {
      if (user && user.uid) {
        this.uid = user.uid;
        const userRef = ref(this.db, `/Accounts/${user.uid}`);
        
        get(userRef).then(snapshot => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            this.userName = userData.Name || 'Admin';
            this.userLocation = userData.Location || '';
            this.userRole = userData.role || 'Normal User';
            if (this.userLocation) {
              this.fetchAvailableBalance();
              this.fetchReceiptsAndTransfers();

              // Fetch pending items if user is Financial Management Admin
              if (this.userRole === 'Financial Management Admin') {
                this.fetchPendingItems();
              }

            } else {
              this.showNotification('error', 'USER_ADDRESS_NOT_FOUND');
            }
          } else {
            this.showNotification('error', 'USER_DATA_NOT_FOUND');
          }
        }).catch(error => {
          this.showNotification('error', 'ERROR_FETCHING_USER_DATA');
        });
      }
    });
  }
  isReceipt(data: Receipt | Transfer): data is Receipt {
    return data.type === 'Receipt';
  }
  
  isTransfer(data: Receipt | Transfer): data is Transfer {
    return data.type === 'Transfer';
  }
  
  private showNotification(type: 'success' | 'error', key: string): void {
    this.translate.get(`NOTIFICATIONS.${key}`).subscribe((res: string) => {
      this.notifier.notify(type, res);
    });
  }

  fetchPendingItems() {
    if (!this.userLocation) return;
  
    const pendingRef = ref(this.db, `finances/${this.userLocation}/Pending`);
    onValue(pendingRef, (snapshot) => {
      if (snapshot.exists()) {
        const pendingData = snapshot.val();
        this.pendingItems = Object.keys(pendingData).map(key => ({
          key,
          data: pendingData[key] as Receipt | Transfer
        }));
      } else {
        this.pendingItems = [];
      }
    }, (error) => {
      this.showNotification('error', 'ERROR_FETCHING_PENDING_ITEMS');
    });
  }
  
  approveItem(key: string, data: Receipt | Transfer) {
    if (!this.userLocation) return;
  
    const pendingRef = ref(this.db, `finances/${this.userLocation}/Pending/${key}`);
  
    if (data.type === 'Receipt') {
      // Approving a Receipt
      const receiptsRef = ref(this.db, `finances/${this.userLocation}/receipts/${key}`);
      const approvedReceipt = { ...(data as Receipt), status: 'approved' };
  
      set(receiptsRef, approvedReceipt)
        .then(() => {
          // Decrease available balance
          this.decreaseAvailableBalance(approvedReceipt.price);
          // Remove from Pending
          set(pendingRef, null);
          this.showNotification('success', 'RECEIPT_APPROVED_SUCCESS');
          this.fetchPendingItems();
          this.fetchReceiptsAndTransfers();
        })
        .catch(error => {
          this.showNotification('error', 'ERROR_APPROVING_RECEIPT');
        });
    } else if (data.type === 'Transfer') {
      // Approving a Transfer
      const transfersRef = ref(this.db, `finances/${this.userLocation}/transferredFunds/${key}`);
      const approvedTransfer = { ...(data as Transfer), status: 'approved' };
  
      set(transfersRef, approvedTransfer)
        .then(() => {
          // Increase available balance
          this.updateAvailableBalance(approvedTransfer.amount);
          // Remove from Pending
          set(pendingRef, null);
          this.showNotification('success', 'TRANSFER_APPROVED_SUCCESS');
          this.fetchPendingItems();
          this.fetchReceiptsAndTransfers();
        })
        .catch(error => {
          this.showNotification('error', 'ERROR_APPROVING_TRANSFER');
        });
    }
  }
  
  rejectItem(key: string, data: Receipt | Transfer) {
    if (!this.userLocation) return;
  
    const pendingRef = ref(this.db, `finances/${this.userLocation}/Pending/${key}`);
  
    set(pendingRef, null)
      .then(() => {
        // Optionally delete associated image from storage
        if ('imageUrl' in data && data.imageUrl) {
          const imageRef = storageRef(this.storage, data.imageUrl);
          deleteObject(imageRef).catch(error => {
            console.error('Error deleting image:', error);
          });
        }
        if ('proofImageUrl' in data && data.proofImageUrl) {
          const proofImageRef = storageRef(this.storage, data.proofImageUrl);
          deleteObject(proofImageRef).catch(error => {
            console.error('Error deleting proof image:', error);
          });
        }
        this.showNotification('success', 'ITEM_REJECTED_SUCCESS');
        this.fetchPendingItems();
      })
      .catch(error => {
        this.showNotification('error', 'ERROR_REJECTING_ITEM');
      });
  }
  
  fetchAvailableBalance() {
    if (!this.userLocation) return;
    
    const balanceRef = ref(this.db, `finances/${this.userLocation}/availableBalance`);
    get(balanceRef).then((snapshot) => {
      if (snapshot.exists()) {
        const balanceData = snapshot.val();
        this.availableBalance = typeof balanceData === 'number' ? balanceData : 0;
      } else {
        this.availableBalance = 0;
      }
    }).catch((error) => {
      this.showNotification('error', 'ERROR_FETCHING_AVAILABLE_BALANCE');
    });
  }

  async fetchReceiptsAndTransfers() {
    if (!this.userLocation) return;

    const receiptsRef = ref(this.db, `finances/${this.userLocation}/receipts`);
    const transfersRef = ref(this.db, `finances/${this.userLocation}/transferredFunds`);

    try {
      const receiptsSnapshot = await get(receiptsRef);
      const receiptsData: Receipt[] = receiptsSnapshot.exists() ? Object.values(receiptsSnapshot.val()) : [];
      const transfersSnapshot = await get(transfersRef);
      const transfersData: Transfer[] = transfersSnapshot.exists() ? Object.values(transfersSnapshot.val()) : [];

      this.processReceipts(receiptsData);
      this.processTransfers(transfersData);
      this.updateChartData();
      this.updateMonthlyExpenses(receiptsData, transfersData);
    } catch (error) {
      this.showNotification('error', 'ERROR_FETCHING_RECEIPTS_OR_TRANSFERS');
    }
  }

  processReceipts(receipts: Receipt[]) {
    this.revenueData.datasets[0].data = [0, 0, 0, 0];
    this.totalSpentThisYear = 0;

    receipts.forEach(receipt => {
      const index = this.revenueLabels.indexOf(receipt.itemType);
      if (index !== -1 && typeof this.revenueData.datasets[0].data[index] === 'number') {
        this.revenueData.datasets[0].data[index] = (this.revenueData.datasets[0].data[index] as number) + receipt.price;
      }

      const receiptDate = new Date(receipt.date);
      if (receiptDate.getFullYear() === this.year) {
        this.totalSpentThisYear += receipt.price;
      }
    });
  }

  processTransfers(transfers: Transfer[]) {
    const totalTransfers = transfers.reduce((sum, transfer) => sum + transfer.amount, 0);
    const transfersIndex = this.revenueLabels.indexOf('Transfers');
    if (transfersIndex !== -1 && typeof this.revenueData.datasets[0].data[transfersIndex] === 'number') {
      this.revenueData.datasets[0].data[transfersIndex] = totalTransfers;
    }
    this.totalIncomeThisYear = totalTransfers;
  }

  updateChartData() {
    if (this.chart) this.chart.update();
  }

  updateMonthlyExpenses(receipts: Receipt[], transfers: Transfer[]) {
    this.months = [];

    const expensesByMonth: { [key: string]: MonthlyExpense[] } = {};

    receipts.forEach(receipt => {
      const date = new Date(receipt.date);
      const monthName = date.toLocaleString('default', { month: 'long' });

      if (!expensesByMonth[monthName]) expensesByMonth[monthName] = [];
      expensesByMonth[monthName].push({
        name: receipt.userName,
        itemType: receipt.itemType,
        date: this.formatDate(receipt.date),
        price: receipt.price,
        imageUrl: receipt.imageUrl,
        expenseType: 'receipt'
      });
    });

    transfers.forEach(transfer => {
      const date = new Date(transfer.date || new Date().toISOString());
      const monthName = date.toLocaleString('default', { month: 'long' });

      if (!expensesByMonth[monthName]) expensesByMonth[monthName] = [];
      expensesByMonth[monthName].push({
        name: transfer.userName,
        itemType: 'Transfer',
        date: this.formatDate(transfer.date || new Date().toISOString()),
        price: transfer.amount,
        proofImageUrl: transfer.proofImageUrl,
        expenseType: 'transfer'
      });
    });

    for (const month in expensesByMonth) {
      if (expensesByMonth.hasOwnProperty(month)) {
        this.months.push({
          name: month,
          expenses: expensesByMonth[month],
          isExpanded: false
        });
      }
    }

    const monthOrder = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    this.months.sort((a, b) => monthOrder.indexOf(a.name) - monthOrder.indexOf(b.name));
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  // Modified uploadReceipt method to handle file upload on submit
  async uploadReceipt() {
    if (!this.userLocation) {
      return;
    }

    if (this.receipt.price > this.availableBalance) {
      this.showNotification('error', 'INSUFFICIENT_BALANCE');
      return;
    }

    if (!this.receiptFile) {
      this.showNotification('error', 'PLEASE_UPLOAD_RECEIPT_IMAGE');
      return;
    }

    const receiptsRef = ref(this.db, `finances/${this.userLocation}/Pending`);
    const newReceipt: Receipt = {
      userName: this.userName,
      date: this.receipt.date,
      price: this.receipt.price,
      itemType: this.receipt.itemType,
      description: this.receipt.description,
      status: 'pending', // Set status to 'pending
      type: 'Receipt',
      uid: this.uid
      // imageUrl will be set after upload
    };

    try {
      // Upload the receipt image first
      const downloadURL = await this.uploadFile(
        this.receiptFile, 
        `receipts/${this.userLocation}/${this.uid}_receipt_${Date.now()}.${this.getFileExtension(this.receiptFile)}`
      );
      newReceipt.imageUrl = downloadURL;

      // Now push the receipt data with imageUrl
      const newReceiptRef = push(receiptsRef);
      const newKey = newReceiptRef.key;

      if (newKey) {
        await set(newReceiptRef, newReceipt);
        // Add this block to create a notice
        const noticeRef = ref(this.db, `Notice/${this.userLocation}`);
        const newNoticeRef = push(noticeRef);
        await set(newNoticeRef, {
          type: 'Receipt',
          data: newReceipt,
          sent: false
        });
        this.showNotification('success', 'RECEIPT_UPLOADED_SUCCESS');
        this.fetchReceiptsAndTransfers();
        this.resetReceiptForm();
      }
    } catch (error) {
      this.showNotification('error', 'ERROR_UPLOADING_RECEIPT_IMAGE');
    }
  }

  // Modified transferFunds method to handle file upload on submit
  async transferFunds() {
    if (!this.userLocation) {
      return;
    }

    if (!this.transferProofFile) {
      this.showNotification('error', 'UPLOAD_TRANSFER_IMAGE_WARNING');
      return;
    }
    if (this.transfer.amount <= 0) {
      this.showNotification('error', 'INVALID_TRANSFER_AMOUNT');
      return;
    }

    const transfersRef = ref(this.db, `finances/${this.userLocation}/Pending`);
    const newTransfer: Transfer = {
      userName: this.userName,
      amount: this.transfer.amount,
      date: new Date().toISOString(),
      status: 'pending', // Set status to 'pending',
      type: 'Transfer',
      uid: this.uid
      // proofImageUrl will be set after upload
    };

    try {
      // Upload the transfer proof image first
      const downloadURL = await this.uploadFile(
        this.transferProofFile, 
        `transfers/${this.userLocation}/${this.uid}_transfer_${Date.now()}.${this.getFileExtension(this.transferProofFile)}`
      );
      newTransfer.proofImageUrl = downloadURL;

      // Now push the transfer data with proofImageUrl
      const newTransferRef = push(transfersRef);
      const newKey = newTransferRef.key;

      if (newKey) {
        await set(newTransferRef, newTransfer);
        // Add this block to create a notice
        const noticeRef = ref(this.db, `Notice/${this.userLocation}`);
        const newNoticeRef = push(noticeRef);
        await set(newNoticeRef, {
          type: 'Transfer',
          data: newTransfer,
          sent: false
        });
        this.showNotification('success', 'FUNDS_TRANSFERRED_SUCCESS');
        //this.fetchReceiptsAndTransfers();
        this.resetTransferForm();
      }
    } catch (error) {
      this.showNotification('error', 'ERROR_TRANSFERRING_FUNDS');
    }
  }

  // Utility method to handle file uploads
  private async uploadFile(file: File, filePath: string): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let uploadFile = file;
        const fileType = file.type;
        const isImage = fileType.startsWith('image/');
        const isPDF = fileType === 'application/pdf';

        if (!isImage && !isPDF) {
          this.showNotification('error', 'INVALID_FILE_TYPE');
          reject('Invalid file type');
          return;
        }

        if (isImage) {
          const options = { maxSizeMB: 1, maxWidthOrHeight: 1024, useWebWorker: true };
          uploadFile = await imageCompression(file, options);
        }

        const storageReference = storageRef(this.storage, filePath);
        const uploadTask = uploadBytesResumable(storageReference, uploadFile);

        // Determine which progress variable to update
        const isReceipt = filePath.includes('receipts');
        if (isReceipt) {
          this.uploadProgress = 0;
        } else {
          this.uploadTransferProgress = 0;
        }

        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (isReceipt) {
              this.uploadProgress = progress;
            } else {
              this.uploadTransferProgress = progress;
            }
          },
          (error) => {
            if (isReceipt) {
              this.uploadProgress = null;
            } else {
              this.uploadTransferProgress = null;
            }
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            if (isReceipt) {
              this.uploadProgress = null;
            } else {
              this.uploadTransferProgress = null;
            }
            resolve(downloadURL);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  // Utility method to get file extension
  private getFileExtension(file: File): string {
    const parts = file.name.split('.');
    return parts.length > 1 ? parts.pop()!.toLowerCase() : 'png';
  }

  // Modified onFileChange to store the selected file instead of uploading
  onFileChange(event: any) {
    const file = event.target.files[0];
    
    if (file && this.userLocation) {
      const fileType = file.type;
      const isImage = fileType.startsWith('image/');
      const isPDF = fileType === 'application/pdf';

      if (!isImage && !isPDF) {
        this.showNotification('error', 'INVALID_FILE_TYPE');
        return;
      }

      this.receiptFile = file;
      this.showNotification('success', 'FILE_SELECTED_SUCCESS');
    } else {
      this.showNotification('error', 'UPLOAD_IMAGE_WARNING');
    }
  }

  // Modified onProofImageChange to store the selected file instead of uploading
  onProofImageChange(event: any) {
    const file = event.target.files[0];
    
    if (file && this.userLocation) {
      const fileType = file.type;
      const isImage = fileType.startsWith('image/');
      const isPDF = fileType === 'application/pdf';

      if (!isImage && !isPDF) {
        this.showNotification('error', 'INVALID_FILE_TYPE');
        return;
      }

      this.transferProofFile = file;
      this.showNotification('success', 'FILE_SELECTED_SUCCESS');
    } else {
      this.showNotification('error', 'UPLOAD_TRANSFER_IMAGE_WARNING');
    }
  }

  confirmTransfer() {
    this.translate.get('CONFIRM_TRANSFER').subscribe((res: string) => {
      if (window.confirm(res)) {
        this.transferFunds();
      }
    });
  }

  toggleMonth(month: Month) {
    month.isExpanded = !month.isExpanded;
  }

  isReceiptFormValid(): boolean {
    return (
      !!this.receipt.itemType &&
      this.receipt.price > 0 &&
      !!this.receipt.date &&
      !!this.receiptFile &&
      this.receipt.price <= this.availableBalance
    );
  }

  resetReceiptForm() {
    this.receipt = {
      userName: this.userName,
      itemType: '',
      price: 0,
      date: '',
      description: '',
      imageUrl: '',
      type: '',
      uid: this.uid
    };
    this.receiptFile = null;
    this.receiptFilePath = null;
  }

  resetTransferForm() {
    this.transfer = {
      userName: '',
      amount: 0,
      proofImageUrl: '',
      type: '',
      uid: this.uid
    };
    this.transferProofFile = null;
    this.transferFilePath = null;
  }

  async updateAvailableBalance(amount: number) {
    if (!this.userLocation) return;
  
    const balanceRef = ref(this.db, `finances/${this.userLocation}/availableBalance`);
    const newBalance = this.availableBalance + amount;
  
    try {
      await set(balanceRef, newBalance);
      this.availableBalance = newBalance;
      this.showNotification('success', 'BALANCE_UPDATED_SUCCESS');
    } catch (error) {
      this.showNotification('error', 'ERROR_UPDATING_BALANCE');
    }
  }
  

  async decreaseAvailableBalance(amount: number) {
    if (!this.userLocation) return;
  
    const balanceRef = ref(this.db, `finances/${this.userLocation}/availableBalance`);
    const newBalance = this.availableBalance - amount;
  
    try {
      await set(balanceRef, newBalance);
      this.availableBalance = newBalance;
      this.showNotification('success', 'BALANCE_UPDATED_SUCCESS');
    } catch (error) {
      this.showNotification('error', 'ERROR_UPDATING_BALANCE');
    }
  }
  
}
