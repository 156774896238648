import { Component, OnInit, OnDestroy } from '@angular/core';
import { Database, ref, onValue, off } from '@angular/fire/database';
import { Auth, authState } from '@angular/fire/auth';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

/**
 * Author Interface
 * Defines the structure of an author object.
 */
export interface Author {
  name: string;
  icon: string; // Path to the author's icon image
}

/**
 * Notification Interface
 * Extends to include author information.
 */
export interface Notification {
  id: string;
  type: 'general' | 'emergency' | 'maintenance' | 'update';
  title: string;
  content: string;
  timestamp: Date;
  author: Author;
  top: boolean; // New property to indicate top notifications
  recipients?: string[]; // Optional recipients field to specify targeted users
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  topNotifications: Notification[] = [];
  regularNotifications: Notification[] = [];
  location: string = '';
  private currentUserUid: string = '';
  private unsubscribe$ = new Subject<void>();

  constructor(
    private db: Database,
    private auth: Auth,
    private translate: TranslateService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.fetchUserAddress();
  }

  ngOnDestroy(): void {
    // Complete the observable to avoid memory leaks
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    // Remove all listeners
    off(ref(this.db, `/Notifications/${this.location}`));
    off(ref(this.db, `/Accounts/${this.auth.currentUser?.uid}/Location`));
  }

  /**
   * Fetches the current user's address and initiates notification fetching.
   */
  fetchUserAddress(): void {
    authState(this.auth)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        if (user && user.uid) {
          this.currentUserUid = user.uid;
          const userAddressRef = ref(this.db, `/Accounts/${user.uid}/Location`);
          onValue(userAddressRef, snapshot => {
            if (snapshot.exists()) {
              this.location = snapshot.val();
              this.fetchNotifications(this.location);
            } else {
              this.translate.get('NOTIFICATIONS.ADDRESS_NOT_FOUND').subscribe((msg: string) => {
                this.notifier.notify('error', msg);
              });
            }
          }, error => {
            this.translate.get('NOTIFICATIONS.FETCH_ADDRESS_ERROR').subscribe((msg: string) => {
              this.notifier.notify('error', msg);
            });
            console.error('Error fetching address:', error);
          });
        }
      });
  }

  /**
   * Fetches notifications from Firebase Realtime Database under the user's address.
   * Filters notifications based on the recipients list if present.
   * @param userAddress - The address of the user used as a node in Firebase.
   */
  fetchNotifications(userAddress: string): void {
    const notificationsRef = ref(this.db, `/Notifications/${userAddress}`);
    onValue(notificationsRef, snapshot => {
      const data = snapshot.val();
      if (data) {
        const notificationsList: Notification[] = Object.keys(data).map(key => ({
          id: key,
          type: data[key].type,
          title: data[key].title,
          content: data[key].content,
          timestamp: new Date(data[key].timestamp),
          author: {
            name: data[key].author?.name || 'Unknown',
            icon: data[key].author?.icon || 'assets/icons/user.png' // Assign default icon if missing
          },
          top: data[key].top || false,
          recipients: data[key].recipients || []
        }));

        // Filter notifications based on recipients
        const filteredNotifications = notificationsList.filter(notification => {
          return notification?.recipients?.includes('N/A') || !notification?.recipients?.length || notification.recipients.includes(this.currentUserUid);
        });

        // Sort notifications from latest to oldest
        const sortedNotifications = filteredNotifications.sort((a, b) => {
          if (a.top === b.top) {
            return b.timestamp.getTime() - a.timestamp.getTime();
          }
          return a.top ? -1 : 1;
        });

        // Separate top and regular notifications
        this.topNotifications = sortedNotifications.filter(notification => notification.top);
        this.regularNotifications = sortedNotifications.filter(notification => !notification.top);
      } else {
        this.topNotifications = [];
        this.regularNotifications = [];
      }
    }, error => {
      this.translate.get('NOTIFICATIONS.FETCH_NOTIFICATIONS_ERROR').subscribe((msg: string) => {
        this.notifier.notify('error', msg);
      });
      console.error('Error fetching notifications:', error);
    });
  }

  /**
   * Returns the icon path based on the notification type.
   * Uses image assets.
   * @param type - The type of the notification.
   * @returns A string representing the path to the icon image.
   */
  getIconPath(type: string): string {
    switch (type) {
      case 'emergency':
        return 'assets/emergency-notification.png';
      case 'general':
        return 'assets/notification.png';
      case 'maintenance':
        return 'assets/maintenance.png';
      case 'update':
        return 'assets/status.png';
      default:
        return 'assets/inotification.png';
    }
  }

  /**
   * Handles image load errors by setting the src to a default user icon.
   * @param event - The error event from the image.
   */
  onImageError(event: any): void {
    event.target.src = 'assets/user.png';
  }
}
