<div class="auth-container">
  <div class="auth-card">
    <div class="auth-header">
      <img src="assets/sign-in.png" alt="{{ 'SIGN_IN.TITLE' | translate }}" class="auth-icon" />
      <h2>{{ 'SIGN_IN.TITLE' | translate }}</h2>
    </div>
    <form (ngSubmit)="signIn()" class="auth-form">
      <input
        type="email"
        [(ngModel)]="email"
        name="email"
        placeholder="{{ 'SIGN_IN.EMAIL_PLACEHOLDER' | translate }}"
        class="auth-input"
        required
        autocomplete="email"
      />
      <input
        type="password"
        [(ngModel)]="password"
        name="password"
        placeholder="{{ 'SIGN_IN.PASSWORD_PLACEHOLDER' | translate }}"
        class="auth-input"
        required
        autocomplete="current-password"
      />
      <button type="submit" class="auth-button">
        {{ 'SIGN_IN.SIGN_IN_BUTTON' | translate }}
      </button>
    </form>
    

    <!-- Forgot Password Link -->
    <div class="forgot-password-link">
      <a (click)="navigateToForgotPassword()">{{ 'SIGN_IN.FORGOT_PASSWORD_LINK' | translate }}</a>
    </div>

    <!-- Show this section if the email is not verified -->
    <div *ngIf="showEmailVerificationSection" class="verification-section">
      <p>{{ 'SIGN_IN.EMAIL_NOT_VERIFIED' | translate }}</p>
      <button (click)="resendVerificationEmail()" class="resend-button">{{ 'SIGN_IN.RESEND_VERIFICATION' | translate }}</button>
      <p *ngIf="emailSentMessage" class="email-sent-message">{{ 'SIGN_IN.VERIFICATION_EMAIL_SENT' | translate }}</p>
    </div>

    <div class="auth-footer">
      <p>{{ 'SIGN_IN.SIGN_UP_PROMPT' | translate }}</p>
      <button (click)="navigateToInvitation()" class="secondary-button">{{ 'SIGN_IN.SIGN_UP_BUTTON' | translate }}</button>
    </div>
  </div>
</div>
