// src/app/terms-and-conditions/terms-and-conditions.component.ts
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'; // Import TranslateService

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  // Define the sections of the Terms and Conditions
  termsSections = [
    { heading: 'TERMS.section1.heading', content: 'TERMS.section1.content' },
    { heading: 'TERMS.section2.heading', content: 'TERMS.section2.content' },
    { heading: 'TERMS.section3.heading', content: 'TERMS.section3.content' },
    { heading: 'TERMS.section4.heading', content: 'TERMS.section4.content' },
    { heading: 'TERMS.section5.heading', content: 'TERMS.section5.content' },
  ];

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    // Any initialization logic can go here
  }

}
