<div class="container bill-calculator-container mt-4 mb-4">
  
  <!-- Tenant Billing Section -->
  <!-- <div class="card custom-card mb-4 animate__animated animate__fadeInUp">
    <div class="card-body">
      <div class="d-flex align-items-center mb-3">
        <img src="assets/friendship.png" alt="Friends Icon" class="icon me-2" />
        <h3 class="card-title mb-0">{{'MONTHLY' | translate}}{{ 'BILL_CALCULATORS.FRIENDS_TITLE' | translate }}</h3>
      </div>
      <hr />
      <div class="mb-3">
        <div class="d-flex align-items-center mb-2">
          <img src="assets/wifi.png" alt="WiFi Icon" class="icon-small me-2" />
          <strong>{{ 'BILL_CALCULATORS.INTERNET_FEE' | translate }}:</strong> €{{ internetFee }}
        </div>
        <div class="d-flex align-items-center mb-2">
          <img src="assets/trash.png" alt="Trash Icon" class="icon-small me-2" />
          <strong>{{ 'BILL_CALCULATORS.TRASH_FEE' | translate }}:</strong> €{{ trashFee }}
        </div>
      </div>
    </div>
  </div> -->

   <!-- Payment Status Section -->
   <div
    *ngIf="internetFeeData.length > 0 || trashFeeData.length > 0"
    class="card custom-card mb-4 animate__animated animate__fadeInUp"
  >
    <div class="card-body">
      <div class="d-flex align-items-center mb-3">
        <img
          src="assets/fee-record.png"
          alt="Payment Status Icon"
          class="icon me-2"
        />
        <h3 class="card-title mb-0">
          {{year}} - {{ 'BILL_CALCULATORS.FEE_TRACKING' | translate }}
        </h3>
      </div>
      <hr />
      <div class="row g-3 align-items-center">
        <!-- Internet Fee Doughnut Chart -->
        <div
          *ngIf="internetFeeData.length > 0"
          class="col-lg-6 col-md-6 col-12 text-center"
        >
          <canvas id="internetFeeDoughnutChart" class="compact-canvas"></canvas>
           <!-- Custom Legend -->
          <div class="legend mt-3">
            <span class="legend-item">
              <span
                class="legend-color"
                [style.backgroundColor]="internetPaidColor"
              ></span>
              {{ 'BILL_STATUS.PAID' | translate }}
            </span>
            <span class="legend-item">
              <span class="legend-color unpaid-color"></span>
              {{ 'BILL_STATUS.UNPAID' | translate }}
            </span>
          </div>
        </div>
           <!-- Divider -->
     <!-- <div class="divider d-none d-md-block"></div> -->
        <!-- Trash Fee Doughnut Chart -->
        <div
          *ngIf="trashFeeData.length > 0"
          class="col-lg-6 col-md-6 col-12 text-center"
        >
          <canvas id="trashFeeDoughnutChart" class="compact-canvas"></canvas>
           <!-- Custom Legend -->
          <div class="legend mt-3">
            <span class="legend-item">
              <span
                class="legend-color"
                [style.backgroundColor]="trashPaidColor"
              ></span>
              {{ 'BILL_STATUS.PAID' | translate }}
            </span>
            <span class="legend-item">
              <span class="legend-color unpaid-color"></span>
              {{ 'BILL_STATUS.UNPAID' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Current Period Bills Section -->
  <div *ngIf="currentPeriodBills != null && currentPeriodBills.length > 0"  class="card custom-card mb-4 animate__animated animate__fadeInUp main-card">
    <div class="d-flex align-items-center ml-5 mt-3">
      <img
        src="assets/fee.png"
        alt="Payment Status Icon"
        class="icon me-2"
      />
      <h3 class="card-title mb-0">
        {{year}} - {{ 'BILL_CALCULATORS.BILL_TITLE' | translate }}
      </h3>
    </div>
    <hr>
  <div class="row">
    <div
      *ngFor="let bill of currentPeriodBills"
            class="col-md-6 mb-4"
    >
      <div
        class="card custom-card animate__animated animate__fadeInUp h-100"
      >
        <div class="card-body">
          <div class="d-flex align-items-center mb-3">
            <img
              [src]="bill.providerIcon"
              alt="Bill Icon"
              class="icon me-2"
            />
            <h3 class="card-title mb-0">
              {{ 'BILL_CALCULATORS.CURRENT_PERIOD_BILL_TITLE' | translate }}
            </h3>
          </div>
          <hr />
          <p>
            <strong>{{ 'BILL_CALCULATORS.TYPE' | translate }}:</strong>
            {{ bill.type | translate }}
          </p>
          <p>
            <strong>{{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}:</strong>
            €{{ bill.amount }}
          </p>
          <p>
            <strong>{{ 'BILL_CALCULATORS.PERIOD' | translate }}:</strong>
            {{ bill.period }}
          </p>
          <p>
            <strong>{{ 'BILL_CALCULATORS.IS_PER_PERSON' | translate }}:</strong>
            {{ bill.isPerPerson | translate }}
          </p>
          <p *ngIf="bill.billImage">
            <strong>{{ 'BILL_CALCULATORS.BILL_IMAGE' | translate }}:</strong>
            <a [href]="bill.billImage" target="_blank" class="blue-link">
              {{ 'BILL_CALCULATORS.VIEW_BILL_IMAGE' | translate }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- Bill Calculation Section -->
  <div class="card custom-card mb-4 animate__animated animate__fadeInUp">
    <div class="card-body">
      <div class="d-flex align-items-center mb-3">
        <img src="assets/bill.png" alt="Bill Icon" class="icon me-2" />
        <h3 class="card-title mb-0">
          {{ 'BILL_CALCULATORS.BILL_CALCULATOR_TOOL' | translate }}
        </h3>
      </div>
      <hr />
      <form (ngSubmit)="initializeDaysAndCalculateBill()" #billForm="ngForm" novalidate>
        <div class="mb-3">
          <label for="totalBill" class="form-label">
            <img src="assets/total.png" alt="Total Bill Icon" class="icon-label me-2" />
            {{ 'BILL_CALCULATORS.TOTAL_BILL_LABEL' | translate }}
          </label>
          <input 
            type="number" 
            [(ngModel)]="totalBill" 
            name="totalBill" 
            id="totalBill" 
            class="form-control" 
            placeholder="{{ 'BILL_CALCULATORS.TOTAL_BILL_PLACEHOLDER' | translate }}" 
            min="0" 
            required 
            #totalBillInput="ngModel"
            [ngClass]="{'is-invalid': totalBillInput.invalid && (totalBillInput.dirty || totalBillInput.touched)}"
          />
          <div *ngIf="totalBillInput.invalid && (totalBillInput.dirty || totalBillInput.touched)" class="invalid-feedback">
            {{ 'BILL_CALCULATORS.TOTAL_BILL_REQUIRED' | translate }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="startDate" class="form-label">
              <img src="assets/calendar.png" alt="Start Date Icon" class="icon-label me-2" />
              {{ 'BILL_CALCULATORS.START_DATE' | translate }}
            </label>
            <input 
              type="date" 
              [(ngModel)]="startDate" 
              name="startDate" 
              id="startDate" 
              class="form-control" 
              required 
              #startDateInput="ngModel"
              [ngClass]="{'is-invalid': startDateInput.invalid && (startDateInput.dirty || startDateInput.touched)}"
            />
            <div *ngIf="startDateInput.invalid && (startDateInput.dirty || startDateInput.touched)" class="invalid-feedback">
              {{ 'BILL_CALCULATORS.START_DATE_REQUIRED' | translate }}
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="endDate" class="form-label">
              <img src="assets/calendar.png" alt="End Date Icon" class="icon-label me-2" />
              {{ 'BILL_CALCULATORS.END_DATE' | translate }}
            </label>
            <input 
              type="date" 
              [(ngModel)]="endDate" 
              name="endDate" 
              id="endDate" 
              class="form-control" 
              required 
              #endDateInput="ngModel"
              [ngClass]="{'is-invalid': endDateInput.invalid && (endDateInput.dirty || endDateInput.touched)}"
            />
            <div *ngIf="endDateInput.invalid && (endDateInput.dirty || endDateInput.touched)" class="invalid-feedback">
              {{ 'BILL_CALCULATORS.END_DATE_REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-success w-100 calculate-btn" [disabled]="billForm.invalid">
          <i class="fas fa-calculator me-2"></i> {{ 'BILL_CALCULATORS.CALCULATE_BUTTON' | translate }}
        </button>
      </form>
      <br>
      <!-- Tenants Table -->
      <div class="table-responsive-custom  mb-4 animate__animated animate__fadeInUp" *ngIf="isCalculated" >
        <table class="table table-bordered table-custom">
          <thead class="table-light">
            <tr>
              <th>{{ 'BILL_CALCULATORS.TABLE.NAME' | translate }}</th>
              <th>{{ 'BILL_CALCULATORS.TABLE.DAYS' | translate }}</th>
              <th>{{ 'BILL_CALCULATORS.TABLE.ELECGAS' | translate }}</th>
              <th>{{ 'BILL_CALCULATORS.TABLE.AMOUNT' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tenant of tenants">
              <td>
                <img 
                  [src]="tenant.profileUrl || 'assets/user.png'" 
                  alt="Profile Image" 
                  class="profile-img me-2" 
                />
                {{ tenant.name }}
              </td>
              <td>
                <input 
                type="number" 
                [(ngModel)]="tenant.days" 
                class="form-control text-center days-input" 
                min="0" 
                (change)="updateBill(tenant)" 
                aria-label="Days Lived"
                placeholder="0"
              />
              
                
              </td>
              <td>€{{ tenant.elecgas }}</td>
              <td>€{{ tenant.amount }}</td>
            </tr>            
          </tbody>
        </table>
      </div>
    </div>
  </div>
  

  <!-- Individual Billing Section -->
  <div class="card custom-card mb-4 animate__animated animate__fadeInUp">
    <div class="card-body">
      <div class="d-flex align-items-center mb-3">
        <img src="assets/individual.png" alt="Individual Bill Icon" class="icon me-2" />
        <h3 class="card-title mb-0">
          {{ 'BILL_CALCULATORS.INDIVIDUAL_BILLING_CALCULATOR' | translate }}
        </h3>
      </div>
      <hr />
      <form (ngSubmit)="calculateIndividualBill()" #individualForm="ngForm" novalidate>
        <!-- Electricity Type Dropdown -->
        <div class="mb-3">
          <label for="electricityType" class="form-label">
            <img src="assets/electype.png" alt="Electricity Icon" class="icon-label me-2" />
            {{ 'BILL_CALCULATORS.ELECTRICITY_TYPE_LABEL' | translate }}
          </label>
          <select
            id="electricityType"
            name="electricityType"
            [(ngModel)]="electricityType"
            class="form-select"
            required
            #electricityTypeInput="ngModel"
            [ngClass]="{'is-invalid': electricityTypeInput.invalid && (electricityTypeInput.dirty || electricityTypeInput.touched)}"
          >
            <option value="" disabled selected>{{ 'BILL_CALCULATORS.SELECT_ELECTRICITY_TYPE' | translate }}</option>
            <option value="allDay">{{ 'BILL_CALCULATORS.ALL_DAY_ELECTRICITY' | translate }}</option>
            <option value="dayNight">{{ 'BILL_CALCULATORS.DAY_NIGHT_ELECTRICITY' | translate }}</option>
          </select>
          <div *ngIf="electricityTypeInput.invalid && (electricityTypeInput.dirty || electricityTypeInput.touched)" class="invalid-feedback">
            {{ 'BILL_CALCULATORS.ELECTRICITY_TYPE_REQUIRED' | translate }}
          </div>
        </div>

        <!-- Gas Inclusion Checkbox -->
        <div class="form-check mb-3">
          <input
            type="checkbox"
            id="includeGas"
            name="includeGas"
            [(ngModel)]="includeGas"
            class="form-check-input"
          />
          <label for="includeGas" class="form-check-label">
            {{ 'BILL_CALCULATORS.INCLUDE_GAS_LABEL' | translate }}
          </label>
        </div>

        <!-- Electricity Usage Fields -->
        <div class="row">
          <!-- Current KW -->
          <div class="col-md-6 mb-3">
            <label for="currentKW" class="form-label">
              <img src="assets/energy.png" alt="Energy Icon" class="icon-label me-2" />
              {{ 'BILL_CALCULATORS.CURRENT_KW_LABEL' | translate }}
            </label>
            <input 
              type="number" 
              [(ngModel)]="individualBilling.currentKW" 
              name="currentKW" 
              id="currentKW" 
              class="form-control" 
              min="0" 
              required 
              #currentKWInput="ngModel"
              [ngClass]="{'is-invalid': currentKWInput.invalid && (currentKWInput.dirty || currentKWInput.touched)}"
            />
            <div *ngIf="currentKWInput.invalid && (currentKWInput.dirty || currentKWInput.touched)" class="invalid-feedback">
              {{ 'BILL_CALCULATORS.CURRENT_KW_REQUIRED' | translate }}
            </div>
          </div>
          <!-- Previous KW -->
          <div class="col-md-6 mb-3">
            <label for="previousKW" class="form-label">
              <img src="assets/history.png" alt="Previous KW Icon" class="icon-label me-2" />
              {{ 'BILL_CALCULATORS.PREVIOUS_KW_LABEL' | translate }}
            </label>
            <input 
              type="number" 
              [(ngModel)]="individualBilling.previousKW" 
              name="previousKW" 
              id="previousKW" 
              class="form-control" 
              min="0" 
              required 
              #previousKWInput="ngModel"
              [ngClass]="{'is-invalid': previousKWInput.invalid && (previousKWInput.dirty || previousKWInput.touched)}"
            />
            <div *ngIf="previousKWInput.invalid && (previousKWInput.dirty || previousKWInput.touched)" class="invalid-feedback">
              {{ 'BILL_CALCULATORS.PREVIOUS_KW_REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <!-- Night Electricity Fields (Visible if Day and Night Electricity is selected) -->
        <div class="row" *ngIf="electricityType === 'dayNight'">
          <!-- Current Night KW -->
          <div class="col-md-6 mb-3">
            <label for="currentNightKW" class="form-label">
              <img src="assets/nightelec.png" alt="Night Energy Icon" class="icon-label me-2" />
              {{ 'BILL_CALCULATORS.CURRENT_NIGHT_KW_LABEL' | translate }}
            </label>
            <input 
              type="number" 
              [(ngModel)]="individualBilling.currentNightKW" 
              name="currentNightKW" 
              id="currentNightKW" 
              class="form-control" 
              min="0" 
              required 
              #currentNightKWInput="ngModel"
              [ngClass]="{'is-invalid': currentNightKWInput.invalid && (currentNightKWInput.dirty || currentNightKWInput.touched)}"
            />
            <div *ngIf="currentNightKWInput.invalid && (currentNightKWInput.dirty || currentNightKWInput.touched)" class="invalid-feedback">
              {{ 'BILL_CALCULATORS.CURRENT_NIGHT_KW_REQUIRED' | translate }}
            </div>
          </div>
          <!-- Previous Night KW -->
          <div class="col-md-6 mb-3">
            <label for="previousNightKW" class="form-label">
              <img src="assets/history.png" alt="Previous Night KW Icon" class="icon-label me-2" />
              {{ 'BILL_CALCULATORS.PREVIOUS_NIGHT_KW_LABEL' | translate }}
            </label>
            <input 
              type="number" 
              [(ngModel)]="individualBilling.previousNightKW" 
              name="previousNightKW" 
              id="previousNightKW" 
              class="form-control" 
              min="0" 
              required 
              #previousNightKWInput="ngModel"
              [ngClass]="{'is-invalid': previousNightKWInput.invalid && (previousNightKWInput.dirty || previousNightKWInput.touched)}"
            />
            <div *ngIf="previousNightKWInput.invalid && (previousNightKWInput.dirty || previousNightKWInput.touched)" class="invalid-feedback">
              {{ 'BILL_CALCULATORS.PREVIOUS_NIGHT_KW_REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <!-- Gas Usage Fields (Visible if Gas is included) -->
        <div class="row" *ngIf="includeGas">
          <!-- Current Gas -->
          <div class="col-md-6 mb-3">
            <label for="currentGas" class="form-label">
              <img src="assets/gas.png" alt="Gas Icon" class="icon-label me-2" />
              {{ 'BILL_CALCULATORS.CURRENT_GAS_LABEL' | translate }}
            </label>
            <input 
              type="number" 
              [(ngModel)]="individualBilling.currentGas" 
              name="currentGas" 
              id="currentGas" 
              class="form-control" 
              min="0" 
              required 
              #currentGasInput="ngModel"
              [ngClass]="{'is-invalid': currentGasInput.invalid && (currentGasInput.dirty || currentGasInput.touched)}"
            />
            <div *ngIf="currentGasInput.invalid && (currentGasInput.dirty || currentGasInput.touched)" class="invalid-feedback">
              {{ 'BILL_CALCULATORS.CURRENT_GAS_REQUIRED' | translate }}
            </div>
          </div>
          <!-- Previous Gas -->
          <div class="col-md-6 mb-3">
            <label for="previousGas" class="form-label">
              <img src="assets/history.png" alt="Previous Gas Icon" class="icon-label me-2" />
              {{ 'BILL_CALCULATORS.PREVIOUS_GAS_LABEL' | translate }}
            </label>
            <input 
              type="number" 
              [(ngModel)]="individualBilling.previousGas" 
              name="previousGas" 
              id="previousGas" 
              class="form-control" 
              min="0" 
              required 
              #previousGasInput="ngModel"
              [ngClass]="{'is-invalid': previousGasInput.invalid && (previousGasInput.dirty || previousGasInput.touched)}"
            />
            <div *ngIf="previousGasInput.invalid && (previousGasInput.dirty || previousGasInput.touched)" class="invalid-feedback">
              {{ 'BILL_CALCULATORS.PREVIOUS_GAS_REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <!-- Days Lived -->
        <div class="mb-3">
          <label for="daysLived" class="form-label">
            <img src="assets/calendar.png" alt="Days Lived Icon" class="icon-label me-2" />
            {{ 'BILL_CALCULATORS.DAYS_LIVED_LABEL' | translate }}
          </label>
          <input 
            type="number" 
            [(ngModel)]="individualBilling.daysLived" 
            name="daysLived" 
            id="daysLived" 
            class="form-control" 
            min="1" 
            required 
            #daysLivedInput="ngModel"
            [ngClass]="{'is-invalid': daysLivedInput.invalid && (daysLivedInput.dirty || daysLivedInput.touched)}"
          />
          <div *ngIf="daysLivedInput.invalid && (daysLivedInput.dirty || daysLivedInput.touched)" class="invalid-feedback">
            {{ 'BILL_CALCULATORS.DAYS_LIVED_REQUIRED' | translate }}
          </div>
        </div>
        <button type="submit" class="btn btn-success w-100 calculate-btn" [disabled]="individualForm.invalid">
          <i class="fas fa-calculator me-2"></i> {{ 'BILL_CALCULATORS.CALCULATE_BUTTON' | translate }}
        </button>
      </form>
    </div>
  </div>

  <!-- Calculation Results Section -->
  <div class="card custom-card animate__animated animate__fadeInUp" *ngIf="isPersonalBillCalculated">
    <div class="card-body">
      <div class="d-flex align-items-center mb-3">
        <img src="assets/results.png" alt="Results Icon" class="icon me-2" />
        <h3 class="card-title mb-0">
          {{ 'BILL_CALCULATORS.CALCULATION_RESULTS_TITLE' | translate }}
        </h3>
      </div>
      <hr />
      <div *ngIf="individualResults.totalAmount > 0; else noResults" class="results-section">
        <div class="row mb-2" *ngFor="let result of getResultsArray(individualResults)">
          <div class="col-6">
            <strong>{{ result.label | translate }}:</strong>
          </div>
          <div class="col-6 text-end">
            €{{ result.value }}
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12 text-end">
            <h4>
              {{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}: €{{ individualResults.totalAmount }}
            </h4>
          </div>
        </div>
      </div>
      <ng-template #noResults>
        <p class="text-muted">{{ 'BILL_CALCULATORS.NO_RESULTS' | translate }}</p>
      </ng-template>
    </div>
  </div>
</div>
