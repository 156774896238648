// src/app/app-routing.module.ts
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { InvitationComponent } from './auth/invitation/invitation.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BillCalculatorComponent } from './bill-calculator/bill-calculator.component';
import { CleanBinScheduleComponent } from './clean-bin-schedule/clean-bin-schedule.component';
import { AdminComponent } from './admin/admin.component';
import { ReceiptTrackerComponent } from './receipt-tracker/receipt-tracker.component';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ReportProblemsComponent } from './report-problems/report-problems.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ListOfTenantsComponent } from './list-of-tenants/list-of-tenants.component';
import { LandlordComponent } from './landlord/landlord.component';
import { LandlordGuard } from './landlord.guard';
import { NotificationsComponent } from './notifications/notifications.component';

const routes: Routes = [
  { path: 'auth/sign-in', component: SignInComponent },
  { path: 'auth/sign-up', component: SignUpComponent, canActivate: [AuthGuard] }, // Guarded by AuthGuard
  { path: 'auth/invitation', component: InvitationComponent },
  { path: 'auth/forgot-password', component: ForgotPasswordComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'bill-calculator', component: BillCalculatorComponent, canActivate: [AuthGuard] },
  { path: 'clean-bin-schedule', component: CleanBinScheduleComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuard] },
  { path: 'landlord', component: LandlordComponent, canActivate: [LandlordGuard] },
  { path: 'receipt-tracker', component: ReceiptTrackerComponent, canActivate: [AuthGuard] },
  { path: 'report-problems', component: ReportProblemsComponent, canActivate: [AuthGuard]  },
  { path: 'group', component: ListOfTenantsComponent, canActivate: [AuthGuard]  },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard]  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  { path: '', redirectTo: 'auth/sign-in', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/sign-in' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
