<!-- src/app/admin/admin.component.html -->
<div class="admin-container">
  <!-- Admin Content -->
  <div class="admin-content">
    <!-- New Address Assignment Section - Admin Only -->
    <div  class="admin-card animate__animated animate__fadeIn mb-4">
      <h2>
        <i class="fas fa-home"></i> {{ 'ADMIN.ADD_ASSIGN_ADDRESS' | translate }}
      </h2>
      <!-- Form to Assign Address to a Landlord -->
      <form [formGroup]="assignAddressForm" (ngSubmit)="assignAddressToLandlord()">
        <!-- Landlord Selection -->
        <div class="form-group mb-3">
          <label for="landlord">{{ 'ADMIN.SELECT_LANDLORD' | translate }}</label>
          <select id="landlord" formControlName="landlordUID" class="form-control" required (change)="onLandlordSelected()">
            <option value="" disabled [selected]="!assignAddressForm.get('landlordUID')?.value">
              {{ 'ADMIN.SELECT_LANDLORD_OPTION' | translate }}
            </option>
            <option *ngFor="let landlord of landlordsList" [value]="landlord.uid">
              {{ landlord.name }} - {{ landlord.email }}
            </option>
          </select>
        </div>
        <!-- Address Input -->
        <div class="form-group mb-3">
          <label for="newAddress">{{ 'ADMIN.NEW_ADDRESS' | translate }}</label>
          <input id="newAddress" type="text" formControlName="newAddress" class="form-control" [placeholder]="'ADMIN.PLACEHOLDER_ENTER_ADDRESS' | translate" required />
        </div>
        <!-- Submit Button -->
        <button type="submit" class="btn btn-success" [disabled]="assignAddressForm.invalid">
          {{ 'ADMIN.ASSIGN_ADDRESS_BUTTON' | translate }}
        </button>
      </form>
      <!-- Table of Assigned Addresses for Selected Landlord -->
      <div *ngIf="assignAddressForm.get('landlordUID')?.value" class="mt-4">
        <h3>{{ 'ADMIN.EXISTING_ADDRESSES' | translate }}</h3>
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>{{ 'ADMIN.ADDRESS' | translate }}</th>
              <th>{{ 'ADMIN.ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let address of selectedLandlordAddresses">
              <td>{{ address }}</td>
              <td>
                <button class="btn btn-danger btn-sm" (click)="removeAddress(address)">
                  <i class="fas fa-trash-alt me-1"></i> {{ 'ADMIN.REMOVE_ADDRESS' | translate }}
                </button>
              </td>
            </tr>
            <tr *ngIf="selectedLandlordAddresses.length === 0">
              <td colspan="2" class="text-center">{{ 'ADMIN.NO_ASSIGNED_ADDRESSES' | translate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Assign User as Landlord Section - Admin Only -->
    <div class="admin-card animate__animated animate__fadeIn mb-4">
      <h2>
        <i class="fas fa-user-shield"></i> {{ 'ADMIN.ASSIGN_USER_AS_LANDLORD' | translate }}
      </h2>
      <!-- Form to Assign User as Landlord -->
      <form [formGroup]="assignLandlordForm" (ngSubmit)="assignUserAsLandlord()">
        <!-- User Selection -->
        <div class="form-group mb-3">
          <label for="user">{{ 'ADMIN.SELECT_USER' | translate }}</label>
          <select id="user" formControlName="userUID" class="form-control" required>
            <option value="" disabled [selected]="!assignLandlordForm.get('userUID')?.value">
              {{ 'ADMIN.SELECT_USER_OPTION' | translate }}
            </option>
            <option *ngFor="let user of potentialLandlords" [value]="user.uid">
              {{ user.name }} ({{ user.email }})
            </option>
          </select>
        </div>
        <!-- Submit Button -->
        <button type="submit" class="btn btn-primary" [disabled]="assignLandlordForm.invalid">
          {{ 'ADMIN.ASSIGN_LANDLORD_BUTTON' | translate }}
        </button>
      </form>
    </div>
   <!-- Choose Address Section -->
   <div class="admin-card animate__animated animate__fadeIn mb-4">
    <h2>
      <i class="fas fa-map-marker-alt"></i> {{ 'ADMIN.CHOOSE_ADDRESS_LABEL' | translate }}
    </h2>
    <div class="form-group mb-3">
      <label for="address">{{ 'ADMIN.CHOOSE_ADDRESS_LABEL' | translate }}</label>
      <select
        id="address"
        [(ngModel)]="selectedAddress"
        name="address"
        (change)="filterUsersByAddress()"
        class="form-control"
        required
      >
        <option value="" disabled selected>{{ 'ADMIN.SELECT_ADDRESS_OPTION' | translate }}</option>
        <option *ngFor="let address of addresses" [value]="address">{{ address }}</option>
      </select>
      <div *ngIf="!selectedAddress && email !== ''" class="error-message text-danger mt-1">
        {{ 'ADMIN.ADDRESS_REQUIRED' | translate }}
      </div>
    </div>
  </div>
  <!-- Invitation Code Generation Card -->
  <div class="admin-card animate__animated animate__fadeIn mb-4" *ngIf="selectedAddress">
    <h2>
      <i class="fas fa-key"></i> {{ 'ADMIN.GENERATE_INVITATION_CODE' | translate }}
    </h2>
    <form>
      <!-- Email Input (Optional) -->
      <div class="form-group mb-3">
        <label for="email">{{ 'ADMIN.ENTER_EMAIL_LABEL' | translate }} ({{ 'ADMIN.OPTIONAL' | translate }})</label>
        <input type="email" id="email" [(ngModel)]="email" name="email" class="form-control" [placeholder]="'ADMIN.PLACEHOLDER_ENTER_EMAIL' | translate" />
        <div *ngIf="email && !isValidEmail(email)" class="error-message text-danger mt-1">
          {{ 'ADMIN.INVALID_EMAIL' | translate }}
        </div>
      </div>
      <!-- Generate Button -->
      <button type="button" (click)="generateInvitationCode()" class="btn btn-primary" [disabled]="isSubmitting">
        <i class="fas fa-sync-alt"></i>
        {{ isSubmitting ? ('ADMIN.GENERATING' | translate) : ('ADMIN.GENERATE_CODE_BUTTON' | translate) }}
      </button>
    </form>
    <!-- Display the Generated Code -->
    <div *ngIf="generatedCode" class="generated-code mt-4">
      <p>{{ 'ADMIN.GENERATED_CODE' | translate }}</p>
      <div class="code-box d-flex align-items-center">
        <input type="text" [value]="generatedCode" readonly class="form-control me-2" />
        <button (click)="copyCode()" class="btn btn-secondary">
          <i class="fas fa-copy me-1"></i> {{ 'ADMIN.COPY_CODE' | translate }}
        </button>
      </div>
    </div>
  </div>
  <!-- User Management Card -->
  <div class="admin-user-card animate__animated animate__fadeIn mb-4" *ngIf="selectedAddress">
    <h2>
      <i class="fas fa-users"></i> {{ 'ADMIN.USER_MANAGEMENT' | translate }}
    </h2>
    <!-- User Management Table -->
    <table class="table table-striped table-bordered user-table" *ngIf="selectedAddress">
      <thead class="table-primary">
        <tr>
          <th>{{ 'ADMIN.USER_NAME' | translate }}</th>
          <th>{{ 'ADMIN.USER_EMAIL' | translate }}</th>
          <th>{{ 'ADMIN.STATUS' | translate }}</th>
          <th>{{ 'ADMIN.ACTIONS' | translate }}</th>
        </tr>
      </thead>
      <tbody *ngFor="let user of usersByAddress[selectedAddress]">
        <tr>
          <td [attr.data-label]="'ADMIN.USER_NAME' | translate">{{ user.Name }}</td>
          <td [attr.data-label]="'ADMIN.USER_EMAIL' | translate">{{ user.Email }}</td>
          <td [attr.data-label]="'ADMIN.STATUS' | translate">
            <span [ngClass]="{'active': user.Status === 'Active', 'inactive': user.Status === 'Inactive'}">
              {{ user.Status }}
            </span>
          </td>
          <td [attr.data-label]="'ADMIN.ACTIONS' | translate">
            <button (click)="toggleUserStatus(user)" class="btn btn-status btn-sm me-2">
              {{ user.Status === 'Active' ? ('ADMIN.SET_INACTIVE' | translate) : ('ADMIN.SET_ACTIVE' | translate) }}
            </button>
            <button (click)="openViewDataModal(user)" class="btn btn-info btn-sm me-2">
              <i class="fas fa-eye"></i>
            </button>
            <button (click)="deleteUser(user)" class="btn btn-danger btn-sm">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
        <!-- Display User Reports if Any -->
        <tr *ngIf="user.reports && user.reports.length > 0">
          <td colspan="4">
            <h4>{{ 'ADMIN.USER_REPORTS' | translate }}</h4>
            <ul>
              <li *ngFor="let report of user.reports">{{ report }}</li>
            </ul>
          </td>
        </tr>
        <!-- Message When No Users Found -->
        <tr *ngIf="usersByAddress[selectedAddress].length === 0">
          <td colspan="4" class="text-center">{{ 'ADMIN.NO_USERS_FOUND' | translate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Billing Management Section -->
  <div class="admin-card shadow-sm rounded-lg p-4 mb-4 animate__animated animate__fadeIn" *ngIf="selectedAddress">
    <!-- Header with Title and Add Button -->
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h2 class="h5 mb-0">
        <i class="fas fa-file-invoice-dollar me-2 text-primary"></i>
        {{ 'ADMIN.BILLING_MANAGEMENT.MANAGE_BILLING' | translate }}
      </h2>
      <button class="btn btn-success btn-sm" (click)="openAddBillingModal()">
        <i class="fas fa-plus-circle me-2"></i>{{ 'ADMIN.ADD_NEW_BILLING_PERIOD' | translate }}
      </button>
    </div>
    <!-- Current Billing Data Table for Desktop -->
    <div class="table-responsive d-none d-md-block animate__animated animate__fadeIn">
      <table class="table table-bordered table-hover align-middle">
        <thead class="table-primary">
          <tr>
            <th scope="col">{{ 'BILL_CALCULATORS.PROVIDER_ICON' | translate }}</th>
            <th scope="col">{{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}</th>
            <th scope="col">{{ 'BILL_CALCULATORS.STATUS' | translate }}</th>
            <th scope="col">{{ 'BILL_CALCULATORS.BILL_IMAGE' | translate }}</th>
            <th scope="col">{{ 'BILL_CALCULATORS.PERIOD' | translate }}</th>
            <th scope="col">{{ 'ADMIN.BILLING_MANAGEMENT.ACTIONS' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let currentBillingData of currentBillingDataList">
            <td>
              <img [src]="currentBillingData?.providerIcon" alt="Provider Icon" class="provider-icon">
            </td>
            <td>€{{ currentBillingData?.amount }}</td>
            <td>
              <span [ngClass]="{'active': currentBillingData?.status === 'Active', 'inactive': currentBillingData?.status === 'Inactive'}">
                {{ currentBillingData?.status }}
              </span>
            </td>
            <td>
              <a [href]="currentBillingData?.billImage" target="_blank" class="btn btn-sm btn-outline-primary">
                <i class="fas fa-eye me-1"></i>{{ 'BILL_CALCULATORS.VIEW_BILL_IMAGE' | translate }}
              </a>
            </td>
            <td>{{ currentBillingData?.period }}</td>
            <td>
              <button class="btn btn-primary btn-sm me-2" (click)="openEditBillingModal(currentBillingData)">
                <i class="fas fa-edit me-1"></i>{{ 'ADMIN.EDIT_CURRENT_BILLING' | translate }}
              </button>
              <button class="btn btn-danger btn-sm" (click)="deleteCurrentBilling(currentBillingData)">
                <i class="fas fa-trash me-1"></i>{{ 'ADMIN.DELETE_BILLING' | translate }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Current Billing Data Cards for Mobile -->
    <div *ngFor="let currentBillingData of currentBillingDataList" class="billing-card card mb-3 d-block d-md-none animate__animated animate__fadeInUp">
      <div class="card-header bg-primary text-white">
        <div class="d-flex align-items-center">
          <img [src]="currentBillingData?.providerIcon" alt="Provider Icon" class="provider-icon me-2">
          <h5 class="mb-0">{{ 'ADMIN.CURRENT_BILLING' | translate }}</h5>
        </div>
      </div>
      <div class="card-body">
        <div class="mb-2">
          <strong>{{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}:</strong> €{{ currentBillingData?.amount }}
        </div>
        <div class="mb-2">
          <strong>{{ 'BILL_CALCULATORS.STATUS' | translate }}:</strong>
          <span [ngClass]="{'active': currentBillingData?.status === 'Active', 'inactive': currentBillingData?.status === 'Inactive'}">
            {{ currentBillingData?.status }}
          </span>
        </div>
        <div class="mb-2">
          <strong>{{ 'BILL_CALCULATORS.BILL_IMAGE' | translate }}:</strong>
          <a [href]="currentBillingData?.billImage" target="_blank" class="btn btn-sm btn-outline-primary">
            <i class="fas fa-eye me-1"></i>{{ 'BILL_CALCULATORS.VIEW_BILL_IMAGE' | translate }}
          </a>
        </div>
        <div class="mb-3">
          <strong>{{ 'BILL_CALCULATORS.PERIOD' | translate }}:</strong> {{ currentBillingData?.period }}
        </div>
        <!-- Edit and Delete Buttons -->
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-sm me-2" (click)="openEditBillingModal(currentBillingData)">
            <i class="fas fa-edit me-1"></i>{{ 'ADMIN.EDIT_CURRENT_BILLING' | translate }}
          </button>
          <button class="btn btn-danger btn-sm" (click)="deleteCurrentBilling(currentBillingData)">
            <i class="fas fa-trash me-1"></i>{{ 'ADMIN.DELETE_BILLING' | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- Message When No Billing Data is Found -->
    <div *ngIf="currentBillingDataList.length === 0" class="no-billing-data text-muted text-center py-5 animate__animated animate__fadeIn">
      <i class="fas fa-exclamation-triangle fa-3x mb-3 text-warning"></i>
      <p>{{ 'ADMIN.BILLING_MANAGEMENT.NO_BILLING_DATA_FOUND' | translate }}</p>
    </div>
  </div>
  <!-- Historical Billing Data Section -->
  <div class="admin-card animate__animated animate__fadeIn mb-4" *ngIf="selectedAddress && previousBillingData.length > 0">
    <h3>{{ 'ADMIN.PREVIOUS_BILLS' | translate }}</h3>
    <table class="table table-striped table-bordered">
      <thead class="table-secondary">
        <tr>
          <th>{{ 'BILL_CALCULATORS.PERIOD' | translate }}</th>
          <th>{{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}</th>
          <th>{{ 'BILL_CALCULATORS.STATUS' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let bill of previousBillingData">
          <td>{{ bill.period }}</td>
          <td>€{{ bill.amount }}</td>
          <td>{{ bill.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Billing Management Card -->
  <div class="admin-card shadow-sm rounded-lg p-4 mb-4" *ngIf="selectedAddress">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 class="mb-0">
        <i class="fas fa-cogs me-2 text-primary"></i>
        {{ 'ADMIN.BILLING_MANAGEMENT.MANAGE_BILLING' | translate }}
      </h2>
      <button class="btn btn-primary" (click)="openBillingDataModal()" [disabled]="!selectedAddress">
        <i class="fas fa-edit me-1"></i>
        {{ 'ADMIN.BILLING_MANAGEMENT.MANAGE_BILLING' | translate }}
      </button>
    </div>
    <!-- Display Current Billing individual Details if Available -->
    <div *ngIf="billingData" class="">
      <h4 class="mb-3 text-success">
        <i class="fas fa-info-circle me-2"></i>
        {{ 'ADMIN.BILLING_MANAGEMENT.CURRENT_BILLING_DATA' | translate }}
      </h4>
      <div class="table-responsive">
        <table class="table table-bordered table-hover">
          <tbody>
            <tr>
              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.CARBON_TAX' | translate }}</th>
              <td>€{{ billingData.carbonTax }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.ENERGY_COST_PER_KW' | translate }}</th>
              <td>€{{ billingData.energyCostPerKW }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.GAS_COST_PER_M3' | translate }}</th>
              <td>€{{ billingData.gasCostPerM3 }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.GAS_STANDING_CHARGE_PER_DAY' | translate }}</th>
              <td>€{{ billingData.gasStandingChargePerDay }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.INTERNET_FEE' | translate }}</th>
              <td>€{{ billingData.internet_fee }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.PSO_LEVY' | translate }}</th>
              <td>€{{ billingData.psoLevy }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.STANDING_CHARGE_PER_DAY' | translate }}</th>
              <td>€{{ billingData.standingChargePerDay }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.TRASH_FEE' | translate }}</th>
              <td>€{{ billingData.trash_fee }}</td>
            </tr>
            <tr>
              <th scope="row">{{ 'ADMIN.BILLING_MANAGEMENT.VAT_RATE' | translate }}</th>
              <td>{{ billingData.vatRate }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Message When No Billing Data is Found -->
    <div *ngIf="!billingData" class="no-billing-data text-muted text-center py-5 animate__animated animate__fadeIn">
      <i class="fas fa-exclamation-triangle fa-3x mb-3"></i>
      <p>{{ 'ADMIN.BILLING_MANAGEMENT.NO_BILLING_DATA_FOUND' | translate }}</p>
    </div>
  </div>
  <!-- Data Modal for Viewing User Data -->
  <div class="modal-overlay" *ngIf="isModalOpen">
    <div class="modal-content animate__animated animate__zoomIn">
      <div class="modal-header">
        <h3>
          <i class="fas fa-eye"></i>
          {{ 'ADMIN.VIEW_DATA' | translate }}
        </h3>
        <button class="close-button" (click)="closeModal()">&times;</button>
      </div>
      <div class="modal-body p-4">
        <form [formGroup]="userDataForm" (ngSubmit)="saveUserData()">
          <!-- Deposit Amount -->
          <div class="form-group mb-3">
            <label for="depositAmount">{{ 'ADMIN.DEPOSIT_AMOUNT' | translate }}</label>
            <input type="number" id="depositAmount" formControlName="depositAmount" class="form-control" [class.is-invalid]="userDataForm.get('depositAmount')?.invalid && userDataForm.get('depositAmount')?.touched" placeholder="{{ 'ADMIN.PLACEHOLDER_DEPOSIT_AMOUNT' | translate }}" min="0" step="0.01" />
            <div *ngIf="userDataForm.get('depositAmount')?.invalid && userDataForm.get('depositAmount')?.touched" class="invalid-feedback">
              {{ 'ADMIN.INVALID_DEPOSIT_AMOUNT' | translate }}
            </div>
          </div>
          <!-- Contract Start Date -->
          <div class="form-group mb-3">
            <label for="contractStartDate">{{ 'ADMIN.CONTRACT_START_DATE' | translate }}</label>
            <input type="date" id="contractStartDate" formControlName="contractStartDate" class="form-control" [class.is-invalid]="userDataForm.get('contractStartDate')?.invalid && userDataForm.get('contractStartDate')?.touched" />
            <div *ngIf="userDataForm.get('contractStartDate')?.invalid && userDataForm.get('contractStartDate')?.touched" class="invalid-feedback">
              {{ 'ADMIN.INVALID_CONTRACT_START_DATE' | translate }}
            </div>
          </div>
          <!-- Contract Expire Date -->
          <div class="form-group mb-3">
            <label for="contractExpireDate">{{ 'ADMIN.CONTRACT_EXPIRE_DATE' | translate }}</label>
            <input type="date" id="contractExpireDate" formControlName="contractExpireDate" class="form-control" [class.is-invalid]="userDataForm.get('contractExpireDate')?.invalid && userDataForm.get('contractExpireDate')?.touched" />
            <div *ngIf="userDataForm.get('contractExpireDate')?.invalid && userDataForm.get('contractExpireDate')?.touched" class="invalid-feedback">
              {{ 'ADMIN.INVALID_CONTRACT_EXPIRE_DATE' | translate }}
            </div>
          </div>
          <!-- Contract Link -->
          <div class="form-group mb-3">
            <label for="contractLink">{{ 'ADMIN.CONTRACT_LINK' | translate }}</label>
            <input type="url" id="contractLink" formControlName="contractLink" class="form-control" [class.is-invalid]="userDataForm.get('contractLink')?.invalid && userDataForm.get('contractLink')?.touched" placeholder="{{ 'ADMIN.PLACEHOLDER_CONTRACT_LINK' | translate }}" />
            <div *ngIf="userDataForm.get('contractLink')?.invalid && userDataForm.get('contractLink')?.touched" class="invalid-feedback">
              {{ 'ADMIN.INVALID_CONTRACT_LINK' | translate }}
            </div>
          </div>
          <!-- Next Payment Date -->
          <div class="form-group mb-3">
            <label for="nextPaymentDate">{{ 'ADMIN.NEXT_PAYMENT_DATE' | translate }}</label>
            <input type="date" id="nextPaymentDate" formControlName="nextPaymentDate" class="form-control" [class.is-invalid]="userDataForm.get('nextPaymentDate')?.invalid && userDataForm.get('nextPaymentDate')?.touched" />
            <div *ngIf="userDataForm.get('nextPaymentDate')?.invalid && userDataForm.get('nextPaymentDate')?.touched" class="invalid-feedback">
              {{ 'ADMIN.INVALID_NEXT_PAYMENT_DATE' | translate }}
            </div>
          </div>
          <!-- Next Payment Amount -->
          <div class="form-group mb-3">
            <label for="nextPaymentAmount">{{ 'ADMIN.NEXT_PAYMENT_AMOUNT' | translate }}</label>
            <input type="number" id="nextPaymentAmount" formControlName="nextPaymentAmount" class="form-control" [class.is-invalid]="userDataForm.get('nextPaymentAmount')?.invalid && userDataForm.get('nextPaymentAmount')?.touched" placeholder="{{ 'ADMIN.PLACEHOLDER_NEXT_PAYMENT_AMOUNT' | translate }}" min="0" step="0.01" />
            <div *ngIf="userDataForm.get('nextPaymentAmount')?.invalid && userDataForm.get('nextPaymentAmount')?.touched" class="invalid-feedback">
              {{ 'ADMIN.INVALID_NEXT_PAYMENT_AMOUNT' | translate }}
            </div>
          </div>
          <!-- Role Assignment Field -->
          <div class="form-group mb-3">
            <label for="role">{{ 'ADMIN.ROLE_ASSIGNMENT' | translate }}</label>
            <select id="role" formControlName="role" class="form-select" required>
              <option *ngFor="let role of roles" [value]="role">{{ role | translate }}</option>
            </select>
            <div *ngIf="userDataForm.get('role')?.invalid && userDataForm.get('role')?.touched" class="invalid-feedback">
              {{ 'ADMIN.INVALID_ROLE' | translate }}
            </div>
          </div>
          <!-- Save Button -->
          <button type="submit" class="btn btn-success mt-3">
            <i class="fas fa-save"></i> {{ 'ADMIN.SAVE_DATA_BUTTON' | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
  <!-- Billing Data Modal for Adding/Editing Billing Details -->
  <div *ngIf="isBillingDataModalOpen" class="modal-overlay">
    <div class="modal-content animate__animated animate__zoomIn">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">
          <i class="fas fa-cogs me-2"></i>
          {{ billingData ? ('ADMIN.BILLING_MANAGEMENT.EDIT_BILLING_DATA' | translate) : ('ADMIN.BILLING_MANAGEMENT.ADD_BILLING_DATA' | translate) }}
        </h5>
        <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="closeBillingDataModal()"></button>
      </div>
      <div class="modal-body p-4">
        <form [formGroup]="billingDataForm" (ngSubmit)="saveBillingDataDetails()">
          <div class="row">
            <!-- Carbon Tax -->
            <div class="col-md-6 mb-3">
              <label for="carbonTax" class="form-label">
                <i class="fas fa-tree me-2 text-success"></i>
                {{ 'ADMIN.BILLING_MANAGEMENT.CARBON_TAX' | translate }}
              </label>
              <input type="number" id="carbonTax" formControlName="carbonTax" class="form-control" min="0" required />
              <div *ngIf="billingDataForm.get('carbonTax')?.invalid && billingDataForm.get('carbonTax')?.touched" class="text-danger">
                {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_CARBON_TAX' | translate }}
              </div>
            </div>
            <!-- Energy Cost Per KW -->
            <div class="col-md-6 mb-3">
              <label for="energyCostPerKW" class="form-label">
                <i class="fas fa-bolt me-2 text-warning"></i>
                {{ 'ADMIN.BILLING_MANAGEMENT.ENERGY_COST_PER_KW' | translate }}
              </label>
              <input type="number" id="energyCostPerKW" formControlName="energyCostPerKW" class="form-control" min="0" required />
              <div *ngIf="billingDataForm.get('energyCostPerKW')?.invalid && billingDataForm.get('energyCostPerKW')?.touched" class="text-danger">
                {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_ENERGY_COST' | translate }}
              </div>
            </div>
          </div>
          <div class="row">
            <!-- Gas Cost Per M3 -->
            <div class="col-md-6 mb-3">
              <label for="gasCostPerM3" class="form-label">
                <i class="fas fa-fire me-2 text-danger"></i>
                {{ 'ADMIN.BILLING_MANAGEMENT.GAS_COST_PER_M3' | translate }}
              </label>
              <input type="number" id="gasCostPerM3" formControlName="gasCostPerM3" class="form-control" min="0" required />
              <div *ngIf="billingDataForm.get('gasCostPerM3')?.invalid && billingDataForm.get('gasCostPerM3')?.touched" class="text-danger">
                {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_GAS_COST' | translate }}
              </div>
            </div>
            <!-- Gas Standing Charge Per Day -->
            <div class="col-md-6 mb-3">
              <label for="gasStandingChargePerDay" class="form-label">
                <i class="fas fa-calendar-alt me-2 text-secondary"></i>
                {{ 'ADMIN.BILLING_MANAGEMENT.GAS_STANDING_CHARGE_PER_DAY' | translate }}
              </label>
              <input type="number" id="gasStandingChargePerDay" formControlName="gasStandingChargePerDay" class="form-control" min="0" required />
              <div *ngIf="billingDataForm.get('gasStandingChargePerDay')?.invalid && billingDataForm.get('gasStandingChargePerDay')?.touched" class="text-danger">
                {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_GAS_CHARGE' | translate }}
              </div>
            </div>
          </div>
          <div class="row">
            <!-- Internet Fee -->
            <div class="col-md-6 mb-3">
              <label for="internet_fee" class="form-label">
                <i class="fas fa-wifi me-2 text-info"></i>
                {{ 'ADMIN.BILLING_MANAGEMENT.INTERNET_FEE' | translate }}
              </label>
              <input type="number" id="internet_fee" formControlName="internet_fee" class="form-control" min="0" required />
              <div *ngIf="billingDataForm.get('internet_fee')?.invalid && billingDataForm.get('internet_fee')?.touched" class="text-danger">
                {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_INTERNET_FEE' | translate }}
              </div>
            </div>
            <!-- PSO Levy -->
            <div class="col-md-6 mb-3">
              <label for="psoLevy" class="form-label">
                <i class="fas fa-hand-holding-usd me-2 text-success"></i>
                {{ 'ADMIN.BILLING_MANAGEMENT.PSO_LEVY' | translate }}
              </label>
              <input type="number" id="psoLevy" formControlName="psoLevy" class="form-control" min="0" required />
              <div *ngIf="billingDataForm.get('psoLevy')?.invalid && billingDataForm.get('psoLevy')?.touched" class="text-danger">
                {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_PSO_LEVY' | translate }}
              </div>
            </div>
          </div>
          <div class="row">
            <!-- Standing Charge Per Day -->
            <div class="col-md-6 mb-3">
              <label for="standingChargePerDay" class="form-label">
                <i class="fas fa-money-bill-wave me-2 text-primary"></i>
                {{ 'ADMIN.BILLING_MANAGEMENT.STANDING_CHARGE_PER_DAY' | translate }}
              </label>
              <input type="number" id="standingChargePerDay" formControlName="standingChargePerDay" class="form-control" min="0" required />
              <div *ngIf="billingDataForm.get('standingChargePerDay')?.invalid && billingDataForm.get('standingChargePerDay')?.touched" class="text-danger">
                {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_STANDING_CHARGE' | translate }}
              </div>
            </div>
            <!-- Trash Fee -->
            <div class="col-md-6 mb-3">
              <label for="trash_fee" class="form-label">
                <i class="fas fa-trash me-2 text-muted"></i>
                {{ 'ADMIN.BILLING_MANAGEMENT.TRASH_FEE' | translate }}
              </label>
              <input type="number" id="trash_fee" formControlName="trash_fee" class="form-control" min="0" required />
              <div *ngIf="billingDataForm.get('trash_fee')?.invalid && billingDataForm.get('trash_fee')?.touched" class="text-danger">
                {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_TRASH_FEE' | translate }}
              </div>
            </div>
          </div>
          <div class="row">
            <!-- VAT Rate -->
            <div class="col-md-6 mb-3">
              <label for="vatRate" class="form-label">
                <i class="fas fa-percent me-2 text-success"></i>
                {{ 'ADMIN.BILLING_MANAGEMENT.VAT_RATE' | translate }}
              </label>
              <input type="number" id="vatRate" formControlName="vatRate" class="form-control" min="0" required />
              <div *ngIf="billingDataForm.get('vatRate')?.invalid && billingDataForm.get('vatRate')?.touched" class="text-danger">
                {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_VAT_RATE' | translate }}
              </div>
            </div>
          </div>
          <!-- Form Actions -->
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary me-2" (click)="closeBillingDataModal()">
              <i class="fas fa-times me-1"></i>{{ 'ADMIN.BILLING_MANAGEMENT.CANCEL' | translate }}
            </button>
            <button type="submit" class="btn btn-success">
              <i class="fas fa-save me-1"></i>
              {{ 'ADMIN.BILLING_MANAGEMENT.SAVE_BILLING_DATA' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Billing Data Modal for Adding New Billing Period -->
  <div *ngIf="isAddBillingModalOpen" class="modal-overlay">
    <div class="modal-content animate__animated animate__zoomIn">
      <div class="modal-header bg-success text-white">
        <h5 class="modal-title">
          <i class="fas fa-plus-circle me-2"></i>
          {{ 'ADMIN.ADD_NEW_BILLING_PERIOD' | translate }}
        </h5>
        <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="closeAddBillingModal()"></button>
      </div>
      <div class="modal-body p-4">
        <form [formGroup]="newBillingForm" (ngSubmit)="saveNewBillingData()">
          <!-- Provider Icon Select Dropdown -->
        <div class="mb-3">
          <label for="billingProviderIcon" class="form-label">
            <i class="fas fa-camera me-2 text-primary"></i>
            {{ 'BILL_CALCULATORS.PROVIDER_ICON' | translate }}
          </label>
          <select formControlName="providerIcon" class="form-select" id="billingProviderIcon" required>
            <option value="" disabled selected>{{ 'ADMIN.BILLING_MANAGEMENT.SELECT_PROVIDER_ICON' | translate }}</option>
            <option *ngFor="let icon of providerIcons" [value]="icon.iconUrl">
              <img src="{{icon.iconUrl}}" /> - {{ icon.label }}
            </option>
          </select>
          <div *ngIf="billingForm.get('providerIcon')?.invalid && billingForm.get('providerIcon')?.touched" class="text-danger">
            {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_PROVIDER_ICON' | translate }}
          </div>
        </div>
          <!-- Amount Input -->
          <div class="mb-3">
            <label for="newBillingAmount" class="form-label">
              <i class="fas fa-euro-sign me-2 text-warning"></i>
              {{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}
            </label>
            <input type="number" formControlName="amount" class="form-control" id="newBillingAmount" min="0" required>
            <div *ngIf="newBillingForm.get('amount')?.invalid && newBillingForm.get('amount')?.touched" class="text-danger">
              {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_AMOUNT' | translate }}
            </div>
          </div>
          <!-- Period Input -->
          <div class="mb-3">
            <label for="newBillingPeriod" class="form-label">
              <i class="fas fa-calendar-alt me-2 text-secondary"></i>
              {{ 'BILL_CALCULATORS.PERIOD' | translate }}
            </label>
            <input type="text" formControlName="period" class="form-control" id="newBillingPeriod" placeholder="e.g., January 2024" required>
            <div *ngIf="newBillingForm.get('period')?.invalid && newBillingForm.get('period')?.touched" class="text-danger">
              {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_PERIOD' | translate }}
            </div>
          </div>
          <!-- Status Select -->
          <div class="mb-3">
            <label for="newBillingStatus" class="form-label">
              <i class="fas fa-user-check me-2 text-success"></i>
              {{ 'BILL_CALCULATORS.STATUS' | translate }}
            </label>
            <select formControlName="status" class="form-select" id="newBillingStatus" required>
              <option value="Active">{{ 'ADMIN.STATUS_ACTIVE' | translate }}</option>
              <option value="Completed">{{ 'ADMIN.STATUS_COMPLETED' | translate }}</option>
            </select>
            <div *ngIf="newBillingForm.get('status')?.invalid && newBillingForm.get('status')?.touched" class="text-danger">
              {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_STATUS' | translate }}
            </div>
          </div>
          <!-- Bill Image Input -->
          <div class="mb-3">
            <label for="newBillingImage" class="form-label">
              <i class="fas fa-image me-2 text-info"></i>
              {{ 'BILL_CALCULATORS.BILL_IMAGE' | translate }}
            </label>
            <input type="url" formControlName="billImage" class="form-control" id="newBillingImage" placeholder="https://example.com/bill.jpg">
            <div *ngIf="newBillingForm.get('billImage')?.invalid && newBillingForm.get('billImage')?.touched" class="text-danger">
              {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_BILL_IMAGE' | translate }}
            </div>
          </div>
          <!-- Form Actions -->
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary me-2" (click)="closeAddBillingModal()">
              <i class="fas fa-times me-1"></i>{{ 'ADMIN.BILLING_MANAGEMENT.CANCEL' | translate }}
            </button>
            <button type="submit" class="btn btn-success">
              <i class="fas fa-save me-1"></i>{{ 'ADMIN.BILLING_MANAGEMENT.SAVE_NEW_BILLING' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Modal for Editing Billing -->
  <div *ngIf="isBillingModalOpen" class="modal-overlay">
    <div class="modal-content animate__animated animate__zoomIn">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">
          <i class="fas fa-edit me-2"></i>
          {{ 'ADMIN.EDIT_CURRENT_BILLING' | translate }}
        </h5>
        <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="closeBillingModal()"></button>
      </div>
      <div class="modal-body p-4">
        <form [formGroup]="billingForm" (ngSubmit)="saveBillingData()">
          <!-- Provider Icon Select Dropdown -->
          <div class="mb-3">
            <label for="billingProviderIcon" class="form-label">
              <i class="fas fa-camera me-2 text-primary"></i>
              {{ 'BILL_CALCULATORS.PROVIDER_ICON' | translate }}
            </label>
            <select formControlName="providerIcon" class="form-select" id="billingProviderIcon" required>
              <option value="" disabled selected>{{ 'ADMIN.BILLING_MANAGEMENT.SELECT_PROVIDER_ICON' | translate }}</option>
              <option *ngFor="let icon of providerIcons" [value]="icon.iconUrl">
                <img src="{{icon.iconUrl}}" /> - {{ icon.label }}
              </option>
            </select>
            <div *ngIf="billingForm.get('providerIcon')?.invalid && billingForm.get('providerIcon')?.touched" class="text-danger">
              {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_PROVIDER_ICON' | translate }}
            </div>
          </div>

          <!-- Amount Input -->
          <div class="mb-3">
            <label for="billingAmount" class="form-label">
              <i class="fas fa-euro-sign me-2 text-warning"></i>
              {{ 'BILL_CALCULATORS.TOTAL_AMOUNT' | translate }}
            </label>
            <input type="number" formControlName="amount" class="form-control" id="billingAmount" min="0" required>
            <div *ngIf="billingForm.get('amount')?.invalid && billingForm.get('amount')?.touched" class="text-danger">
              {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_AMOUNT' | translate }}
            </div>
          </div>
          <!-- Period Input -->
          <div class="mb-3">
            <label for="billingPeriod" class="form-label">
              <i class="fas fa-calendar-alt me-2 text-secondary"></i>
              {{ 'BILL_CALCULATORS.PERIOD' | translate }}
            </label>
            <input type="text" formControlName="period" class="form-control" id="billingPeriod" placeholder="e.g., January 2024" required>
            <div *ngIf="billingForm.get('period')?.invalid && billingForm.get('period')?.touched" class="text-danger">
              {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_PERIOD' | translate }}
            </div>
          </div>
          <!-- Status Select -->
          <div class="mb-3">
            <label for="billingStatus" class="form-label">
              <i class="fas fa-user-check me-2 text-success"></i>
              {{ 'BILL_CALCULATORS.STATUS' | translate }}
            </label>
            <select formControlName="status" class="form-select" id="billingStatus" required>
              <option value="Active">{{ 'ADMIN.STATUS_ACTIVE' | translate }}</option>
              <option value="Completed">{{ 'ADMIN.STATUS_COMPLETED' | translate }}</option>
            </select>
            <div *ngIf="billingForm.get('status')?.invalid && billingForm.get('status')?.touched" class="text-danger">
              {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_STATUS' | translate }}
            </div>
          </div>
          <!-- Bill Image Input -->
          <div class="mb-3">
            <label for="billingImage" class="form-label">
              <i class="fas fa-image me-2 text-info"></i>
              {{ 'BILL_CALCULATORS.BILL_IMAGE' | translate }}
            </label>
            <input type="url" formControlName="billImage" class="form-control" id="billingImage" placeholder="https://example.com/bill.jpg">
            <div *ngIf="billingForm.get('billImage')?.invalid && billingForm.get('billImage')?.touched" class="text-danger">
              {{ 'ADMIN.BILLING_MANAGEMENT.INVALID_BILL_IMAGE' | translate }}
            </div>
          </div>
          <!-- Form Actions -->
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary me-2" (click)="closeBillingModal()">
              <i class="fas fa-times me-1"></i>{{ 'ADMIN.BILLING_MANAGEMENT.CANCEL' | translate }}
            </button>
            <button type="submit" class="btn btn-success">
              <i class="fas fa-save me-1"></i>
              {{ 'ADMIN.BILLING_MANAGEMENT.SAVE_BILLING_DATA' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  </div>