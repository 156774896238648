<div class="auth-container">
  <div class="auth-card">
    <div class="auth-header">
      <img src="assets/form.png" alt="Sign Up Icon" class="auth-icon" />
      <h2>{{ 'SIGN_UP.TITLE' | translate }}</h2>
    </div>
    <div class="auth-form">
      <input
        type="text"
        [(ngModel)]="name"
        placeholder="{{ 'SIGN_UP.NAME' | translate }}"
        class="auth-input"
        required
      />
      <input
        type="email"
        [(ngModel)]="email"
        placeholder="{{ 'SIGN_UP.EMAIL_PLACEHOLDER' | translate }}"
        class="auth-input"
        required
      />
      <input
        type="password"
        [(ngModel)]="password"
        placeholder="{{ 'SIGN_UP.PASSWORD_PLACEHOLDER' | translate }}"
        class="auth-input"
        required
      />

      <!-- Disclaimer Checkbox -->
      <div class="checkbox-container">
        <input
          type="checkbox"
          id="disclaimer"
          [(ngModel)]="isDisclaimerChecked"
          required
        />
        <label for="disclaimer">
          {{ 'SIGN_UP.ACCEPT_TERMS' | translate }}
          <a routerLink="/terms-and-conditions" target="_blank">
            {{ 'SIGN_UP.TERMS_LINK' | translate }}
          </a>
        </label>
      </div>

      <button
        (click)="signUp()"
        class="auth-button"
        [disabled]="!isFormValid()"
      >
        {{ 'SIGN_UP.SIGN_UP_BUTTON' | translate }}
      </button>
    </div>
    <div class="auth-footer">
      <p>{{ 'SIGN_UP.ALREADY_HAVE_ACCOUNT' | translate }}</p>
      <button (click)="navigateToSignIn()" class="secondary-button">
        {{ 'SIGN_UP.SIGN_IN_BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
